import { ITextInstanceService } from './text';
import { IBackgroundInstanceService } from './background';
import { IPackshotsInstanceService } from './packshots';
import { IBannerInstanceService } from './banner';
import { IRenderInstanceService } from './render';

interface IInstanceService {
    text: ITextInstanceService;
    packshots: IPackshotsInstanceService;
    background: IBackgroundInstanceService;
    banner: IBannerInstanceService;
    render: IRenderInstanceService;
}

const InstanceService = (
    text: ITextInstanceService,
    packshots: IPackshotsInstanceService,
    background: IBackgroundInstanceService,
    banner: IBannerInstanceService,
    render: IRenderInstanceService,
): IInstanceService => ({
    text,
    packshots,
    background,
    banner,
    render,
});

export type { IInstanceService };
export default InstanceService;
