import React, { ReactElement } from 'react';
import MUISkeleton from '@mui/material/Skeleton';
import Class from '../../utils/classes';

type Props = {
    variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
    className?: string;
};

export default function Skeleton(props: Props): ReactElement {
    const { variant, className } = props;
    return <MUISkeleton variant={variant} className={Class.classNames('h-full', className || '')} />;
}

Skeleton.defaultProps = {
    variant: 'rectangular',
    className: '',
};
