import { selectSelectedLocals } from './select-selected-locals';
import { selectSelectedInstanceIds } from './select-selected-instance-ids';
import { selectSelectedProviders } from './select-selected-providers';
import { selectSelectedCampaignId } from './select-selected-campaign-id';
import { selectSelectedOutputChannel } from './select-selected-output-channel';
import { selectSelectedInstanceFormats } from './select-selected-instance-formats';
import { selectGeneratorData } from './select-generator-data';

const GeneratorSelector = {
    getSelectedCampaignId: selectSelectedCampaignId(),
    getSelectedOutputChannel: selectSelectedOutputChannel(),
    getSelectedLocals: selectSelectedLocals(),
    getSelectedInstanceIds: selectSelectedInstanceIds(),
    getSelectedProviders: selectSelectedProviders(),
    getSelectedInstanceFormats: selectSelectedInstanceFormats(),
    getGeneratorData: selectGeneratorData(),
};

export default GeneratorSelector;
