import React, { ReactElement } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import EditorSelectors from '../../stores/selectors/editor';
import useLocale from '../../hooks/use-locale';

export default function ToolbarCampaignName(): ReactElement {
    const campaignName = useStoreValue<string>(EditorSelectors.getActiveCampaignName());

    const { getText } = useLocale();

    return (
        <div className='relative min-w-120 rounded-15 bg-white pb-7 pl-10 pr-32 pt-24 shadow'>
            <p className='absolute -translate-x-2 -translate-y-20 scale-90 text-lg text-companyMediumGrey'>
                {getText('widgets.campaignSelect.label')}
            </p>
            <p className='w-120 overflow-hidden overflow-ellipsis whitespace-nowrap'>{campaignName}</p>
        </div>
    );
}
