import React, { ReactElement } from 'react';

import { Icon, IconButton } from '@mui/material';
import { isNil } from '@metaphore/magnolia-rendering';

type Props = {
    fontSizeFactor: number;
    onSetFontSizeFactor(value: number): void;
};

export default function TextFontSizeFactor(props: Props): ReactElement {
    const { fontSizeFactor, onSetFontSizeFactor } = props;

    const min = 0.5;
    const max = 2;
    const step = 0.1;

    const iconStyles = {
        border: '1px solid rgb(208, 208, 208)',
        padding: '4.5px',
    };

    function clamp(val: number): number {
        if (val < min) {
            return min;
        }

        if (val > max) {
            return max;
        }

        return val;
    }

    function toPercentage(val?: number): number {
        if (isNil(val)) {
            return 100;
        }
        return Math.round(clamp(val) * 100);
    }

    function fromPercentage(val: number): number {
        return val / 100;
    }

    function handleValueChange(val: number): void {
        const newValue = clamp(val);
        onSetFontSizeFactor(newValue);
    }

    function handleBlur(v: string): void {
        const percentage = Number.parseFloat(v);
        const newValue = fromPercentage(percentage);
        handleValueChange(newValue);
    }

    function handleValueAdd(add: number): void {
        handleValueChange(fontSizeFactor + add);
    }

    return (
        <>
            <IconButton sx={iconStyles} onClick={() => handleValueAdd(-step)}>
                <Icon>remove</Icon>
            </IconButton>
            <input
                style={{
                    background: 'none',
                    width: '45px',
                    textAlign: 'center',
                }}
                type='text'
                readOnly
                disabled
                value={`${toPercentage(fontSizeFactor)}%`}
                min={min * 100}
                max={max * 100}
                onBlur={(e) => handleBlur(e.target.value)}
            />
            <IconButton onClick={() => handleValueAdd(step)} sx={iconStyles}>
                <Icon>add</Icon>
            </IconButton>
        </>
    );
}
