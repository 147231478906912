import { createSelector } from '@reduxjs/toolkit';
import { TemplateImage } from '../../../generated/gql/graphql';
import { RootState } from '../../index';
import { EditorState } from '../../slices/editor';

export const selectImageAssetGroupById = (id: string): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) =>
            (state.baseTemplate?.objects.find((item) => item.id === id)?.content as TemplateImage)?.assetGroups?.[0],
    );
