import React, { PropsWithChildren, ReactElement } from 'react';
import { ClickAwayListener, Grow, Popper as MUIPopper, PopperProps } from '@mui/material';
import List from '../list/list';

type Props = {
    onOpen(open: boolean): void;
} & PopperProps;

export default function Popper(props: PropsWithChildren<Props>): ReactElement {
    const { open, anchorEl, children, onOpen } = props;
    return (
        <MUIPopper
            className='z-50'
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            placement='bottom-end'
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'right top',
                    }}
                >
                    <div className='mt-8 rounded-15 bg-white shadow'>
                        <ClickAwayListener onClickAway={() => onOpen(false)}>
                            <div>
                                <List>{children}</List>
                            </div>
                        </ClickAwayListener>
                    </div>
                </Grow>
            )}
        </MUIPopper>
    );
}
