import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import AssetTile from './asset-tile';
import useAssetUpload from '../../hooks/use-asset-upload';
import { AssetGroup } from '../../types/assets';
import { AssetUploadStatus } from '../../types/enums/asset-upload-status';
import CircleErrorIcon from '../../components/icons/circle-error-icon';
import CircleCheckIcon from '../../components/icons/circle-check-icon';
import useLocale from '../../hooks/use-locale';
import LoadingIcon from '../../components/icons/loading-icon';

type Props = {
    file: File;
    onUpload: (error?: string) => void;
};

export interface IAssetPreviewTile extends ForwardRefExoticComponent<ReactElement> {
    upload: (group: AssetGroup) => Promise<void>;
}

const AssetPreviewTile = forwardRef<IAssetPreviewTile, Props>((props, ref) => {
    const { file, onUpload } = props;
    const [url, setUrl] = useState('');

    const { getText } = useLocale();
    const { uploadAsset, status } = useAssetUpload();

    useEffect(() => {
        if (/\.(jpe?g|png|gif|svg)$/i.test(file.name)) {
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => {
                    const resultString = reader.result as string;
                    if (resultString) {
                        setUrl(resultString);
                    }
                },
                false,
            );

            reader.readAsDataURL(file);
        }
    });

    async function handleUpload(group: AssetGroup): Promise<void> {
        try {
            await uploadAsset(file, group);
            onUpload();
        } catch {
            onUpload('error');
        }
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                upload: handleUpload,
            } as IAssetPreviewTile),
    );

    function getStatusText(): ReactElement | undefined {
        if (status === AssetUploadStatus.NONE) {
            return undefined;
        }

        return (
            <div className='flex flex-row items-center space-x-2'>
                {status === AssetUploadStatus.LOADING && (
                    <>
                        <LoadingIcon className='animate-spin' width={18} height={18} />
                        <p className='text-lg'>{getText('widgets.assetUpload.status.loading')}</p>
                    </>
                )}
                {status === AssetUploadStatus.ERROR && (
                    <>
                        <CircleErrorIcon width={18} height={18} />
                        <p className='text-lg'>{getText('widgets.assetUpload.status.error')}</p>
                    </>
                )}
                {status === AssetUploadStatus.SUCCESS && (
                    <>
                        <CircleCheckIcon width={18} height={18} />{' '}
                        <p className='text-lg'>{getText('widgets.assetUpload.status.success')}</p>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className='flex flex-col space-y-10'>
            <AssetTile focused={false} url={url} dark onClick={() => {}} disabled onDelete={() => {}} />
            {getStatusText()}
        </div>
    );
});

export default AssetPreviewTile;
