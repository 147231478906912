import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icon';

export default function BannerIcon(props: IconProps): ReactElement {
    const { width, height, className, color } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.20002 12.4801C7.5314 12.4801 7.80002 12.7487 7.80002 13.0801V13.6801C7.80002 14.0115 7.5314 14.2801 7.20002 14.2801H4.80002C3.47456 14.2801 2.40002 13.2055 2.40002 11.8801V6.48008C2.40002 5.15462 3.47456 4.08008 4.80002 4.08008H15.6C16.9255 4.08008 18 5.15462 18 6.48008V7.68008C18 8.01146 17.7314 8.28008 17.4 8.28008H16.8C16.4686 8.28008 16.2 8.01146 16.2 7.68008V6.48008C16.2 6.1487 15.9314 5.88008 15.6 5.88008H4.80002C4.46864 5.88008 4.20002 6.1487 4.20002 6.48008V11.8801C4.20002 12.2115 4.46864 12.4801 4.80002 12.4801H7.20002ZM21.6 11.8801V17.2801C21.6 18.6055 20.5255 19.6801 19.2 19.6801H11.4C10.0746 19.6801 9.00002 18.6055 9.00002 17.2801V11.8801C9.00002 10.5546 10.0746 9.48008 11.4 9.48008H19.2C20.5255 9.48008 21.6 10.5546 21.6 11.8801ZM19.8 11.8801C19.8 11.5492 19.5309 11.2801 19.2 11.2801H11.4C11.0692 11.2801 10.8 11.5492 10.8 11.8801V17.2801C10.8 17.6109 11.0692 17.8801 11.4 17.8801H19.2C19.5309 17.8801 19.8 17.6109 19.8 17.2801V11.8801Z'
                fill={color}
            />
        </svg>
    );
}
