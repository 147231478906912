import { Rendering, RenderingInput } from '../../generated/gql/graphql';
import { GraphQlClient } from '../../providers/graphql-provider';
import { Mutations } from '../../repositories';

interface IRenderInstanceService {
    renderTemplateInstance(id: string, input?: RenderingInput): Promise<Rendering>;
}

const RenderInstanceService = (): IRenderInstanceService => {
    async function renderTemplateInstance(id: string, input: RenderingInput): Promise<Rendering> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.instance.renderTemplateInstance,
            variables: {
                templateInstanceId: id,
                input,
            },
        });
        if (result.errors) {
            throw result.errors;
        }
        return result.data.renderTemplateInstance as Rendering;
    }

    return {
        renderTemplateInstance,
    };
};

export type { IRenderInstanceService };
export default RenderInstanceService;
