import { gql } from '@apollo/client';

export const duplicateCampaignItem = gql`
    mutation duplicateCampaignItem($input: DuplicateCampaignItemInput!) {
        duplicateCampaignItem(input: $input) {
            id
            name
        }
    }
`;
