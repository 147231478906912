import React, { ReactElement, useEffect, useState } from 'react';
import TitleWithIconButton from '../../components/header/title-with-icon-button';
import { Add } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';
import { asyncInvoke } from '../../utils/function';
import useLocale from '../../hooks/use-locale';
import { useNavigate } from 'react-router-dom';
import Routes from '../../types/enums/routes';

type Props = {
    campaignId: string;
    onClickCreateCampaign(): void;
};

export default function CampaignHeader(props: Props): ReactElement {
    const { campaignId, onClickCreateCampaign } = props;

    const [title, setTitle] = useState('');
    const [languages, setLanguages] = useState<string[]>([]);

    const { campaign } = useServices();
    const { getText } = useLocale();
    const navigate = useNavigate();

    async function loadCampaign(): Promise<void> {
        const result = await campaign.getById(campaignId);
        setTitle(result.name);
        setLanguages(result.languages?.map((l) => l.name) ?? []);
    }

    useEffect(asyncInvoke(loadCampaign), []);

    const showCampaignLanguagesDebug = false;

    return (
        <div className='my-20 flex space-x-15'>
            <button type='button' onClick={() => navigate(Routes.CAMPAIGNS)}>
                <p className='hover: my-auto mr-0 text-2xl uppercase text-companyDarkGrey hover:underline'>
                    {getText('widgets.dashboard.header.title')}
                </p>
            </button>
            <p className='my-auto text-2xl uppercase text-companyDarkGrey'>/</p>
            <TitleWithIconButton
                title={title}
                icon={<Add />}
                onClickButton={() => onClickCreateCampaign()}
                toolTip={getText('widgets.campaigns.header.tooltip')}
            />
            {showCampaignLanguagesDebug && (
                <p className='my-auto text-xl uppercase text-companyMediumGrey'>
                    {getText('widgets.campaigns.header.languages', languages.join(', '))}
                </p>
            )}
        </div>
    );
}
