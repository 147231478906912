import { gql } from '@apollo/client';

export const getTemplateMotionLength = gql`
    query getTemplateMotionLength($templateId: ID!) {
        template(id: $templateId) {
            motion {
                length
            }
        }
    }
`;
