import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icon';

export default function PrintIcon(props: IconProps): ReactElement {
    const { width, height, className, color } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_232_1931)'>
                <path
                    d='M19 8H18V3H6V8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM8 5H16V8H8V5ZM16 17V19H8V15H16V17ZM18 15V13H6V15H4V11C4 10.45 4.45 10 5 10H19C19.55 10 20 10.45 20 11V15H18Z'
                    fill={color}
                />
                <path
                    d='M18 12.5C18.5523 12.5 19 12.0523 19 11.5C19 10.9477 18.5523 10.5 18 10.5C17.4477 10.5 17 10.9477 17 11.5C17 12.0523 17.4477 12.5 18 12.5Z'
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id='clip0_232_1931'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}
