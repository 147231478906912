import React, { ReactElement, useEffect } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { PauseCircleOutline, PlayCircleOutline, ReplayOutlined } from '@mui/icons-material';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import { useServices } from '../../hooks/use-services';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';

export default function CanvasPlayToggle(): ReactElement {
    const isPlaying = useStoreValue<boolean>(CanvasSelectors.isPlaying());
    const isEnded = useStoreValue<boolean>(CanvasSelectors.isEnded());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());

    const { canvas } = useServices();

    useEffect(() => {
        if (selectedObjectId) {
            canvas.pause();
        }
    }, [selectedObjectId, canvas]);

    function onClick(): void {
        if (isPlaying) {
            canvas.pause();
            return;
        }
        if (isEnded) {
            canvas.setPlaytime(0);
        }
        canvas.play();
        canvas.selectObject(undefined);
    }

    useHotkeys(ShortKeys.VIDEO_PLAY_PAUSE, () => onClick(), { preventDefault: true });

    function getIcon(): ReactElement {
        if (isPlaying && !isEnded) {
            return <PauseCircleOutline />;
        }
        if (!isPlaying && !isEnded) {
            return <PlayCircleOutline />;
        }
        return <ReplayOutlined />;
    }

    return <IconButton onClick={() => onClick()}>{getIcon()}</IconButton>;
}
