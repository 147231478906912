import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icon';

export default function LoadingIcon(props: IconProps): ReactElement {
    const { width, height, className, color } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill={color}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12 21C7.04 21 3 16.96 3 12C3 7.04 7.04 3 12 3C16.96 3 21 7.04 21 12H19C19 8.14 15.86 5 12 5C8.14 5 5 8.14 5 12C5 15.86 8.14 19 12 19V21Z'
                fill='#222A2F'
            />
        </svg>
    );
}
