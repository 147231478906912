import { TemplateColorOption, TemplateObjectChange } from '@metaphore/magnolia-rendering';
import { TemplateObjectBackground } from '@metaphore/magnolia-rendering/dist/templates/TemplateObjectBackground';
import { Icon, IconButton, Menu, MenuItem } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import InstanceSelector from '../../stores/selectors/instance';

type Props = {
    icon: string;
    type: string;
    colorOptions: TemplateColorOption[] | undefined;
    color: string;
    onSetColor(path: string, value: string | TemplateObjectBackground): void;
};

export default function TextColorControl(props: Props): ReactElement {
    const { onSetColor, icon, type, color, colorOptions } = props;

    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const selectedColorOption = useStoreValue<TemplateObjectChange>(
        InstanceSelector.getChangedPropertyById(selectedObjectId, 'content.selectedColorOption'),
    )?.value;
    const selectedColor = useStoreValue<TemplateObjectChange>(
        InstanceSelector.getChangedPropertyById(selectedObjectId, 'content.color'),
    )?.value;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
    const [scheduledEvent, setScheduledEvent] = React.useState<any>();
    const [inputColor, setInputColor] = useState<any>(selectedColor || color);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (Number(selectedColorOption) >= 0 && colorOptions?.length) {
            setInputColor(colorOptions[Number(selectedColorOption)].value);
        }
    }, [colorOptions, inputColor, selectedColorOption]);

    useEffect(() => {
        setInputColor(color);
    }, [color]);

    function handleOnClick(event: React.MouseEvent<HTMLElement>): void {
        setAnchorEl(event.currentTarget);
    }

    function handleOnChange(value: string): void {
        if (scheduledEvent !== false) {
            window.clearTimeout(scheduledEvent);
            setScheduledEvent(false);
        }

        setScheduledEvent(
            window.setTimeout(() => {
                // eslint-disable-next-line unicorn/no-useless-undefined
                setAnchorEl(undefined);

                if (!value) {
                    return;
                }

                if (colorOptions?.length) {
                    onSetColor('content.selectedColorOption', value);
                } else {
                    onSetColor('content.color', value);
                }

                setInputColor(value);
            }, 100),
        );
    }

    return (
        <div className='color-picker cursor-pointer'>
            {colorOptions?.length ? (
                <>
                    <Icon
                        id='basic-button'
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e) => handleOnClick(e)}
                    >
                        {icon}
                    </Icon>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => handleOnChange('')}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {colorOptions?.map((item: TemplateColorOption, index) => (
                            <MenuItem key={item.value} onClick={() => handleOnChange(index.toString())}>
                                <IconButton
                                    className='h-24 w-24 border border-solid'
                                    style={{ background: item.value }}
                                >
                                    {inputColor === item.value && <Icon>done</Icon>}
                                </IconButton>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            ) : (
                <>
                    <input
                        onChange={(e) => handleOnChange(e.target.value)}
                        className='input-color cursor-pointer'
                        type='color'
                        value={inputColor || ''}
                        id='basic-button'
                    />
                    <Icon>{icon}</Icon>
                </>
            )}

            {type === 'background' && (
                <IconButton
                    className='ml-7 p-0'
                    onClick={() => handleOnChange('')}
                    disabled={!inputColor}
                    disableRipple
                    color='inherit'
                >
                    <Icon>format_color_reset</Icon>
                </IconButton>
            )}

            <div style={{ background: inputColor }} className='current-color' />
        </div>
    );
}
