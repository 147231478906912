import React, { ReactElement, useEffect, useRef, useState } from 'react';
import SearchField from '../../../../components/inputs/search-field';
import AssetTile from '../../../asset/asset-tile';
import short from 'short-uuid';
import useLocale from '../../../../hooks/use-locale';
import { AssetData } from '../../../../types/assets.d';
import { asyncInvoke } from '../../../../utils/function';
import { useServices } from '../../../../hooks/use-services';
import Skeleton from '../../../../components/skeleton/skeleton';
import Class from '../../../../utils/classes';
import { Asset, AssetGroup } from '../../../../generated/gql/graphql';

type Props = {
    label: string;
    assetGroup: AssetGroup;
    onSelectAsset(url: string): void;
};

export default function AssetReplace(props: Props): ReactElement {
    const { label, assetGroup, onSelectAsset } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const assetLimit = 50;

    const { getText } = useLocale();
    const { asset } = useServices();
    const scrollRef = useRef<HTMLDivElement>(null);

    const [selectedAsset, setSelectedAsset] = useState<Asset>();
    const [isLoading, setIsLoading] = useState(true);

    const [assets, setAssets] = useState<AssetData>({
        items: [],
        limit: 0,
        offset: 0,
        page: 1,
        totalCount: 0,
    });
    const assetsRef = useRef<AssetData>(assets);

    async function fetchAssets(page?: number): Promise<void> {
        const loadedAssets = await asset.getAssetsByQuery(
            { group: assetGroup },
            {
                limit: assetLimit,
                offset: 0,
                page: page || 1,
            },
        );

        setAssets((prev) => {
            const combinedItems = [...(prev?.items || []), ...(loadedAssets.items || [])];

            const newAssets = {
                items: combinedItems.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i),
                limit: loadedAssets.limit || 0,
                offset: loadedAssets.offset || 0,
                page: loadedAssets.page || 1,
                totalCount: loadedAssets.totalCount || 0,
            };
            assetsRef.current = newAssets;

            return newAssets;
        });
        setIsLoading(false);
    }

    useEffect(asyncInvoke(fetchAssets), []);

    function onScroll(): void {
        if (scrollRef && scrollRef.current) {
            const scrollable = scrollRef.current;
            if (!scrollable) {
                return;
            }

            if (
                scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight &&
                assetsRef.current.items.length < assetsRef.current.totalCount
            ) {
                fetchAssets(assets.page + 1);
            }
        }
    }

    useEffect(() => {
        const ref = scrollRef?.current;
        if (ref) {
            ref.addEventListener('scroll', onScroll);
        }

        return () => {
            if (ref) {
                ref.removeEventListener('scroll', onScroll);
            }
        };
    }, [scrollRef]);

    function getFilteredAssets(): Asset[] {
        if (!assets) {
            return [];
        }

        const formattedSearchTerm = searchTerm?.toLowerCase();
        return assets.items.filter((element) => element.name.toLowerCase().includes(formattedSearchTerm || ''));
    }

    function handleSelectAsset(selection: Asset): void {
        setSelectedAsset(selection);

        if (selection.url) {
            onSelectAsset(selection.url);
        }
    }

    return (
        <div className='flex flex-col overflow-hidden rounded-10 shadow'>
            <div className='flex w-full flex-row items-center justify-between space-x-200 border-b-1 px-30 pb-20 pt-20'>
                <p className='text-xl uppercase text-companyMediumGrey'>{label}</p>
                <SearchField
                    placeholder={getText('widgets.canvas.searchAndReplace.searchPlaceholder')}
                    onChange={(v) => setSearchTerm(v)}
                />
            </div>
            <div
                className='max-h-420 min-h-[320px]
                overflow-y-scroll px-30 py-20 scrollbar-thin
                scrollbar-track-companyLightGrey scrollbar-thumb-companyGrey
                scrollbar-track-rounded-10 scrollbar-thumb-rounded-10'
                ref={scrollRef}
            >
                <div className='grid grid-cols-6 gap-10'>
                    {isLoading
                        ? Array.from({ length: 10 }).map(() => (
                              <Skeleton
                                  key={short.generate()}
                                  variant='rectangular'
                                  className={Class.classNames(
                                      'relative aspect-square rounded-10 border-company shadow-tile ',
                                  )}
                              />
                          ))
                        : getFilteredAssets().map((v) => (
                              <AssetTile
                                  key={short.generate()}
                                  id={v.id || ''}
                                  url={v.thumbnailUrl || ''}
                                  dark
                                  onClick={() => handleSelectAsset(v)}
                                  focused={selectedAsset?.id === v.id}
                                  deletable={false}
                                  onDelete={() => {}}
                              />
                          ))}
                </div>
            </div>
        </div>
    );
}
