import React, { ReactElement, useRef, useState } from 'react';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import Class from '../../utils/classes';
import useAssetDelete from '../../hooks/use-asset-delete';
import { IconButton } from '@mui/material';
import useLocale from '../../hooks/use-locale';
import Dialog from '../../components/dialog/dialog';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-icon.svg';
import useNotification from '../../hooks/use-notification';

type Props = {
    id?: string;
    url: string;
    focused: boolean;
    dark: boolean;
    onClick(): void;
    disabled?: boolean;
    deletable?: boolean;
    onDelete(): void;
};

export default function AssetTile(props: Props): ReactElement {
    const { id, url, focused, dark, onClick, disabled, deletable, onDelete } = props;
    const rippleRef = useRef<TouchRippleActions>(null);

    const { getText } = useLocale();
    const { deleteAsset } = useAssetDelete();
    const notification = useNotification();

    function onRippleStart(event: any): void {
        rippleRef.current?.start(event);
    }

    function onRippleStop(event: any): void {
        rippleRef.current?.stop(event);
    }

    const [isDialogOpen, setisDialogOpen] = useState(false);

    async function handleOnDelete(): Promise<void> {
        setisDialogOpen(false);
        if (!id) {
            return;
        }
        try {
            await deleteAsset(id);
            notification.pushSuccess(
                getText('widgets.assetDelete.notifications.success.body'),
                getText('widgets.assetDelete.notifications.success.title'),
            );
        } catch {
            notification.pushError(
                getText('widgets.assetDelete.notifications.error.body'),
                getText('widgets.assetDelete.notifications.error.title'),
            );
        } finally {
            onDelete();
        }
    }

    return (
        <>
            <div className='group relative flex '>
                <button
                    type='button'
                    className={Class.classNames(
                        'relative aspect-square h-full w-full overflow-hidden rounded-10 border-company shadow-tile',
                        focused ? 'border-4' : '',
                        dark ? 'bg-companyGrey' : 'bg-companyLightGrey',
                        disabled ? '' : 'cursor-pointer group-hover:border-4',
                    )}
                    onClick={onClick}
                    onMouseDown={onRippleStart}
                    onMouseUp={onRippleStop}
                    disabled={disabled}
                >
                    <img src={url} alt='asset' width='100%' height='100%' className='h-full w-full object-contain' />
                    <TouchRipple ref={rippleRef} center className='h-full w-full' />
                </button>
                {deletable && (
                    <IconButton
                        onClick={() => {
                            setisDialogOpen(true);
                        }}
                        className='absolute right-0 top-0 rounded-full opacity-0 group-hover:opacity-100'
                    >
                        <DeleteIcon className='rounded-full bg-white bg-opacity-70 shadow' color='#222A2F' />
                    </IconButton>
                )}
            </div>
            <Dialog
                title={getText('widgets.assetDelete.dialog.title')}
                contentText={getText('widgets.assetDelete.dialog.content')}
                submitText={getText('widgets.assetDelete.dialog.submit')}
                closeText={getText('widgets.assetDelete.dialog.cancel')}
                open={isDialogOpen}
                onClose={() => setisDialogOpen(false)}
                onSubmit={() => handleOnDelete()}
            />
        </>
    );
}

AssetTile.defaultProps = {
    id: '',
    disabled: false,
    deletable: false,
};
