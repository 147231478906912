import { gql } from '@apollo/client';

export const createCampaign = gql`
    mutation CreateCampaign($input: CampaignInput!) {
        createCampaign(input: $input) {
            id
            name
        }
    }
`;
