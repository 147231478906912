import React, { ReactElement, ReactNode } from 'react';
import { ListItemIcon as MUIListItemIcon } from '@mui/material';

type Props = {
    className?: string;
    children: ReactNode;
};

export default function ListItemIcon(props: Props): ReactElement {
    const { className, children } = props;
    return <MUIListItemIcon className={`min-w-max ${className}`}>{children}</MUIListItemIcon>;
}

ListItemIcon.defaultProps = {
    className: '',
};
