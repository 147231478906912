import TextField from '../../components/inputs/text-field';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import EditorSelectors from '../../stores/selectors/editor';
import { TemplateInstance } from '../../generated/gql/graphql';
import { useStoreValue } from '../../hooks/use-store-value';
import { useServices } from '../../hooks/use-services';

export default function BannerUrl(): ReactElement {
    const [url, setUrl] = useState('');

    const templateInstance = useStoreValue<TemplateInstance>(EditorSelectors.getTemplateInstance());

    const { instance } = useServices();

    useEffect(() => {
        if (!templateInstance) {
            return;
        }

        if (!templateInstance.renderSettings) {
            return;
        }

        if (!templateInstance.renderSettings.bannerSettings) {
            return;
        }

        setUrl(templateInstance.renderSettings.bannerSettings.targetUrl || '');
    }, [templateInstance]);

    async function onUrlChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
        setUrl(e.target.value);
        await instance.banner.updateSettings(templateInstance.id, {
            ...templateInstance.renderSettings?.bannerSettings,
            targetUrl: e.target.value,
        });
    }

    return <TextField className='w-full' label='URL' value={url} onChange={(v) => onUrlChange(v)} />;
}
