import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Accordion as MUIAccordion, AccordionSummary as MUIAccordionSummary } from '@mui/material';

type Props = {
    children: NonNullable<ReactNode>;
    expanded?: boolean | undefined;
};

export default function AccordionImageEdit(props: Props): ReactElement {
    const { children, expanded } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded || false);

    function onUpdateExpand(): void {
        if (expanded !== undefined) {
            setIsExpanded(expanded);
        }
    }

    useEffect(onUpdateExpand, [expanded]);

    return (
        <MUIAccordion
            expanded={isExpanded}
            className='z-2 !m-0 min-w-570 overflow-hidden rounded-15 rounded-tl-none shadow'
        >
            <MUIAccordionSummary className='h-0 !min-h-0 !p-0' />
            {children}
        </MUIAccordion>
    );
}

AccordionImageEdit.defaultProps = {
    expanded: false,
};
