import React, { ReactElement, useContext, useEffect, useState } from 'react';
import useLocale from '../../../hooks/use-locale';
import Modal from '../../../components/modal/modal';
import { Radio } from '@mui/material';
import TextInput from '../../../components/inputs/text-input';
import UnitConvert from '../../../utils/unit-convert';
import { useStoreValue } from '../../../hooks/use-store-value';
import { CampaignItem, Template } from '../../../generated/gql/graphql';
import EditorSelectors from '../../../stores/selectors/editor';
import StringFormatUtils from '../../../utils/string-format';
import moment from 'moment/moment';
import { useServices } from '../../../hooks/use-services';
import { asyncInvoke } from '../../../utils/function';
import { getLayoutForDimensions } from '@metaphore/magnolia-rendering';
import useNotification from '../../../hooks/use-notification';
import { FormatProviderContext } from '../../../providers/format-provider';

type Props = {
    open: boolean;
    onClose: () => void;
};

enum State {
    SELECT = 'select',
    CREATE = 'create',
    UNAVAILABLE = 'unavailable',
}

export default function FormatEditorModal(props: Props): ReactElement {
    const { open, onClose } = props;

    const { getText } = useLocale();
    const { me, template, editor } = useServices();
    const { setSelectedProvider, setSelectedFormat, groupByGroupId, setAvailableProviders } =
        useContext(FormatProviderContext);

    const notification = useNotification();

    const campaignName = useStoreValue<string>(EditorSelectors.getActiveCampaignName());
    const activeCampaignItem = useStoreValue<CampaignItem | undefined>(EditorSelectors.getActiveCampaignItem());
    const currentTemplate = useStoreValue<Template>(EditorSelectors.getActiveTemplate());

    const [state, setState] = useState<State>(State.SELECT);
    const [unit, setUnit] = useState('px');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [name, setName] = useState('');
    const [clientName, setClientName] = useState('');

    function getConvertedValue(value: string): string {
        if (unit === 'mm') {
            return `${Math.round(UnitConvert.mmToPx(Number(value)))} px`;
        }

        return `${Math.round(UnitConvert.pxToMm(Number(value)))} mm`;
    }

    function getPixelValue(value: string): string {
        return unit === 'px' ? `${value} px` : getConvertedValue(value);
    }

    async function getClientName(): Promise<void> {
        const cN = await me.getClientName();
        setClientName(cN);
    }

    useEffect(asyncInvoke(getClientName), []);

    function getMailtoLink(): string {
        return `mailto:?subject=${getText(
            'widgets.canvas.formatEditor.unavailable.email.subject',
        )}&body=${encodeURIComponent(
            getText(`widgets.canvas.formatEditor.unavailable.email.body`, {
                formatHeight: getPixelValue(width),
                formatWidth: getPixelValue(height),
                clientName: clientName || '',
                templateName: activeCampaignItem?.template?.name || '',
                campaignName: campaignName || '',
                campaignItemName: activeCampaignItem?.name || '',
                date: StringFormatUtils.formatTimestamp(moment().format()),
            }),
        )}`;
    }

    function handleOnClose(): void {
        setTimeout(() => {
            setState(State.SELECT);
        }, 300);
        setWidth('');
        setHeight('');
        setUnit('px');
        setName('');
        onClose();
    }

    function getContent(): ReactElement {
        switch (state) {
            case State.CREATE:
                return (
                    <div className='flex flex-col space-y-20'>
                        <div>
                            <p>{getText('widgets.canvas.formatEditor.create.body1')}</p>
                            <p className='mt-10'>{getText('widgets.canvas.formatEditor.create.body2')}</p>
                        </div>
                        <TextInput
                            onChange={(v) => setName(v)}
                            defaultValue={name}
                            placeholder={getText('widgets.canvas.formatEditor.create.placeholder')}
                        />
                    </div>
                );
            case State.UNAVAILABLE:
                return (
                    <div className='flex flex-col space-y-20'>
                        <div className='flex flex-col space-y-10'>
                            <p className='font-bold'>{getText('widgets.canvas.formatEditor.unavailable.headline')}</p>
                            <div className='flex flex-row space-x-10 text-companyMediumGrey'>
                                <p>{getPixelValue(width)}</p>
                                <p>x</p>
                                <p>{getPixelValue(height)}</p>
                            </div>
                        </div>
                        <div className='flex flex-col space-y-10'>
                            <p>{getText('widgets.canvas.formatEditor.unavailable.body1')}</p>
                            <p>{getText('widgets.canvas.formatEditor.unavailable.body2')}</p>
                            <p>{getText('widgets.canvas.formatEditor.unavailable.body3')}</p>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className='flex flex-col space-y-20'>
                        <div className='mb-28 flex flex-col space-y-20'>
                            <p>{getText('widgets.canvas.formatEditor.select.headline')}</p>
                            <div className='flex flex-row items-center'>
                                <TextInput
                                    className='basis-1/2'
                                    onChange={(v) => setWidth(v)}
                                    subline={width ? getConvertedValue(width) : ''}
                                    defaultValue={width}
                                    suffix={unit}
                                    type='number'
                                />
                                <span className='h-24 w-24 text-center text-black text-opacity-90'>X</span>
                                <TextInput
                                    className='basis-1/2'
                                    onChange={(v) => setHeight(v)}
                                    subline={height ? getConvertedValue(height) : ''}
                                    defaultValue={height}
                                    suffix={unit}
                                    type='number'
                                />
                            </div>
                        </div>
                        <ul className='flex flex-col space-y-15'>
                            <p>{getText('widgets.canvas.formatEditor.select.measuringUnit')}</p>
                            <button
                                className='flex flex-row items-center space-x-6'
                                type='button'
                                onClick={() => setUnit('px')}
                            >
                                <Radio checked={unit === 'px'} />
                                <p>{getText('widgets.canvas.formatEditor.select.units.px')}</p>
                            </button>
                            <button
                                className='flex flex-row items-center space-x-6'
                                type='button'
                                onClick={() => setUnit('mm')}
                            >
                                <Radio checked={unit === 'mm'} />
                                <p>{getText('widgets.canvas.formatEditor.select.units.mm')}</p>
                            </button>
                        </ul>
                    </div>
                );
        }
    }

    function getActions(): { id: string; label: string }[] {
        switch (state) {
            case State.CREATE:
                return [{ id: 'create', label: getText('widgets.canvas.formatEditor.create.submit') }];
            case State.UNAVAILABLE:
                return [{ id: 'email', label: getText('widgets.canvas.formatEditor.unavailable.submit') }];
            default:
                return [{ id: 'preCreate', label: getText('widgets.canvas.formatEditor.select.submit') }];
        }
    }

    function getDisabledActions(): string[] {
        switch (state) {
            case State.CREATE:
                return [name.length === 0 ? 'create' : ''];
            case State.UNAVAILABLE:
                return [];
            default:
                return [width.length === 0 || height.length === 0 ? 'preCreate' : ''];
        }
    }

    function getBackCallback(): undefined | (() => void) {
        switch (state) {
            case State.CREATE:
                return () => setState(State.SELECT);
            case State.UNAVAILABLE:
                return () => setState(State.SELECT);
            default:
                return undefined;
        }
    }

    async function createClientFormat(): Promise<void> {
        try {
            const layout = getLayoutForDimensions(currentTemplate, {
                width: Number.parseInt(width, 10),
                height: Number.parseInt(height, 10),
            });

            const templateFormat = await template.createTemplateFormat(currentTemplate.id, {
                name,
                width: Number.parseInt(getPixelValue(width), 10),
                height: Number.parseInt(getPixelValue(height), 10),
                layoutId: layout?.id,
            });
            notification.pushSuccess(
                getText('widgets.canvas.formatEditor.notifications.success.body'),
                getText('widgets.canvas.formatEditor.notifications.success.title'),
            );

            const tempTemplate = await template.loadBaseTemplate(currentTemplate.id);

            const providers = groupByGroupId(tempTemplate);

            const customProvider = providers.find((p) => p.name === getText('widgets.providerFormatSelect.custom'));
            const newFormat = customProvider?.formats.find((f) => f.id === templateFormat.formatId);

            if (customProvider && newFormat) {
                setAvailableProviders(providers);
                setSelectedProvider(customProvider);
                setSelectedFormat(newFormat);
                editor.setFormat(newFormat);
            }

            handleOnClose();
        } catch (error) {
            console.error('Failed to create format', error);
            notification.pushError(
                getText('widgets.canvas.formatEditor.notifications.error.body'),
                getText('widgets.canvas.formatEditor.notifications.error.title'),
            );
        }
    }

    function onSubmit(action: string): void {
        switch (action) {
            case 'create':
                createClientFormat();
                break;
            case 'email':
                window.location.href = getMailtoLink();
                handleOnClose();
                break;
            default:
                {
                    if (!template) return;
                    const layout = getLayoutForDimensions(currentTemplate, {
                        width: Number.parseInt(width, 10),
                        height: Number.parseInt(height, 10),
                    });
                    if (layout === undefined) {
                        setState(State.UNAVAILABLE);
                        return;
                    }

                    setState(State.CREATE);
                }
                break;
        }
    }

    return (
        <Modal
            showCancelIcon={false}
            title={getText('widgets.canvas.formatEditor.headline')}
            onClose={() => handleOnClose()}
            open={open}
            actions={getActions()}
            disableSubmitActionIds={getDisabledActions()}
            onSubmit={(a) => onSubmit(a)}
            onBack={getBackCallback()}
        >
            {getContent()}
        </Modal>
    );
}
