import { store } from '../stores';
import { HistoryActions } from '../stores/slices/history';
import { ICommonInstanceService } from './instance/common';

interface IHistoryService {
    undo: () => void;
    redo: () => void;
}

const HistoryService = (common: ICommonInstanceService): IHistoryService => {
    async function undo(): Promise<void> {
        store.dispatch(HistoryActions.undo());
        const { present } = store.getState().history.instanceHistory;

        await common.setObjectChange(present.id, present.path, present.value, true);
    }

    async function redo(): Promise<void> {
        store.dispatch(HistoryActions.redo());
        const { present } = store.getState().history.instanceHistory;
        await common.setObjectChange(present.id, present.path, present.value, true);
    }

    return {
        undo,
        redo,
    };
};

export default HistoryService;
