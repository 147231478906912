import React, { ReactElement } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { Loop } from '@mui/icons-material';
import useLocale from '../../hooks/use-locale';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';

export default function CanvasLoopToggle(): ReactElement {
    const isLooping = useStoreValue<boolean>(CanvasSelectors.isLooping());

    const { canvas } = useServices();

    const { getText } = useLocale();

    function onClick(): void {
        canvas.setLoop(!isLooping);
    }

    return (
        <IconButton tooltip={getText('widgets.canvas.controls.loop.tooltip')} onClick={() => onClick()}>
            <Loop color={isLooping ? 'primary' : 'inherit'} />
        </IconButton>
    );
}
