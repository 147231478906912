import React, { ReactElement, useEffect, useState } from 'react';
import useLocale from '../../../hooks/use-locale';
import Modal from '../../../components/modal/modal';
import TextField from '../../../components/inputs/text-field';
import { useServices } from '../../../hooks/use-services';
import { asyncInvoke } from '../../../utils/function';
import ListItemButton from '../../../components/list/list-item-button';
import { Radio } from '@mui/material';

type Props = {
    open: boolean;
    onClose(): void;
    onSubmit(name: string, selectedTemplate: string): void;
};

export default function NewCampaignItemForm(props: Props): ReactElement {
    const { open, onClose, onSubmit } = props;

    const [name, setName] = useState('');
    const [templates, setTemplates] = useState<{ id: string; name: string }[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>();
    const [allowSubmission, setAllowSubmission] = useState(false);

    const { template } = useServices();
    const { getText } = useLocale();

    useEffect(() => {
        setAllowSubmission(!!selectedTemplate && !!name);
    }, [name, selectedTemplate]);

    async function loadTemplates(): Promise<void> {
        const result = await template.getAllTemplates();
        setTemplates(result);
    }

    useEffect(asyncInvoke(loadTemplates), []);

    function handleTemplateChange(id: string): void {
        setSelectedTemplate(id);
    }

    function handleSubmit(action: string): void {
        if (action !== 'submit') {
            return;
        }

        if (!name || !selectedTemplate) {
            return;
        }

        onSubmit(name, selectedTemplate);
    }

    return (
        <Modal
            open={open}
            title={getText('components.dialogs.template.create.title')}
            actions={[
                {
                    id: 'submit',
                    label: getText('widgets.forms.common.actions.create'),
                },
            ]}
            onSubmit={(v) => handleSubmit(v)}
            disableSubmitActionIds={!allowSubmission ? ['submit'] : undefined}
            onClose={onClose}
            maxWidth='600px'
            maxHeight='760px'
        >
            <h3 className='text-xl'>
                {getText(
                    'components.dialogs.template.create.campaignItemName.label',
                    <span className='text-base text-companyDarkGrey'>*</span>,
                )}
            </h3>
            <TextField
                className='w-full'
                type='text'
                placeholder={getText('components.dialogs.template.create.campaignItemName.placeholder')}
                value={name}
                onChange={(v) => setName(v.target.value)}
            />
            <h3 className='w-320 text-xl'>
                {getText(
                    'components.dialogs.template.create.selectTemplates',
                    <span className='text-base text-companyDarkGrey'>*</span>,
                )}
            </h3>
            <div className='h-[288px] overflow-y-scroll'>
                {templates.map((item) => (
                    <ListItemButton
                        key={item.id}
                        className='flex h-72 w-full text-base'
                        onClick={() => handleTemplateChange(item.id)}
                    >
                        <Radio checked={selectedTemplate === item.id} />
                        <p className='w-full'>{item.name}</p>
                    </ListItemButton>
                ))}
            </div>
        </Modal>
    );
}
