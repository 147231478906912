import React, { ReactElement } from 'react';
import CharacterDropdown from '../../components/dropdown/character-dropdown';

const specialCharacters = [
    { value: '$' },
    { value: '£' },
    { value: '?' },
    { value: '@' },
    { value: '=' },
    { value: '§' },
    { value: '&' },
    { value: '%' },
    { value: '#' },
];

type Props = {
    onSetTextCharacter(value: string): void;
};

export default function SpecialCharacters({ onSetTextCharacter }: Props): ReactElement {
    return <CharacterDropdown characters={specialCharacters} setTextCharacter={onSetTextCharacter} />;
}
