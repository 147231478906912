import React, { ReactElement, ReactNode } from 'react';
import { Toolbar as MUIToolbar } from '@mui/material';

type Props = {
    className?: string;
    children?: ReactNode;
};

export default function Toolbar(props: Props): ReactElement {
    const { className, children } = props;

    return <MUIToolbar className={`min-h-fit pl-1 pr-1 ${className}`}>{children}</MUIToolbar>;
}

Toolbar.defaultProps = {
    className: '',
    children: undefined,
};
