import { useContext } from 'react';
import { NotificationContext } from '../providers/notification-provider';

type NotificationApi = {
    pushError(message: string, title?: string): void;
    pushSuccess(message: string, title?: string): void;
    pushInfo(message: string, title?: string): void;
};

export default function useNotification(): NotificationApi {
    const notification = useContext(NotificationContext);

    return {
        pushError: (msg, title) => notification.showError(msg, title),
        pushSuccess: (msg, title) => notification.showSuccess(msg, title),
        pushInfo: (msg, title) => notification.showInfo(msg, title),
    };
}
