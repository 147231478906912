import { Template, TemplateFormat } from '../generated/gql/graphql';
import { ProviderWithFormats } from '../types/provider';
import React, { createContext, ReactElement, useEffect, useMemo, useState } from 'react';
import { useStoreValue } from '../hooks/use-store-value';
import EditorSelectors from '../stores/selectors/editor';
import useLocale from '../hooks/use-locale';

type FormatProviderContextType = {
    setAvailableProviders: (providers: ProviderWithFormats[]) => void;
    availableProviders: ProviderWithFormats[];
    selectedProvider?: ProviderWithFormats;
    setSelectedProvider: (provider: ProviderWithFormats) => void;
    selectedFormat?: TemplateFormat;
    setSelectedFormat: (format: TemplateFormat) => void;
    groupByGroupId: (targetTemplate: Template) => ProviderWithFormats[];
};

export const FormatProviderContext = createContext<FormatProviderContextType>({
    availableProviders: [],
    setAvailableProviders: () => {},
    selectedProvider: undefined,
    setSelectedProvider: () => {},
    selectedFormat: undefined,
    setSelectedFormat: () => {},
    groupByGroupId: () => [],
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function FormatProvider(props: Props): ReactElement {
    const { children } = props;

    const { getText } = useLocale();
    const selectedTemplate = useStoreValue<Template>(EditorSelectors.getActiveTemplate());

    const [availableProviders, setAvailableProviders] = useState<ProviderWithFormats[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<ProviderWithFormats>(availableProviders[0]);
    const [selectedFormat, setSelectedFormat] = useState<TemplateFormat>();

    function groupByGroupId(targetTemplate: Template): ProviderWithFormats[] {
        const providers: ProviderWithFormats[] = [];
        targetTemplate.formats?.forEach((f) => {
            const provider = providers.find((v) => v.name === f.groupId);
            if (provider) {
                provider.formats.push(f);
            } else {
                providers.push({ name: f.groupId!, formats: [f] });
            }
        });

        targetTemplate.clientFormats?.forEach((f) => {
            if (!f.format) return;

            const provider = providers.find((v) => v.name === getText('widgets.providerFormatSelect.custom'));
            if (provider) {
                if (
                    provider.formats.some(
                        (v) =>
                            `${v.name}-${v.width}x${v.height}` ===
                            `${f.format!.name}-${f.format!.width}x${f.format!.height}`,
                    )
                )
                    return;
                provider.formats.push(f.format);
            } else {
                providers.push({ name: getText('widgets.providerFormatSelect.custom'), formats: [f.format] });
            }
        });

        return providers;
    }

    useEffect(() => {
        if (selectedTemplate) {
            const providers = groupByGroupId(selectedTemplate);
            setAvailableProviders(providers);
            setSelectedProvider(providers[0]);
            setSelectedFormat(providers[0].formats[0]);
        }
    }, [selectedTemplate]);

    const value = useMemo(
        () => ({
            selectedProvider,
            setSelectedProvider,
            selectedFormat,
            setSelectedFormat,
            availableProviders,
            setAvailableProviders,
            groupByGroupId,
        }),
        [selectedProvider, selectedFormat, availableProviders],
    );

    return <FormatProviderContext.Provider value={value}>{children}</FormatProviderContext.Provider>;
}
