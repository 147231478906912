import { selectImageAssetGroupById } from './select-image-assetgroup.by-id';
import { selectTextProperties } from './select-text-properties';
import { selectTextPropertyById } from './select-text-property-by-id';
import { selectImageProperties } from './select-image-properties';
import { selectImagePropertyById } from './select-image-property-by-id';
import { selectImagePropertiesByAssetGroup } from './select-image-properties-by-asset-group';

const TemplateSelector = {
    getImageAssetGroupById: selectImageAssetGroupById,
    getTextProperties: selectTextProperties,
    getTextPropertyById: selectTextPropertyById,
    getImageProperties: selectImageProperties,
    getImagePropertyById: selectImagePropertyById,
    getImagePropertiesByAssetGroup: selectImagePropertiesByAssetGroup,
};

export default TemplateSelector;
