import { configureStore } from '@reduxjs/toolkit';
import canvas from './slices/canvas';
import editor from './slices/editor';
import generator from './slices/generator';
import history from './slices/history';

export const store = configureStore({
    reducer: {
        canvas,
        editor,
        generator,
        history,
    },
});

export type RootState = ReturnType<typeof store.getState>;
