import React, { ReactElement, ReactNode } from 'react';

type Props = {
    label: string;
    icon: ReactNode;
    onClick: () => void;
};

export default function TextIconButton(props: Props): ReactElement {
    const { label, icon, onClick } = props;
    return (
        <button className='flex cursor-pointer flex-row items-center gap-10' onClick={onClick} type='button'>
            <p className='text-base uppercase text-companyDarkGrey'>{label}</p>
            {icon}
        </button>
    );
}
