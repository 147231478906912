import React, { ReactElement } from 'react';
import ActiveTemplateName from '../template/active-template-name';
import CanvasHeaderMore from './canvas-header-more';

type Props = {
    onResetSize(): void;
    onFitToCanvas(): void;
};

export default function CanvasHeader(props: Props): ReactElement {
    const { onFitToCanvas, onResetSize } = props;

    return (
        <div className='z-50 m-15 flex h-60 justify-between space-x-20'>
            <ActiveTemplateName classNames='my-auto text-lg font-bold uppercase text-companyMediumGrey' />
            <div className='flex space-x-15'>
                <CanvasHeaderMore onFitToCanvas={onFitToCanvas} onResetSize={onResetSize} />
            </div>
        </div>
    );
}
