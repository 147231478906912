import React, {
    forwardRef,
    ForwardRefExoticComponent,
    MouseEvent,
    PointerEvent,
    ReactElement,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    WheelEvent,
} from 'react';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import { TemplateFormat } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import CanvasLevelMenu from './canvas-level-menu';
import { TemplateObject } from '@metaphore/magnolia-rendering';

interface IPosition {
    x: number;
    y: number;
}

enum DragMode {
    None,
    Space,
    Mouse,
}

export interface ICanvasPreview extends ForwardRefExoticComponent<ReactElement> {
    resetSize: () => void;
    fitToCanvas: () => void;
}

const CanvasPreview = forwardRef<ICanvasPreview, {}>((_props, ref) => {
    const canvasRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [zoom, setZoom] = useState<number>(1);
    const [prevZoom, setPrevZoom] = useState<number>(zoom);
    const [dragging, setDragging] = useState<boolean>(false);
    const [mouseOnCanvas, setMouseOnCanvas] = useState<boolean>(false);
    const [dragMode, setDragMode] = useState<DragMode>(DragMode.None);
    const [position, setPosition] = useState<IPosition>({ x: -1000, y: -1000 });

    const levelMenuRef = useRef<HTMLDivElement>(null);
    const [levelMenuPosition, setLevelMenuPosition] = useState<IPosition>({ x: 0, y: 0 });
    const [levelMenuItems, setLevelMenuItems] = useState<TemplateObject[]>([]);
    const [isLevelMenuVisible, setIsLevelMenuVisible] = useState(false);

    const { canvas } = useServices();

    const [isInitialized, setIsInitialized] = useState(false);

    const templateInstanceId = useStoreValue(EditorSelectors.getTemplateInstanceId());
    const format = useStoreValue<TemplateFormat>(EditorSelectors.getCurrentFormat());

    /**
     * Centers the canvas and adjusts its zoom to cover up the whole available space
     * while still be fully contained in it
     */
    function coverAvailableSpace(): void {
        setTimeout(() => {
            if (canvasRef.current && wrapperRef.current) {
                if (
                    wrapperRef.current.clientWidth / canvasRef.current.clientWidth >
                    wrapperRef.current.clientHeight / canvasRef.current.clientHeight
                ) {
                    setZoom(wrapperRef.current.clientHeight / canvasRef.current.clientHeight);
                } else {
                    setZoom(wrapperRef.current.clientWidth / canvasRef.current.clientWidth);
                }

                setPosition({
                    x: canvasRef.current.clientWidth / 2,
                    y: wrapperRef.current.clientHeight / 2,
                });
            }
        });
    }

    function onInitialize(): void {
        if (isInitialized) {
            return;
        }

        if (!templateInstanceId) {
            return;
        }

        if (!canvasRef.current) {
            return;
        }

        canvas.init(canvasRef.current, format);

        coverAvailableSpace();
        setDragMode(DragMode.None);
        setIsInitialized(true);
    }

    function onFormatChanged(): void {
        canvas.resize(format);
        coverAvailableSpace();
    }

    function onMouseZoom(ev: WheelEvent<HTMLDivElement>): void {
        const mouseEvent = ev;

        setZoom(zoom * (1 - mouseEvent.deltaY / 1000));

        // Calculate translation due to zoom
        if (canvasRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const canvasPosition = position;

            // Calculate current mouse position on canvas
            const mousePositionX = mouseEvent.pageX - (canvasRect.left + canvasRect.width / 2);
            const mousePositionY = mouseEvent.pageY - (canvasRect.top + canvasRect.height / 2);
            const relativeOffsetX = mousePositionX / canvasRect.width;
            const relativeOffsetY = mousePositionY / canvasRect.height;

            // Calculate new position on canvas that has to be under the mouse cursor
            const xDelta = (canvasRect.width / prevZoom) * relativeOffsetX * zoom - mousePositionX;
            const yDelta = (canvasRect.height / prevZoom) * relativeOffsetY * zoom - mousePositionY;

            setPosition({
                x: canvasPosition.x - xDelta,
                y: canvasPosition.y - yDelta,
            });

            setPrevZoom(zoom);
        }
    }

    function onPointerMove(ev: PointerEvent<HTMLDivElement>): void {
        if (!dragging) {
            return;
        }

        setPosition({
            x: position.x + ev.movementX,
            y: position.y + ev.movementY,
        });
    }

    function onPointerDown(): void {
        if (dragMode !== DragMode.None) {
            setDragging(true);
        }
    }

    function resetSelection(): void {
        canvas.selectObject(undefined);
    }

    function onMouseDown(ev: MouseEvent): void {
        if (ev.button === 2 || ev.button === 1) {
            ev.preventDefault();
            setDragMode(DragMode.Mouse);
            setDragging(true);
        }

        if (wrapperRef.current && wrapperRef.current.contains(ev.currentTarget) && !mouseOnCanvas) {
            resetSelection();
        }
    }

    function onResetSize(): void {
        if (canvasRef.current && wrapperRef.current) {
            setPosition({
                x: canvasRef.current.clientWidth / 2,
                y: wrapperRef.current.clientHeight / 2,
            });
            setZoom(1);
            setPrevZoom(1);
        }
    }

    function onRightClick(ev: MouseEvent): void {
        ev.preventDefault();
        if (ev.button === 2) {
            const items = canvas.getTemplateObjectsAtPoint(ev.nativeEvent.offsetX, ev.nativeEvent.offsetY);
            setLevelMenuItems(items);
        }
    }

    useEffect(onInitialize, [templateInstanceId, canvas, format, isInitialized]);
    useEffect(onFormatChanged, [format, canvas]);
    useEffect(() => {
        function onKeyDown(ev: KeyboardEvent): void {
            if (ev.key === ' ') {
                if (ev.target === document.body) {
                    ev.preventDefault();
                }
                setDragMode(DragMode.Space);
            }
        }

        if (dragMode === DragMode.None) {
            window.addEventListener('keydown', onKeyDown);
        }

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [dragMode, dragging]);

    useEffect(() => {
        function onPointerUp(): void {
            setDragging(false);

            // Exit drag mode
            if (dragMode === DragMode.Mouse) {
                setDragMode(DragMode.None);
            }
        }

        function onKeyUp(ev: KeyboardEvent): void {
            if (dragMode === DragMode.Space && ev.key === ' ') {
                ev.preventDefault();
                setDragMode(DragMode.None);
            }
        }

        // Register events
        window.addEventListener('pointerup', onPointerUp);
        window.addEventListener('keyup', onKeyUp);

        // Cleanup events
        return () => {
            window.removeEventListener('pointerup', onPointerUp);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [dragMode]);

    useEffect(() => {
        if (!wrapperRef.current) return;

        function hideLevelMenu(): void {
            setIsLevelMenuVisible(false);
        }

        function showLevelMenu(e: globalThis.MouseEvent): void {
            if (!wrapperRef.current || !levelMenuRef.current) {
                return;
            }

            e.preventDefault();
            if (isLevelMenuVisible) {
                setIsLevelMenuVisible(false);
            } else {
                setIsLevelMenuVisible(true);
                const wrapperWidth = wrapperRef.current.clientWidth;
                const wrapperHeight = wrapperRef.current.clientHeight;

                const wrapperX = wrapperRef.current.getBoundingClientRect().left;
                const wrapperY = wrapperRef.current.getBoundingClientRect().top;

                const width = levelMenuRef.current.clientWidth;
                const height = levelMenuRef.current.clientHeight;

                let posX = e.pageX - wrapperX;
                let posY = e.pageY - wrapperY;

                if (posX + width > wrapperWidth) {
                    posX -= width;
                }

                if (posY + height > wrapperHeight) {
                    posY -= height;
                }

                setLevelMenuPosition({ x: posX, y: posY });
            }
        }

        wrapperRef.current.addEventListener('click', hideLevelMenu);
        wrapperRef.current.addEventListener('contextmenu', showLevelMenu);

        // eslint-disable-next-line consistent-return
        return (): void => {
            if (!wrapperRef.current) return;
            wrapperRef.current.removeEventListener('click', hideLevelMenu);
            wrapperRef.current.removeEventListener('contextmenu', showLevelMenu);
        };
    }, [wrapperRef, levelMenuRef, isLevelMenuVisible]);

    useImperativeHandle(
        ref,
        () =>
            ({
                resetSize: () => {
                    onResetSize();
                },
                fitToCanvas: () => {
                    coverAvailableSpace();
                },
            } as ICanvasPreview),
    );

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            style={{ cursor: dragging ? 'grabbing' : dragMode ? 'grab' : 'default' }}
            onMouseEnter={() => document.body.classList.add('overflow-hidden')}
            onMouseLeave={() => document.body.classList.remove('overflow-hidden')}
            onPointerMove={onPointerMove}
            onPointerDown={onPointerDown}
            onWheel={isLevelMenuVisible ? undefined : onMouseZoom}
            onContextMenu={(e) => e.preventDefault()}
            onMouseDown={onMouseDown}
            className='relative z-40 mx-15 flex h-full justify-center overflow-hidden'
            ref={wrapperRef}
        >
            {levelMenuItems.length > 0 && (
                <CanvasLevelMenu
                    ref={levelMenuRef}
                    visible={isLevelMenuVisible}
                    items={levelMenuItems}
                    position={levelMenuPosition}
                />
            )}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                style={{
                    width: `${format.width}px`,
                    height: `${format.height}px`,
                    transform: `translate3d(
                        ${position.x - format.width / 2}px, 
                        ${position.y - format.height / 2}px, 0px) 
                        scale(${zoom})`,
                }}
                className='absolute'
                onMouseEnter={() => setMouseOnCanvas(true)}
                onMouseLeave={() => setMouseOnCanvas(false)}
                onMouseDown={(e) => onRightClick(e)}
                ref={canvasRef}
            />
        </div>
    );
});

export default CanvasPreview;
