import React, { ReactElement } from 'react';
import { ChevronRightRounded } from '@mui/icons-material';

type Props = {
    label: string;
    onClick(): void;
};

export default function SelectButton(props: Props): ReactElement {
    const { label, onClick } = props;

    return (
        <button
            type='button'
            onClick={() => onClick()}
            className='flex w-full flex-row items-center justify-between rounded-10 p-20 text-left text-xl uppercase text-companyMediumGrey shadow'
        >
            {label}
            <ChevronRightRounded />
        </button>
    );
}
