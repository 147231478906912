import { graphql } from '../../../generated/gql';
import { CampaignItemsQuery } from '../../../generated/gql/graphql';
import { ArrayElement } from '../../../utils/graphql-utils';

export const getCampaignItemsById = graphql(`
    query CampaignItems($query: CampaignItemQuery!) {
        campaignItems(query: $query) {
            id
            instances {
                language {
                    id
                    name
                }
                languageId
                templateInstanceId
                thumbnailUrl
                templateInstance {
                    formatValues {
                        formatId
                    }
                }
            }
            name
            createdAt
            updatedAt
            templateId
            template {
                id
                name
                motion {
                    fps
                    length
                }
                formats {
                    groupId
                    name
                    width
                    height
                }
                instances {
                    id
                    name
                    languageId
                    templateId
                }
                outputChannels
            }
        }
    }
`);

export type CampaignItem = ArrayElement<CampaignItemsQuery['campaignItems']>;
