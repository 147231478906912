import React, { HTMLInputTypeAttribute, ReactElement } from 'react';

type Props = {
    className?: string;
    onChange: (value: string) => void;
    defaultValue?: string;
    placeholder?: string;
    subline?: string;
    suffix?: string;
    type?: HTMLInputTypeAttribute;
};

export default function TextInput(props: Props): ReactElement {
    const { className, defaultValue, placeholder, subline, suffix, type, onChange } = props;

    return (
        <div className={`relative flex flex-col justify-center ${className}`}>
            <input
                defaultValue={defaultValue}
                className='w-full rounded-10 border border-companyMediumGrey px-24 py-6 pr-40 [appearance:textfield] focus:border-companyMediumGrey focus:outline-none hover:border-companyDarkGrey [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                type={type}
                placeholder={placeholder}
                onChange={(v) => onChange(v.currentTarget.value)}
            />
            {subline && <p className='absolute -bottom-20 ml-24 text-sm text-companyMediumGrey'>{subline}</p>}
            {suffix && <span className='absolute right-24'>{suffix}</span>}
        </div>
    );
}

TextInput.defaultProps = {
    className: '',
    defaultValue: '',
    placeholder: '',
    subline: '',
    suffix: '',
    type: 'text',
};
