import { Queries } from '../repositories';
import { GraphQlClient } from '../providers/graphql-provider';
import { Language } from '../types/language';

interface ILanguageService {
    getAllLanguages: () => Promise<Language[]>;
}

const LanguageService = (): ILanguageService => {
    async function getAllLanguages(): Promise<Language[]> {
        const result = await GraphQlClient.query({ query: Queries.language.getLanguages });

        if (result.error) {
            throw result.error;
        }

        const data = result.data.languages as Language[];
        if (!data) {
            throw new Error('No data');
        }

        return data;
    }

    return {
        getAllLanguages,
    };
};

export type { ILanguageService };
export default LanguageService;
