import React, { ReactElement, ReactNode } from 'react';
import Class from '../../utils/classes';

type Props = {
    children: ReactNode;
    className?: string;
};

export default function TableBodyHeadCell(props: Props): ReactElement {
    const { children, className } = props;
    return (
        <th
            className={Class.classNames(
                'border-company-DarkGrey sticky left-0 z-10 border-r-1 bg-white text-base text-companyDarkGrey',
                className || '',
            )}
        >
            {children}
        </th>
    );
}

TableBodyHeadCell.defaultProps = {
    className: '',
};
