import moment from 'moment/moment';

function formatTimestamp(timestamp: string): string {
    const parsedDate = moment(timestamp);
    return parsedDate.format('DD/MM/YYYY');
}

const StringFormatUtils = {
    formatTimestamp,
};

export default StringFormatUtils;
