import React, { ReactElement, ReactNode } from 'react';
import { ListItem as MUIListItem } from '@mui/material';

type Props = {
    disablePadding?: boolean;
    className?: string;
    secondaryAction?: ReactNode;
    children?: ReactNode;
};

export default function ListItem(props: Props): ReactElement {
    const { disablePadding, className, secondaryAction, children } = props;
    return (
        <MUIListItem disablePadding={disablePadding} className={className} secondaryAction={secondaryAction}>
            {children}
        </MUIListItem>
    );
}

ListItem.defaultProps = {
    disablePadding: false,
    children: undefined,
    className: undefined,
    secondaryAction: undefined,
};
