import { gql } from '@apollo/client';

export const getById = gql`
    query getCampaignById($campaignId: ID!) {
        campaign(id: $campaignId) {
            id
            createdAt
            updatedAt
            name
            clientId
            languageIds
            settings
            items {
                id
                name
                settings
                templateId
                instances {
                    templateInstance {
                        formatValues {
                            formatId
                        }
                    }
                }
            }
        }
    }
`;
