import { TemplateFormat } from '../generated/gql/graphql';
import { store } from '../stores';
import { EditorActions } from '../stores/slices/editor';
import { ICampaignService } from './campaign';

interface IEditorService {
    loadCampaign(id: string, withDefaultTemplate?: boolean): Promise<void>;

    setFormat(format: TemplateFormat): void;

    setCampaignItem(campaignItemId: string): void;

    setIsFormatSpecificActive(isActive: boolean): void;

    reset(): void;
}

export const EditorService = (campaignService: ICampaignService): IEditorService => {
    async function loadCampaign(id: string, withDefaultTemplate?: boolean): Promise<void> {
        const campaign = await campaignService.getCampaignDetailsById(id);
        store.dispatch(EditorActions.setCampaign({ campaign }));
        store.dispatch(EditorActions.setCampaignItems({ items: campaign.items || [] }));

        if (withDefaultTemplate) {
            // ToDo: load default template
        }
    }

    function setFormat(format: TemplateFormat): void {
        const { instanceTemplate } = store.getState().editor;

        store.dispatch(EditorActions.setSelectedFormat({ format }));

        store.dispatch(
            EditorActions.setIsFormatSpecificActive({
                isFormatSpecificActive:
                    instanceTemplate?.formatValues?.find((e) => e.formatId === format.id) !== undefined,
            }),
        );
    }

    function setCampaignItem(campaignItemId: string): void {
        const { campaign } = store.getState().editor;

        if (campaign && campaign.items) {
            const item = campaign.items.find((e) => e.id === campaignItemId);
            if (item) {
                store.dispatch(EditorActions.setSelectedCampaignItem({ item }));
            }
        }
    }

    function setIsFormatSpecificActive(isActive: boolean): void {
        store.dispatch(EditorActions.setIsFormatSpecificActive({ isFormatSpecificActive: isActive }));
    }

    function reset(): void {
        store.dispatch(EditorActions.reset());
    }

    return {
        loadCampaign,
        setFormat,
        setCampaignItem,
        setIsFormatSpecificActive,
        reset,
    };
};

export type { IEditorService };
export default EditorService;
