import React, { ReactElement, useEffect, useState } from 'react';
import useLocale from '../../../../hooks/use-locale';
import SearchField from '../../../../components/inputs/search-field';
import { useStoreValue } from '../../../../hooks/use-store-value';
import EditorSelectors from '../../../../stores/selectors/editor';

import { useServices } from '../../../../hooks/use-services';
import short from 'short-uuid';
import TemplateSelector from '../../../../stores/selectors/template';
import TextReplaceField from './text-replace-field';
import Class from '../../../../utils/classes';
import { CustomTemplateObjectChange } from '../../../../types/template';
import { CampaignItem, CampaignItemInstance, TemplateObject, TemplateText } from '../../../../generated/gql/graphql';
import { ReactComponent as SearchAndReplaceIcon } from '../../../../assets/icons/search-and-replace-icon.svg';
import { Check } from '@mui/icons-material';

type Props = {
    onSubmit(templateObjectChanges: CustomTemplateObjectChange[]): void;
};

type ReplaceValue = {
    instanceId: string;
    objectId: string;
    value: string;
};

export default function TextReplace(props: Props): ReactElement {
    const { onSubmit } = props;

    const { getText } = useLocale();
    const { campaign } = useServices();

    const activeCampaignItem = useStoreValue<CampaignItem | undefined>(EditorSelectors.getActiveCampaignItem());
    const [campaignItem, setCampaignItem] = useState<CampaignItem | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>('');

    const [replaceValue, setReplaceValue] = useState<string>('');
    const [replacedValues, setReplacedValues] = useState<ReplaceValue[]>([]);

    const textProps = useStoreValue<TemplateObject[]>(TemplateSelector.getTextProperties());

    useEffect(() => {
        async function fetch(): Promise<void> {
            if (activeCampaignItem) {
                const result = await campaign.getCampaignItemTemplateInstancesById(activeCampaignItem.id);
                setCampaignItem(result);
            }
        }

        fetch();
    }, [activeCampaignItem]);

    function handleOnSubmit(replaceValues: ReplaceValue[]): void {
        onSubmit(
            replaceValues.map((item) => ({
                instanceId: item.instanceId,
                id: item.objectId,
                value: item.value,
                path: 'content.text',
            })),
        );
    }

    function handleOnReplace(instanceId: string, objectId: string, value: string): void {
        const index = replacedValues.findIndex((item) => item.instanceId === instanceId && item.objectId === objectId);
        if (index !== -1) {
            replacedValues.splice(index, 1);
        } else {
            replacedValues.push({
                instanceId,
                objectId,
                value: value.replace(new RegExp(searchValue, 'gi'), replaceValue),
            });
        }
        setReplacedValues([...replacedValues]);
        handleOnSubmit([...replacedValues]);
    }

    function getReplacedValue(instanceId: string, objectId: string): string {
        const value = replacedValues.find((item) => item.instanceId === instanceId && item.objectId === objectId);
        return value?.value || '';
    }

    function getTextProperty(instance: CampaignItemInstance, object: TemplateObject): string {
        return (
            instance.templateInstance?.values
                ?.find((item) => item.id === object.id && item.path === 'content.text')
                ?.value?.toString() ??
            (object.content as TemplateText).text ??
            ''
        );
    }

    function getTextTemplateObjects(instance: CampaignItemInstance): TemplateObject[] {
        return textProps
            .filter((object) => !object.disabled || object.editable)
            .filter((object) => getTextProperty(instance, object).toLowerCase().includes(searchValue.toLowerCase()));
    }

    function getInstanceCount(): number {
        let count = 0;
        if (campaignItem) {
            campaignItem.instances?.forEach((i) => {
                const objects = getTextTemplateObjects(i);
                objects.forEach((object) => {
                    if (
                        !replacedValues.some(
                            (item) => item.instanceId === i.templateInstanceId && item.objectId === object.id,
                        )
                    ) {
                        count += 1;
                    }
                });
            });
        }
        return count;
    }

    function getTextTemplateObjectCount(): number {
        return campaignItem?.instances?.map((i) => getTextTemplateObjects(i).length).reduce((a, b) => a + b, 0) || 0;
    }

    function handleOnReplaceAll(): void {
        if (campaignItem?.instances) {
            setReplacedValues([]);
            campaignItem.instances.forEach((i) => {
                getTextTemplateObjects(i).forEach((object) => {
                    const index = replacedValues.findIndex(
                        (item) => item.instanceId === i.templateInstanceId && item.objectId === object.id,
                    );
                    if (index !== -1) {
                        return;
                    }
                    handleOnReplace(i.templateInstanceId, object.id!, getTextProperty(i, object));
                });
            });
        }
    }

    return (
        <>
            <p>{getText('widgets.canvas.searchAndReplace.text.subline')}</p>
            <div className='flex flex-col overflow-hidden rounded-10 shadow'>
                <div className='flex w-full flex-col items-start justify-between space-y-20 border-b-1 px-30 pb-20 pt-20'>
                    <p className='self-start text-xl uppercase text-companyMediumGrey'>
                        {getText('widgets.canvas.searchAndReplace.text.subHeader')}
                    </p>
                    <div className='flex w-full flex-1 flex-row space-x-20'>
                        <div className='flex w-full flex-1 flex-col space-y-20'>
                            <SearchField
                                label={getText('widgets.canvas.searchAndReplace.text.searchLabel')}
                                resultLabel={getText('widgets.canvas.searchAndReplace.text.searchFound', {
                                    count: getTextTemplateObjectCount(),
                                })}
                                placeholder={getText('widgets.canvas.searchAndReplace.searchPlaceholder')}
                                onChange={(v) => setSearchValue(v)}
                                disabled={replacedValues.length > 0}
                            />
                            <SearchField
                                label={getText('widgets.canvas.searchAndReplace.text.replaceWithLabel')}
                                placeholder={getText('widgets.canvas.searchAndReplace.text.replaceWithPlaceholder')}
                                onChange={(v) => setReplaceValue(v)}
                                type='replace'
                                disabled={replacedValues.length > 0}
                            />
                        </div>
                        <div className='flex min-w-[182px] items-end'>
                            <button
                                type='button'
                                className={Class.classNames(
                                    'mb-10 flex flex-row whitespace-nowrap rounded-xl border-1 px-15 py-6 text-sm',
                                    searchValue === '' || replaceValue === ''
                                        ? 'border-companyGrey text-companyGrey'
                                        : 'border-company text-company',
                                    getTextTemplateObjectCount() === replacedValues.length
                                        ? 'border-companyMediumGrey text-companyMediumGrey'
                                        : '',
                                )}
                                disabled={!searchValue || !replaceValue}
                                onClick={() => handleOnReplaceAll()}
                            >
                                {getTextTemplateObjectCount() === replacedValues.length ? (
                                    <Check className='mr-6 h-12 w-12 text-companyMediumGrey' />
                                ) : (
                                    <SearchAndReplaceIcon
                                        className={Class.classNames(
                                            'mr-6 h-12 w-12 ',
                                            searchValue === '' || replaceValue === ''
                                                ? 'text-companyGrey'
                                                : 'text-company',
                                            getTextTemplateObjectCount() === replacedValues.length
                                                ? 'text-companyMediumGrey'
                                                : '',
                                        )}
                                    />
                                )}
                                {getTextTemplateObjectCount() === replacedValues.length
                                    ? getText('widgets.canvas.searchAndReplace.text.replacedAll')
                                    : getText('widgets.canvas.searchAndReplace.text.replaceAll', {
                                          count: getInstanceCount(),
                                      })}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className='max-h-420 min-h-[320px]
                    overflow-y-scroll px-30 py-20 scrollbar-thin
                    scrollbar-track-companyLightGrey scrollbar-thumb-companyGrey
                    scrollbar-track-rounded-10 scrollbar-thumb-rounded-10'
                >
                    <ul className='flex flex-col space-y-20'>
                        {campaignItem &&
                            campaignItem.instances &&
                            campaignItem.instances.map((i) => (
                                <li key={short().generate()} className='flex flex-col space-y-10'>
                                    <p className='mb-12'>{i.language?.name}</p>
                                    {getTextTemplateObjects(i).length > 0 ? (
                                        getTextTemplateObjects(i).map((object) => (
                                            <TextReplaceField
                                                searchValue={searchValue}
                                                key={short().generate()}
                                                name={object.name || ''}
                                                value={getTextProperty(i, object)}
                                                disabled={!searchValue || !replaceValue}
                                                onReplace={(v) => handleOnReplace(i.templateInstanceId, object.id!, v)}
                                                replacedValue={getReplacedValue(i.templateInstanceId, object.id!)}
                                            />
                                        ))
                                    ) : (
                                        <p className='text-sm text-companyMediumGrey'>
                                            {getText('widgets.canvas.searchAndReplace.text.noResults')}
                                        </p>
                                    )}
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
