import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { useAppConfig } from './config/app-config-use';

type Props = {
    children: ReactNode;
};

export default function KeycloakProvider(props: Props): ReactElement {
    const { children } = props;
    const appConfig = useAppConfig();
    const [keycloak, setKeycloak] = useState<Keycloak | null>(null);

    useEffect(() => {
        if (!keycloak && !appConfig.loading) {
            const keycloakConfig = appConfig.config.keycloak;
            setKeycloak(new Keycloak(keycloakConfig));
        }
    }, [keycloak, appConfig.loading, appConfig.config?.keycloak]);

    if (!keycloak) {
        return <>Loading...</>;
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{ onLoad: 'login-required' }}
            isLoadingCheck={(client) => !client.authenticated}
            LoadingComponent={<div />}
        >
            {children}
        </ReactKeycloakProvider>
    );
}
