import { gql } from '@apollo/client';

export const createClientFormat = gql`
    mutation CreateClientFormat($input: CreateClientTemplateFormatInput!) {
        createClientFormat(input: $input) {
            id
            templateId
            formatId
        }
    }
`;
