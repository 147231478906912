import { gql } from '@apollo/client';

export const getClientName = gql`
    query getClientName {
        me {
            client {
                name
            }
        }
    }
`;
