import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Video from './pages/editor/video';
import Generator from './pages/generator';
import NotFound from './pages/not-found';
import Still from './pages/editor/still';
import Banner from './pages/editor/banner';
import { Routes as RoutePaths } from './types/enums/routes';
import CampaignPage from './pages/campaign';
import CampaignsPage from './pages/campaigns';
import MainContainer from './components/layout/main-container';
import MainSidebar from './components/layout/main-sidebar';
import EditorContainer from './components/layout/editor/editor-container';
import AppHeader from './components/layout/app-header';

function App(): ReactElement {
    return (
        <BrowserRouter>
            <MainContainer>
                <MainSidebar />
                <EditorContainer>
                    <AppHeader />
                    <Routes>
                        <Route path={RoutePaths.CAMPAIGNS} element={<CampaignsPage />} />
                        <Route path={RoutePaths.CAMPAIGN} element={<CampaignPage />} />
                        <Route path={RoutePaths.VIDEO} element={<Video />} />
                        <Route path={RoutePaths.STILL} element={<Still />} />
                        <Route path={RoutePaths.BANNER} element={<Banner />} />
                        <Route path={RoutePaths.GENERATOR} element={<Generator />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </EditorContainer>
            </MainContainer>
        </BrowserRouter>
    );
}

export default App;
