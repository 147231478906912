import React, { forwardRef, ForwardRefExoticComponent, ReactElement, useImperativeHandle } from 'react';
import {
    FormControl as MUIFormControl,
    InputLabel as MUIInputLabel,
    MenuItem,
    Select as MUISelect,
    SelectChangeEvent,
} from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded, ExpandMoreOutlined } from '@mui/icons-material';
import IconButton from '../buttons/icon-button';
import Tooltip from '../tooltip/tooltip';
import { SelectOptions } from '../../types/select-options';

type Props = {
    label: string;
    options: string[] | SelectOptions[];
    onChange: (value: any) => void;
    selectedOption?: string;
    showArrows?: boolean;
    nextArrowDisabled?: boolean;
    prevArrowDisabled?: boolean;
    onNext?: () => void;
    onPrevious?: () => void;
    className?: string;
    showTooltip?: boolean;
    iconTooltipText?: string;
};

export interface ISelect extends ForwardRefExoticComponent<ReactElement> {
    next: () => void;
    previous: () => void;
}

const Select = forwardRef<ISelect, Props>((props, ref) => {
    const {
        label,
        options,
        selectedOption,
        onChange,
        showArrows,
        nextArrowDisabled,
        prevArrowDisabled,
        onNext,
        onPrevious,
        showTooltip,
        iconTooltipText,
        className,
    } = props;

    const selectedIndex: number = selectedOption ? +selectedOption : -1;

    function handleChange(event: SelectChangeEvent): void {
        onChange(event.target.value);
    }

    function handleOnPrev(): void {
        if (selectedIndex > 0) {
            onChange(selectedIndex - 1);
        }
        if (selectedIndex === 0 && showArrows && onPrevious) {
            onPrevious();
        }
    }

    function handleOnNext(): void {
        if (selectedIndex < options.length - 1) {
            onChange(selectedIndex + 1);
        }
        if (selectedIndex === options.length - 1 && showArrows && onNext) {
            onNext();
        }
    }

    function getOptionLabel(option: string | SelectOptions): string {
        if (!option) return '';

        return typeof option === 'string' ? option : option.label;
    }

    function getOptionIcon(option: string | SelectOptions): ReactElement | undefined {
        return typeof option === 'string' || !option ? undefined : option.icon;
    }

    function getOptionHasIcon(index: string): boolean {
        if ((Array.isArray(options) && options.some((s) => typeof s === 'string')) || index === '-1' || index === '') {
            return false;
        }

        const formattedOptions = options as SelectOptions[];
        const foundOption = formattedOptions[Number.parseInt(index, 10)];

        return !!(foundOption && getOptionIcon(foundOption));
    }

    function getIconElement(option: string | SelectOptions): ReactElement | undefined {
        const optionIcon = getOptionIcon(option);

        if (!optionIcon) return undefined;

        if (iconTooltipText) {
            return (
                <Tooltip title={iconTooltipText} placement='top'>
                    <span className='ml-4'>{optionIcon}</span>
                </Tooltip>
            );
        }

        return <span className='ml-4'>{optionIcon}</span>;
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                next: () => {
                    handleOnNext();
                },
                previous: () => {
                    handleOnPrev();
                },
            } as ISelect),
    );

    return (
        <MUIFormControl className='flex-row gap-0.5 rounded-15 bg-white shadow'>
            <MUIInputLabel
                id={`select-label-${label}`}
                className='text-lg'
                classes={{ shrink: 'translate-x-10 translate-y-4 scale-90 !text-companyMediumGrey' }}
            >
                {label}
            </MUIInputLabel>
            <Tooltip title={showTooltip ? getOptionLabel(options[selectedIndex]) : undefined} placement='top'>
                <MUISelect
                    labelId={`select-label-${label}`}
                    id={`select-${label}`}
                    variant='filled'
                    disabled={options.length === 0}
                    value={selectedOption === '-1' ? '' : selectedOption}
                    className={`min-w-120 overflow-hidden ${
                        !showArrows ? 'rounded-15' : 'rounded-l-15'
                    } bg-white before:content-none after:content-none ${className}`}
                    inputProps={{
                        className: `pt-24 pb-7 pl-10 pr-44 text-lg [&>span]:hidden ${
                            getOptionHasIcon(selectedOption || '') ? 'text-company' : 'text-companyDarkGrey'
                        }`,
                    }}
                    IconComponent={ExpandMoreOutlined}
                    MenuProps={{
                        PopoverClasses: {
                            paper: 'mt-10 shadow-menu rounded-15 pt-30 pl-40 pb-20 !pr-20',
                        },
                        MenuListProps: {
                            className:
                                'max-h-150 pr-30 overflow-y-scroll scrollbar-thin scrollbar-thumb-companyGrey scrollbar-track-companyLightGrey scrollbar-thumb-rounded-10 scrollbar-track-rounded-10',
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                    }}
                    onChange={(e) => handleChange(e)}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={getOptionLabel(option)}
                            value={index}
                            className='flex flex-row items-center !bg-white pl-0 pr-0 text-base text-companyDarkGrey hover:text-companyMediumGrey '
                            classes={{ selected: 'text-company hover:text-company' }}
                            disableRipple
                        >
                            {getOptionLabel(option)}
                            {getIconElement(option)}
                        </MenuItem>
                    ))}
                </MUISelect>
            </Tooltip>

            {showArrows && (
                <div className='flex flex-row items-center rounded-r-15 border-l-1 border-companyGrey bg-white pl-5 pr-5'>
                    <IconButton
                        className='max-h-34 max-w-34'
                        onClick={() => handleOnPrev()}
                        disabled={(selectedIndex === 0 && nextArrowDisabled === undefined) || prevArrowDisabled}
                    >
                        <ChevronLeftRounded />
                    </IconButton>
                    <IconButton
                        className='max-h-34 max-w-34'
                        onClick={() => handleOnNext()}
                        disabled={
                            (selectedIndex === options.length - 1 && nextArrowDisabled === undefined) ||
                            nextArrowDisabled
                        }
                    >
                        <ChevronRightRounded />
                    </IconButton>
                </div>
            )}
        </MUIFormControl>
    );
});

Select.defaultProps = {
    selectedOption: '',
    className: '',
    showArrows: false,
    nextArrowDisabled: undefined,
    prevArrowDisabled: undefined,
    onNext: undefined,
    onPrevious: undefined,
    showTooltip: false,
    iconTooltipText: '',
};

export default Select;
