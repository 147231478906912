import React, { ReactElement } from 'react';
import PageTitle from '../components/header/page-title';
import MultiTemplateLanguageSelector from '../widgets/template/multi-template-language-selector';
import useLocale from '../hooks/use-locale';
import GeneratorMatrix from '../widgets/matrix/generator-matrix-panel';
import GeneratorGenerate from '../widgets/generator/generator-generate';
import GeneratorCampaignTypeSelector from '../widgets/generator/generator-campaign-type-selector';
import useEditorParams from '../hooks/use-editor-params';
import { useStoreValue } from '../hooks/use-store-value';
import EditorSelectors from '../stores/selectors/editor';
import { OutputChannel } from '../types/enums/template';
import GeneratorSelector from '../stores/selectors/generator';
import GeneratorTiffToggle from '../widgets/generator/generator-tiff-toggle';

export default function Generator(): ReactElement {
    const { getText } = useLocale();

    const campaignName = useStoreValue<string>(EditorSelectors.getActiveCampaignName());
    const selectedOutputChannel = useStoreValue<OutputChannel>(GeneratorSelector.getSelectedOutputChannel);

    // #382 Hide Tiff Toggle (for now)
    const showTiffToggleDebug = false;

    useEditorParams();

    return (
        <>
            <div className='mb-20 flex w-full items-center'>
                <PageTitle title={`${getText('widgets.generator.header.title')} ${campaignName}`} />
                <GeneratorCampaignTypeSelector />
            </div>
            <div className='flex flex-col items-start gap-12'>
                <div className='flex h-full gap-12'>
                    <MultiTemplateLanguageSelector />
                    {selectedOutputChannel === OutputChannel.Print && showTiffToggleDebug && <GeneratorTiffToggle />}
                </div>
                <GeneratorMatrix />
            </div>
            <GeneratorGenerate />
        </>
    );
}
