import React, { ReactElement } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { ReplayOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import InstanceSelector from '../../stores/selectors/instance';
import { useServices } from '../../hooks/use-services';
import { History } from '../../types/history';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';

export default function ToolbarUndoRedo(): ReactElement {
    const changeHistory = useSelector(InstanceSelector.getInstanceHistory()) as History;

    const { history } = useServices();

    function undo(): void {
        if (changeHistory.past.length > 0) {
            history.undo();
        }
    }

    function redo(): void {
        if (changeHistory.future.length > 0) {
            history.redo();
        }
    }

    useHotkeys(ShortKeys.UNDO, () => undo(), { preventDefault: true, enableOnFormTags: true });
    useHotkeys(ShortKeys.REDO, () => redo(), { preventDefault: true, enableOnFormTags: true });

    return (
        <div className='flex flex-row gap-2 overflow-hidden rounded-15 shadow'>
            <IconButton
                className='rounded-none bg-white p-15'
                onClick={() => undo()}
                disabled={changeHistory.past.length === 0}
            >
                <ReplayOutlined className='fill-companyMediumGrey' />
            </IconButton>
            <IconButton
                className='rounded-none bg-white p-15 '
                onClick={() => redo()}
                disabled={changeHistory.future.length === 0}
            >
                <ReplayOutlined className='scale-x-[-1] fill-companyMediumGrey' />
            </IconButton>
        </div>
    );
}
