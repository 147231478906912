import React, { ReactElement, useEffect, useState } from 'react';
import Accordion from '../../components/accordions/accordion';
import AccordionDetails from '../../components/accordions/accordion-details';
import { useStoreValue } from '../../hooks/use-store-value';
import TextProperty from './text-property';
import TemplateSelector from '../../stores/selectors/template';
import CanvasSelectors from '../../stores/selectors/canvas';
import useLocale from '../../hooks/use-locale';
import {
    executeRecursive,
    getFirstAppearanceTime,
    getRenderObjectTree,
    isTemplateText,
    Template,
    TemplateFormat,
    TemplateInstance,
    TemplateObject,
} from '@metaphore/magnolia-rendering';
import EditorSelectors from '../../stores/selectors/editor';

type AppearanceTime = {
    id: string;
    time: number;
};

export default function TextInspector(): ReactElement {
    const textProps = useStoreValue<TemplateObject[]>(TemplateSelector.getTextProperties());
    const selectObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const baseTemplate = useStoreValue<Template | undefined>(EditorSelectors.getActiveTemplate());
    const templateInstance = useStoreValue<TemplateInstance>(EditorSelectors.getTemplateInstance());
    const templateFormat = useStoreValue<TemplateFormat>(EditorSelectors.getCurrentFormat());

    const [firstAppearanceTime, setFirstAppearanceTime] = useState<AppearanceTime[]>([]);

    const { getText } = useLocale();

    const expanded = textProps?.some((item) => item.id === selectObjectId) || false;

    function onExpand(expand: boolean): void {
        setIsExpanded(expand);
    }

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded, selectObjectId]);

    useEffect(() => {
        if (!baseTemplate) {
            return;
        }

        const tree = getRenderObjectTree(baseTemplate, templateInstance, templateFormat?.id ?? undefined, undefined);
        const out: AppearanceTime[] = [];

        executeRecursive(tree.roots, (node) => {
            if (isTemplateText(node.templateObject.content)) {
                out.push({
                    id: node.templateObject.id || '',
                    time: getFirstAppearanceTime(node) || 0,
                });
            }
        });

        setFirstAppearanceTime(out);
    }, [baseTemplate, templateInstance, templateFormat]);

    function getSortedTextProps(): TemplateObject[] {
        if (!textProps) {
            return [];
        }

        // sort text props by first appearance time
        return textProps.sort((a, b) => {
            const aTime = firstAppearanceTime.find((item) => item.id === a.id)?.time || 0;
            const bTime = firstAppearanceTime.find((item) => item.id === b.id)?.time || 0;

            return aTime - bTime;
        });
    }

    return (
        <Accordion label={getText('widgets.textInspector.label')} expanded={isExpanded} onExpand={(e) => onExpand(e)}>
            <AccordionDetails>
                <div className='flex flex-col space-y-20'>
                    {textProps &&
                        getSortedTextProps().map(
                            (v) => v.id && <TextProperty key={v.id} id={v.id} focused={v.id === selectObjectId} />,
                        )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
