import { Campaign, CampaignItem, Template, TemplateFormat, TemplateObject } from '../../generated/gql/graphql';
import { createSlice } from '@reduxjs/toolkit';
import { ReduxAction } from '../../types/redux-action';
import { CustomTemplateInstance } from '../../types/template';

export type EditorState = {
    campaign?: Campaign;

    // Selected CampaignItem
    campaignItem?: CampaignItem;
    campaignItems?: CampaignItem[];

    // Base definition of the scenario (template).
    baseTemplate?: Template;

    // InstanceModifier contain all modifications to the base template.
    instanceTemplate?: CustomTemplateInstance;

    templateObjectChange?: TemplateObject;

    selectedFormat?: TemplateFormat;

    isFormatSpecificActive?: boolean;
};

const initialState: EditorState = {};

const slice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        setCampaign: (state, action: ReduxAction<{ campaign: Campaign }>) => ({
            ...state,
            campaign: action.payload.campaign,
        }),
        setBaseTemplate: (state, action: ReduxAction<{ template: Template }>) => ({
            ...state,
            baseTemplate: action.payload.template,
        }),
        setInstanceTemplate: (state, action: ReduxAction<{ instance: CustomTemplateInstance }>) => ({
            ...state,
            instanceTemplate: action.payload.instance,
        }),
        setTemplateObjectChange: (
            state,
            action: ReduxAction<{ templateObjectChange: TemplateObject | undefined }>,
        ) => ({
            ...state,
            templateObjectChange: action.payload.templateObjectChange,
        }),
        setSelectedFormat: (state, action: ReduxAction<{ format: TemplateFormat }>) => ({
            ...state,
            selectedFormat: action.payload.format,
        }),
        setSelectedCampaignItem: (state, action: ReduxAction<{ item: CampaignItem }>) => ({
            ...state,
            campaignItem: action.payload.item,
        }),
        setCampaignItems: (state, action: ReduxAction<{ items: CampaignItem[] }>) => ({
            ...state,
            campaignItems: action.payload.items,
        }),
        setIsFormatSpecificActive: (state, action: ReduxAction<{ isFormatSpecificActive: boolean }>) => ({
            ...state,
            isFormatSpecificActive: action.payload.isFormatSpecificActive,
        }),
        reset: () => ({
            campaign: undefined,
            campaignItem: undefined,
            campaignItems: undefined,
            baseTemplate: undefined,
            instanceTemplate: undefined,
            templateObjectChange: undefined,
            selectedFormat: undefined,
            isFormatSpecificActive: false,
        }),
    },
});

export const EditorActions = {
    setCampaign: slice.actions.setCampaign,
    setBaseTemplate: slice.actions.setBaseTemplate,
    setInstanceTemplate: slice.actions.setInstanceTemplate,
    setTemplateObjectChange: slice.actions.setTemplateObjectChange,
    setSelectedFormat: slice.actions.setSelectedFormat,
    setSelectedCampaignItem: slice.actions.setSelectedCampaignItem,
    setCampaignItems: slice.actions.setCampaignItems,
    setIsFormatSpecificActive: slice.actions.setIsFormatSpecificActive,
    reset: slice.actions.reset,
};

export default slice.reducer;
