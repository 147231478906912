import { useState } from 'react';
import { AssetGroup } from '../generated/gql/graphql';
import { useServices } from './use-services';
import { AssetUploadStatus } from '../types/enums/asset-upload-status';

interface IAssetUpload {
    status: AssetUploadStatus;
    uploadAsset: (file: File, assetGroup: AssetGroup) => Promise<void>;
}

export default function useAssetUpload(): IAssetUpload {
    const [status, setStatus] = useState<AssetUploadStatus>(AssetUploadStatus.NONE);

    const { asset } = useServices();

    async function uploadAsset(file: File, assetGroup: AssetGroup): Promise<void> {
        try {
            setStatus(AssetUploadStatus.LOADING);
            await asset.uploadAsset([file], assetGroup);
            setStatus(AssetUploadStatus.SUCCESS);
        } catch {
            setStatus(AssetUploadStatus.ERROR);
        }
    }

    return {
        status,
        uploadAsset,
    };
}
