import React, { ReactElement } from 'react';
import CampaignCards from '../widgets/campaign/campaign-cards';
import CampaignCardListHeader from '../widgets/campaign/campaign-card-list-header';

export default function CampaignsPage(): ReactElement {
    return (
        <>
            <CampaignCardListHeader />
            <CampaignCards />
        </>
    );
}
