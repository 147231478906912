import React, { ReactElement, ReactNode } from 'react';
import { ListItemButton as MUIListItemButton } from '@mui/material';

type Props = {
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
    children?: ReactNode;
};

export default function ListItemButton(props: Props): ReactElement {
    const { disabled, onClick, className, children } = props;
    return (
        <MUIListItemButton onClick={onClick} className={className} disabled={disabled}>
            {children}
        </MUIListItemButton>
    );
}

ListItemButton.defaultProps = {
    disabled: false,
    onClick: undefined,
    className: '',
    children: undefined,
};
