import React, { ReactElement, useCallback, useRef, useState } from 'react';
import Modal from '../../components/modal/modal';
import { FileRejection, useDropzone } from 'react-dropzone';
import CloudUpload from '../../assets/icons/cloud-upload.svg';
import ActionButton from '../../components/buttons/action-button';
import useLocale from '../../hooks/use-locale';
import AssetPreviewTile, { IAssetPreviewTile } from './asset-preview-tile';
import useNotification from '../../hooks/use-notification';
import { AssetGroup, UploadedAsset } from '../../types/assets';
import { hasErrorsWithCode } from '../../utils/asset-upload';
import { AssetUploadStatus } from '../../types/enums/asset-upload-status';
import Class from '../../utils/classes';

type Props = {
    headline: string;
    open: boolean;
    assetGroup: AssetGroup;
    onClose(): void;
};

const maxFileCount = 20;

export default function AssetUpload(props: Props): ReactElement {
    const { headline, assetGroup, open, onClose } = props;

    const [files, setFiles] = useState<File[]>([]);
    const [uploadStatus, setUploadStatus] = useState<AssetUploadStatus>(AssetUploadStatus.NONE);

    const assetRefs = useRef<IAssetPreviewTile[]>([]);
    const uploadedAssets = useRef<UploadedAsset[]>([]);

    const { getText } = useLocale();
    const notification = useNotification();

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0 && hasErrorsWithCode(fileRejections)) {
                notification.pushError(
                    getText('widgets.assetUpload.notifications.error.type.body'),
                    getText('widgets.assetUpload.notifications.error.type.title'),
                );
                return;
            }

            if (fileRejections.length > maxFileCount || [...files, ...acceptedFiles].length > maxFileCount) {
                notification.pushError(
                    getText('widgets.assetUpload.notifications.error.count.body', { count: maxFileCount }),
                    getText('widgets.assetUpload.notifications.error.count.title'),
                );
                return;
            }
            setFiles([...files, ...acceptedFiles]);
        },
        [files, notification],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/gif': ['.gif'],
            'image/svg+xml': ['.svg'],
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
        },
        disabled: uploadStatus === AssetUploadStatus.LOADING || uploadStatus === AssetUploadStatus.SUCCESS,
        maxFiles: maxFileCount,
    });

    function handleClose(): void {
        uploadedAssets.current = [];
        assetRefs.current = [];
        setFiles([]);
        setUploadStatus(AssetUploadStatus.NONE);
        onClose();
    }

    function handleOnFileUpload(file: File, error?: string): void {
        const mergedUploadedAssets = [{ file, error } as UploadedAsset, ...uploadedAssets.current];
        uploadedAssets.current = mergedUploadedAssets;

        if (mergedUploadedAssets.length === files.length && mergedUploadedAssets.some((a) => a.error)) {
            notification.pushError(
                getText('widgets.assetUpload.notifications.error.partially.body'),
                getText('widgets.assetUpload.notifications.error.partially.title'),
            );
            setUploadStatus(AssetUploadStatus.SUCCESS);
        } else if (mergedUploadedAssets.length === files.length) {
            notification.pushSuccess(
                getText('widgets.assetUpload.notifications.success.title'),
                getText('widgets.assetUpload.notifications.success.body'),
            );
            setUploadStatus(AssetUploadStatus.SUCCESS);
        }
    }

    async function onUploadFiles(action: string): Promise<void> {
        if (action !== 'upload') {
            handleClose();
            return;
        }

        setUploadStatus(AssetUploadStatus.LOADING);
        assetRefs.current.forEach((ref) => {
            ref.upload(assetGroup);
        });
    }

    return (
        <Modal
            onClose={() => handleClose()}
            open={open}
            title={headline}
            onSubmit={(v) => {
                if (uploadStatus === AssetUploadStatus.SUCCESS) {
                    handleClose();
                    return;
                }
                onUploadFiles(v);
            }}
            disableSubmitActionIds={[
                ...(files.length === 0 || uploadStatus === AssetUploadStatus.LOADING ? ['upload'] : []),
            ]}
            showCancelButton={uploadStatus !== AssetUploadStatus.SUCCESS && uploadStatus !== AssetUploadStatus.LOADING}
            actions={[
                {
                    id: 'upload',
                    label:
                        uploadStatus !== AssetUploadStatus.SUCCESS
                            ? getText('widgets.assetUpload.upload')
                            : getText('widgets.assetUpload.done'),
                },
            ]}
        >
            <div
                {...getRootProps({ className: 'dropzone' })}
                className={Class.classNames(
                    'flex h-full min-h-550 flex-col items-center rounded-15 bg-companyLightGrey ',
                    files.length > 0 ? 'max-h-550 justify-start overflow-y-scroll p-50 ' : 'justify-center pb-96 pt-96',
                )}
            >
                {files.length > 0 ? (
                    <div className='grid grid-cols-5 gap-10'>
                        {files.map((f, index) => (
                            <AssetPreviewTile
                                ref={(el) => {
                                    if (el) {
                                        assetRefs.current[index] = el;
                                    }
                                }}
                                key={`${f.name}_${index.toString()}`}
                                file={f}
                                onUpload={(e) => handleOnFileUpload(f, e)}
                            />
                        ))}
                    </div>
                ) : (
                    <>
                        <img src={CloudUpload} alt='' width='220px' height='220px' />
                        <input {...getInputProps()} />
                        <p className='text-center text-xl text-companyMediumGrey'>
                            {getText('widgets.assetUpload.drag')}
                            <br />
                            {getText('widgets.assetUpload.or')}
                        </p>
                        <ActionButton
                            label={getText('widgets.assetUpload.browse')}
                            onClick={() => {}}
                            className='mt-24 w-auto'
                        />
                    </>
                )}
            </div>
        </Modal>
    );
}
