import { getTemplateInstances } from './queries/template/get-template-instances';
import { getTemplateById } from './queries/template/get-template-by-id';
import { getTemplateFormats } from './queries/template/get-template-format';
import { createRenderJob } from './mutations/rendering-job/create-render-job';
// eslint-disable-next-line max-len
import { subscribeRenderingJobStatusChanged } from './subscriptions/rendering-job/subscribe-rendering-job-status-changed';
import { subscribeRenderingStatusChanged } from './subscriptions/instance/subscribe-rendering-status-changed';
import { updateInstanceChangeObject } from './mutations/instance/update-instance-change-object';
import { getTemplateInstanceById } from './queries/instance/get-instance-by-id';
import { updateBannerSettings } from './mutations/instance/update-banner-settings';
import { renderTemplateInstance } from './mutations/instance/render-template-instance';
import { getLanguages } from './queries/language/get-all-languages';
import { getAllTemplates } from './queries/template/get-all-template';
import { getTemplateMotionLength } from './queries/template/get-template-motion-length';
import { uploadAsset } from './mutations/asset/upload-asset';
import { getCampaignItemsById } from './queries/campaign/get-campaign-items-by-id';
import { getClientLogo } from './queries/me/get-client-logo';
import { getAll } from './queries/campaign/get-all';
import { getById } from './queries/campaign/get-by-id';
import { getCampaignDetailsById } from './queries/campaign/get-campaign-details-by-id';
import { updateCampaignItems } from './mutations/campaign/update-campaign-items';
import { deleteCampaign } from './mutations/campaign/delete-campaign';
import { updateCampaign } from './mutations/campaign/update-campaign';
import { createCampaign } from './mutations/campaign/create-campaign';
import { deleteAsset } from './mutations/asset/delete-asset';
import { duplicateCampaign } from './mutations/campaign/duplicate-campaign';
import { duplicateCampaignItem } from './mutations/campaign/duplicate-campaign-item';
import { getCampaignItemInstancesById } from './queries/campaign/get-campaign-item-instances-by-id';
import { getClientName } from './queries/me/get-client-name';
import { createClientFormat } from './mutations/client-format.ts/create-client-format';

/*
 * Queries are a collection of functions that return a GraphQL query.
 * The queries are used to fetch data from the server.
 */

export const Queries = {
    campaign: {
        getAll,
        getById,
        getCampaignDetailsById,
        getCampaignItemsById,
        getCampaignItemInstancesById,
    },
    me: {
        getClientLogo,
        getClientName,
    },
    template: {
        getTemplateInstances,
        getTemplateFormats,
        getTemplateById,
        getAllTemplates,
        getTemplateMotionLength,
    },
    instance: {
        getTemplateInstanceById,
    },
    language: {
        getLanguages,
    },
};

/*
 * Mutations are a collection of functions that return a GraphQL mutation.
 * The mutations are used to create, modify data on the server.
 */
export const Mutations = {
    asset: {
        uploadAsset,
        deleteAsset,
    },
    campaign: {
        create: createCampaign,
        delete: deleteCampaign,
        update: updateCampaign,
        duplicate: duplicateCampaign,
        items: {
            updateItems: updateCampaignItems,
            duplicateItem: duplicateCampaignItem,
        },
    },
    instance: {
        updateInstanceChangeObject,
        updateBannerSettings,
        renderTemplateInstance,
    },
    job: {
        createRenderJob,
    },
    template: {
        createClientFormat,
    },
};

/*
 * Subscriptions are a collection of functions that return a GraphQL subscription.
 */
export const Subscriptions = {
    instance: {
        renderStatus: subscribeRenderingStatusChanged,
    },
    job: {
        jobStatus: subscribeRenderingJobStatusChanged,
    },
};
