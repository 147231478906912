import React, { ReactElement, useEffect, useRef, useState } from 'react';
import useLocale from '../../../../hooks/use-locale';
import Class from '../../../../utils/classes';
import { Check } from '@mui/icons-material';
import short from 'short-uuid';
import { ReactComponent as SearchAndReplaceIcon } from '../../../../assets/icons/search-and-replace-icon.svg';

type Props = {
    name: string;
    value: string;
    searchValue: string;
    disabled?: boolean;
    replacedValue?: string;
    onReplace(value: string): void;
};

export default function TextReplaceField(props: Props): ReactElement {
    const { name, value, replacedValue, disabled, searchValue, onReplace } = props;

    const { getText } = useLocale();

    const [currentValue] = useState<string>(value);

    const ref = useRef<HTMLTextAreaElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 'auto';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [currentValue]);

    function getHighlightedText(): ReactElement {
        if (!searchValue.trim()) {
            return <span>{value}</span>;
        }

        const parts = value.split(new RegExp(`(${searchValue})`, 'gi'));
        return (
            <span>
                {parts.map((part) =>
                    part.toLowerCase() === searchValue.toLowerCase() ? (
                        <mark key={short().generate()} className='bg-yellow-200'>
                            {part}
                        </mark>
                    ) : (
                        part
                    ),
                )}
            </span>
        );
    }

    function getTextValue(): string {
        if (replacedValue) {
            return replacedValue;
        }
        return value || '';
    }

    return (
        <div className='flex flex-row items-center space-x-15'>
            <div className='relative w-full'>
                <span className='absolute left-0 top-0 origin-top-left -translate-y-9 translate-x-14 scale-75 bg-white px-8 text-black text-opacity-60'>
                    {name}
                </span>
                <textarea
                    ref={ref}
                    className='pointer-events-none box-border h-auto w-full resize-none overflow-hidden break-words rounded border-1 border-companyGrey px-[16.5px] py-14'
                    defaultValue={getTextValue()}
                />
                {!replacedValue && (
                    <div
                        ref={divRef}
                        className='pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-0 w-full whitespace-pre-wrap break-words border-1 border-transparent bg-transparent p-2 px-[16.5px] py-14 text-transparent'
                    >
                        {getHighlightedText()}
                    </div>
                )}
            </div>
            <button
                type='button'
                className={Class.classNames(
                    'flex min-w-[100px] flex-1 flex-row items-center space-x-20 whitespace-nowrap rounded-xl border-1 px-10 py-4 text-sm',
                    replacedValue ? 'border-companyMediumGrey text-companyMediumGrey' : 'border-company text-company',
                    disabled ? 'border-companyGrey text-companyGrey' : '',
                )}
                disabled={disabled}
                onClick={() => onReplace(value)}
            >
                {replacedValue ? (
                    <Check className='mr-6 h-12 w-12' />
                ) : (
                    <SearchAndReplaceIcon
                        className={Class.classNames(
                            'mr-6 h-12 w-12 ',
                            replacedValue ? 'text-companyMediumGrey' : 'text-company',
                            disabled ? 'text-companyGrey' : '',
                        )}
                    />
                )}
                {replacedValue
                    ? getText('widgets.canvas.searchAndReplace.text.replaced')
                    : getText('widgets.canvas.searchAndReplace.text.replace')}
            </button>
        </div>
    );
}

TextReplaceField.defaultProps = {
    replacedValue: '',
    disabled: false,
};
