export enum TemplateObjectType {
    Container = 'Container',
    Holder = 'Holder',
    Image = 'Image',
    ImageSequence = 'ImageSequence',
    Mask = 'Mask',
    Rectangle = 'Rectangle',
    Text = 'Text',
    Video = 'Video',
}

export enum TemplateType {
    Still = 'still',
    Motion = 'motion',
}

export enum OutputChannel {
    Banner = 'Banner',
    Print = 'Print',
    Still = 'Still',
    Video = 'Video',
}
