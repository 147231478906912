/* eslint-disable react-hooks/exhaustive-deps */
import { Slider } from '@mui/material';
import React, { ReactElement } from 'react';

type Direction = 'top' | 'right' | 'bottom' | 'left';

type Props = {
    sliderValue: number;
    label: string;
    onChange(value: number, direction: Direction): void;
    direction: Direction;
};

export default function CropSlider(props: Props): ReactElement {
    const { sliderValue, label, onChange, direction } = props;
    return (
        <div className='flex'>
            <p className='w-100 text-gray-500'>{label}</p>
            <Slider
                className='flex-1'
                value={sliderValue}
                valueLabelDisplay='on'
                valueLabelFormat={`${(sliderValue * 100).toFixed(0)}%`}
                min={0}
                max={1}
                step={0.01}
                size='small'
                onChange={(e, v) => onChange(v as number, direction)}
            />
        </div>
    );
}
