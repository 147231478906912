import React, { ReactElement, useRef, useState } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { MoreHorizOutlined } from '@mui/icons-material';
import ListItemButton from '../../components/list/list-item-button';
import ListItem from '../../components/list/list-item';
import { CampaignItemListItem } from '../../types/campaign';
import Popper from '../../components/popper/popper';
import PopperItem from '../../components/popper/popper-item';
import useLocale from '../../hooks/use-locale';
import { CampaignItem } from '../../generated/gql/graphql';
import StringFormatUtils from '../../utils/string-format';
import { ReactComponent as FormatOverrideIcon } from '../../assets/icons/format-override-icon.svg';
import CampaignItemIcon from '../../assets/icons/campaign_item.svg';
import Tooltip from '../../components/tooltip/tooltip';

type Props = {
    item: CampaignItem;
    listItem: CampaignItemListItem;
    onClick(item: CampaignItem): void;
    onPressDelete(item: CampaignItem): void;
    onPressRename(item: CampaignItem): void;
    onPressDuplicate(item: CampaignItem): void;
};

export default function CampaignTemplateItem(props: Props): ReactElement {
    const { item, listItem, onClick, onPressDelete, onPressRename, onPressDuplicate } = props;
    const [isOpen, setIsOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const { getText } = useLocale();

    return (
        <ListItem
            className='h-64 border-b-1 border-companyGrey p-0'
            secondaryAction={
                <IconButton ref={anchorRef} onClick={() => setIsOpen(true)}>
                    <MoreHorizOutlined />
                </IconButton>
            }
        >
            <ListItemButton className='listItems-center flex w-full flex-row gap-16' onClick={() => onClick(item)}>
                <div className='flex basis-2/6 items-center space-x-10'>
                    {listItem.thumbnailUrl ? (
                        <img
                            className='h-50 w-50 object-contain'
                            src={listItem.thumbnailUrl}
                            alt={listItem.templateName}
                        />
                    ) : (
                        <img
                            className='h-50 w-50 object-cover'
                            src={CampaignItemIcon}
                            alt='Campaign item fallback icon'
                        />
                    )}
                    <p>{listItem.name}</p>
                    {listItem.formatSpecificCount > 0 && (
                        <Tooltip title={getText('widgets.campaigns.tooltipFormatOverride')} placement='top'>
                            <span className='ml-4 flex flex-row items-center space-x-4 text-company'>
                                <FormatOverrideIcon className='h-20 w-20' />
                                <p>{listItem.formatSpecificCount}</p>
                            </span>
                        </Tooltip>
                    )}
                </div>
                <div className='basis-2/6 text-companyDarkGrey'>{listItem.templateName}</div>
                <div className='basis-2/6 text-center'>
                    {listItem.updatedAt ? StringFormatUtils.formatTimestamp(listItem.updatedAt) : '-'}
                </div>
                <div className='basis-2/6 text-center'>
                    {listItem.createdAt ? StringFormatUtils.formatTimestamp(listItem.createdAt) : '-'}
                </div>
                <div className='w-60' />
            </ListItemButton>
            <Popper open={isOpen} onOpen={(b) => setIsOpen(b)} anchorEl={anchorRef.current}>
                <PopperItem label={getText('widgets.campaigns.context.rename')} onClick={() => onPressRename(item)} />
                <PopperItem label={getText('widgets.campaigns.context.delete')} onClick={() => onPressDelete(item)} />
                <PopperItem
                    label={getText('widgets.campaigns.context.duplicate')}
                    onClick={() => onPressDuplicate(item)}
                />
            </Popper>
        </ListItem>
    );
}
