import { Campaign, CampaignItem, CampaignItemInput } from '../generated/gql/graphql';
import { ILoggerService } from './logger';
import { GraphQlClient } from '../providers/graphql-provider';
import { Mutations, Queries } from '../repositories';
import { OutputChannel } from '../types/enums/template';
import { CampaignListType } from '../types/campaign';
import { store } from '../stores';

export interface ICampaignService {
    getAll(): Promise<CampaignListType>;

    getById(campaignId: string): Promise<Campaign>;

    getCampaignDetailsById(campaignId: string): Promise<Campaign>;

    getCampaignItemsById(campaignId: string, outputChannels?: OutputChannel[]): Promise<CampaignItem[]>;

    renameCampaign(campaignId: string, name: string): Promise<void>;

    deleteCampaign(campaignId: string): Promise<void>;

    createCampaign(name: string, languageIds: string[]): Promise<Campaign>;

    updateCampaignItems(campaignId: string, item: CampaignItemInput[]): Promise<void>;

    duplicateCampaign(campaignId: string, name: string): Promise<Campaign>;

    duplicateCampaignItem(campaignItemId: string, name: string): Promise<CampaignItem>;

    getCampaignItemTemplateInstancesById(campaignItemId: string): Promise<CampaignItem | undefined>;
}

export const CampaignService = (logger: ILoggerService): ICampaignService => {
    async function getAll(): Promise<CampaignListType> {
        const result = await GraphQlClient.query({
            query: Queries.campaign.getAll,
            fetchPolicy: 'no-cache',
        });

        if (result.error) {
            logger.info('no campaigns found');
            throw result.error;
        }

        return result.data.campaigns.map((campaign: Campaign) => ({
            id: campaign.id,
            name: campaign.name,
            items: campaign.items,
            languages: campaign.languages,
            createdAt: campaign.createdAt,
            updatedAt: campaign.updatedAt,
        }));
    }

    async function getById(campaignId: string): Promise<Campaign> {
        const result = await GraphQlClient.query({
            query: Queries.campaign.getById,
            fetchPolicy: 'no-cache',
            variables: {
                campaignId,
            },
        });

        if (result.error) {
            throw result.error;
        }

        return result.data.campaign;
    }

    async function getCampaignDetailsById(campaignId: string): Promise<Campaign> {
        const result = await GraphQlClient.query({
            query: Queries.campaign.getCampaignDetailsById,
            fetchPolicy: 'no-cache',
            variables: { campaignId },
        });

        if (result.error) {
            throw result.error;
        }

        return result.data.campaign;
    }

    async function getCampaignItemsById(campaignId: string, outputChannels?: OutputChannel[]): Promise<CampaignItem[]> {
        if (!campaignId) {
            return [];
        }

        const result = await GraphQlClient.query({
            query: Queries.campaign.getCampaignItemsById,
            fetchPolicy: 'no-cache',
            variables: {
                query: {
                    campaignId,
                    filter: {
                        outputChannels: {
                            anyOf: outputChannels,
                        },
                    },
                },
            },
        });
        if (result.error) {
            logger.info('no campaign items found');
            throw result.error;
        }

        return result.data.campaignItems as CampaignItem[];
    }

    async function renameCampaign(campaignId: string, name: string): Promise<void> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.update,
            variables: {
                updateCampaignId: campaignId,
                input: {
                    name,
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }
    }

    async function deleteCampaign(campaignId: string): Promise<void> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.delete,
            variables: {
                deleteCampaignId: campaignId,
            },
        });

        if (result.errors) {
            throw result.errors;
        }
    }

    async function createCampaign(name: string, languageIds: string[]): Promise<Campaign> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.create,
            variables: {
                input: {
                    name,
                    languageIds,
                    items: [],
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }

        return result.data.createCampaign;
    }

    async function updateCampaignItems(campaignId: string, items: CampaignItemInput[]): Promise<void> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.items.updateItems,
            variables: {
                updateCampaignId: campaignId,
                input: {
                    items,
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }
    }

    async function duplicateCampaign(campaignId: string, name: string): Promise<Campaign> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.duplicate,
            variables: {
                input: {
                    campaignId,
                    name,
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }

        return result.data.duplicateCampaign;
    }

    async function duplicateCampaignItem(campaignItemId: string, name: string): Promise<CampaignItem> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.campaign.items.duplicateItem,
            variables: {
                input: {
                    campaignItemId,
                    name,
                },
            },
        });
        if (result.errors) {
            throw result.errors;
        }
        return result.data.duplicateCampaignItem;
    }

    async function getCampaignItemTemplateInstancesById(campaignItemId: string): Promise<CampaignItem | undefined> {
        const { campaign } = store.getState().editor;

        if (!campaign || !campaign.id || !campaign.items) {
            return undefined;
        }

        const result = await GraphQlClient.query({
            query: Queries.campaign.getCampaignItemInstancesById,
            fetchPolicy: 'no-cache',
            variables: { campaignId: campaign.id },
        });

        if (result.error) {
            throw result.error;
        }

        return result.data.campaign.items.find((c: CampaignItem) => c.id === campaignItemId) || undefined;
    }

    return {
        getAll,
        getById,
        getCampaignDetailsById,
        renameCampaign,
        deleteCampaign,
        createCampaign,
        getCampaignItemsById,
        updateCampaignItems,
        duplicateCampaign,
        duplicateCampaignItem,
        getCampaignItemTemplateInstancesById,
    };
};
