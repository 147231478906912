import React, { ReactElement } from 'react';
import useLocale from '../../hooks/use-locale';
import IconButton from '../../components/buttons/icon-button';
import { FastRewindOutlined } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';

export default function CanvasRewindToggle(): ReactElement {
    const { getText } = useLocale();

    const { canvas } = useServices();

    useHotkeys(ShortKeys.VIDEO_REWIND, () => canvas.rewind(), { preventDefault: true });

    return (
        <IconButton tooltip={getText('widgets.canvas.controls.backward.tooltip')} onClick={() => canvas.rewind()}>
            <FastRewindOutlined />
        </IconButton>
    );
}
