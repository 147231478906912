import React, { ReactElement } from 'react';
import Slider from '../../components/slider/slider';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import { Template } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import { useServices } from '../../hooks/use-services';

export default function VideoProgressBar(): ReactElement {
    const playtime = useStoreValue<number>(CanvasSelectors.playtime());
    const activeTemplate = useStoreValue<Template>(EditorSelectors.getActiveTemplate());

    const length = activeTemplate.motion?.length || 0;

    const { canvas } = useServices();

    function onSeek(value: number): void {
        canvas.setPlaytime(value);
    }

    return (
        <div className='mx-32 flex'>
            <Slider
                value={playtime}
                max={length}
                disableTransition
                size='small'
                defaultValue={0}
                onChange={(v) => onSeek(v)}
            />
            <p className='ml-10 select-none whitespace-nowrap text-sm'>
                {Math.round(playtime)} / {Math.round(length)}
            </p>
        </div>
    );
}
