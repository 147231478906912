import EditorService from './editor';
import CanvasService from './canvas';
import EventBusService from './event-bus';
import LoggerService from './logger';
import TextInstanceService from './instance/text';
import InstanceService from './instance';
import CommonInstanceService from './instance/common';
import PackshotsInstanceService from './instance/packshots';
import RendererService from './renderer';
import GeneratorService from './generator';
import BackgroundInstanceService from './instance/background';
import BannerInstanceService from './instance/banner';
import AssetService from './asset';
import HistoryService from './history';
import RenderInstanceService from './instance/render';
import LanguageService from './language';
import TemplateService from './template';
import { CampaignService } from './campaign';
import { MeService } from './me';

const loggerInstance = LoggerService();
const eventBusInstance = EventBusService(loggerInstance);

const campaignInstance = CampaignService(loggerInstance);
const editorInstance = EditorService(campaignInstance);
const meServiceInstance = MeService(loggerInstance);

const templateInstance = TemplateService(editorInstance, loggerInstance, eventBusInstance);

const commonInstanceService = CommonInstanceService(loggerInstance, eventBusInstance);
const canvasInstanceService = CanvasService(loggerInstance, eventBusInstance, commonInstanceService);
const backgroundInstanceService = BackgroundInstanceService(commonInstanceService);
const packshotsInstanceService = PackshotsInstanceService(commonInstanceService);
const textServiceInstance = TextInstanceService(commonInstanceService);
const bannerServiceInstance = BannerInstanceService();
const renderInstanceServiceInstance = RenderInstanceService();

const instanceInstance = InstanceService(
    textServiceInstance,
    packshotsInstanceService,
    backgroundInstanceService,
    bannerServiceInstance,
    renderInstanceServiceInstance,
);
const assetServiceInstance = AssetService(loggerInstance, eventBusInstance);
const historyServiceInstance = HistoryService(commonInstanceService);

const renderInstance = RendererService();
const generatorInstance = GeneratorService();

const languageInstance = LanguageService();

export const ServiceInstances = {
    logger: loggerInstance,
    eventBus: eventBusInstance,
    canvas: canvasInstanceService,
    instance: instanceInstance,
    editor: editorInstance,
    template: templateInstance,
    renderer: renderInstance,
    generator: generatorInstance,
    asset: assetServiceInstance,
    history: historyServiceInstance,
    language: languageInstance,
    campaign: campaignInstance,
    me: meServiceInstance,
    common: commonInstanceService,
};

export type Services = typeof ServiceInstances;
