/* eslint-disable max-len */
import { Crop } from '@mui/icons-material';
import React, { ReactElement, ReactNode } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import { EditMode } from '../../types/enums/edit-mode';

type Props = {
    disabled: boolean;
    editMode: EditMode;
};

export default function ImageEditButton(props: Props): ReactElement {
    const { disabled, editMode } = props;
    const { canvas } = useServices();

    const currentEditMode = useStoreValue<EditMode>(CanvasSelectors.editMode());

    const currentlyActive = currentEditMode === editMode;

    const buttonStateStyles = currentlyActive ? '!rounded-b-none !shadow-imageeditbutton' : '';

    function renderIcon(mode: EditMode): ReactNode {
        switch (mode) {
            case EditMode.CROP_SCALE:
                return <Crop />;
            default:
                return <div />;
        }
    }

    return (
        <IconButton
            disabled={disabled}
            className={`p-17 relative z-40 h-56 w-56 rounded-xl bg-white shadow transition-shadow ${buttonStateStyles}`}
            onClick={() => {
                canvas.setEditMode(currentEditMode === editMode ? EditMode.NOT_EDITING : editMode);
            }}
        >
            {renderIcon(editMode)}
        </IconButton>
    );
}
