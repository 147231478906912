import React, { ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import { AccordionDetails as MUIAccordionDetails } from '@mui/material';

type Props = {
    className?: string;
    children?: ReactNode;
};

const AccordionDetails = forwardRef((props: Props, ref?: ForwardedRef<HTMLDivElement | undefined>): ReactElement => {
    const { className = undefined, children } = props;
    return (
        <MUIAccordionDetails
            className={`overflow-y-scroll border-t border-solid 
            border-companyGrey pb-20 pl-40 pr-20 pt-20 ${className}`}
        >
            <div
                ref={ref as ForwardedRef<HTMLDivElement>}
                className='max-h-420 overflow-y-scroll pb-10 pr-20 pt-10
                scrollbar-thin scrollbar-track-companyLightGrey scrollbar-thumb-companyGrey
                scrollbar-track-rounded-10 scrollbar-thumb-rounded-10'
            >
                {children}
            </div>
        </MUIAccordionDetails>
    );
});

export default AccordionDetails;

AccordionDetails.defaultProps = {
    className: '',
    children: undefined,
};
