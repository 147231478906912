import React, { ReactElement, useEffect, useState } from 'react';
import ActionButton from '../../components/buttons/action-button';
import useLocale from '../../hooks/use-locale';
import { useStoreValue } from '../../hooks/use-store-value';
import GeneratorSelector from '../../stores/selectors/generator';
import { useServices } from '../../hooks/use-services';
import Dialog from '../../components/dialog/dialog';
import confetti from 'canvas-confetti';
import { OutputChannel } from '../../types/enums/template';
import { GeneratorFormat } from '../../types/generator';
import { asyncInvoke } from '../../utils/function';
import { RenderingExportTarget } from '../../types/enums/rendering-export-targets';
import GeneratorPreviewButton from './generator-preview-button';
import useNotification from '../../hooks/use-notification';

type RenderData = {
    campaignId: string;
    campaignItemInstanceId: string;
    instanceId: string;
    formatIds: string[];
};

export default function GeneratorGenerate(): ReactElement {
    const selectedOutputChannel = useStoreValue<OutputChannel>(GeneratorSelector.getSelectedOutputChannel);

    const selectedLanguage = useStoreValue<string>(GeneratorSelector.getSelectedLocals);
    const generatorData = useStoreValue<GeneratorFormat[]>(GeneratorSelector.getGeneratorData);

    const [renderData, setRenderData] = useState<RenderData[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const disableTimeoutInMS = 5000;

    const { getText } = useLocale();

    const { renderer } = useServices();
    const notification = useNotification();

    async function syncRenderData(): Promise<RenderData[]> {
        const filtered = generatorData.filter((item) => item.checked && selectedLanguage.includes(item.language.id));

        const data: RenderData[] = filtered.map((item) => ({
            campaignId: item.campaignId,
            campaignItemInstanceId: item.campaignItemInstanceId,
            instanceId: item.campaignItemInstanceId,
            formatIds: [item.formatId],
        }));

        setRenderData(data);
        return data;
    }

    function getExportTarget(): RenderingExportTarget | undefined {
        switch (selectedOutputChannel) {
            case OutputChannel.Banner:
                return RenderingExportTarget.GoogleAd;
            case OutputChannel.Video:
                return RenderingExportTarget.Mp4;
            case OutputChannel.Still:
                return RenderingExportTarget.Png;
            case OutputChannel.Print:
                return RenderingExportTarget.Pdf;
            default:
                console.error('Unknown output channel');
                return undefined;
        }
    }

    function disableGenerateButton(): void {
        setIsGenerating(true);

        setTimeout(() => {
            setIsGenerating(false);
        }, disableTimeoutInMS);
    }

    async function onGeneratePreview(name: string): Promise<void> {
        const data = await syncRenderData();
        const exportTarget = getExportTarget();
        if (!exportTarget) {
            return;
        }
        try {
            await renderer.createJob(data, exportTarget, name, true);
            notification.pushSuccess(
                getText('widgets.generator.preview.notifications.success.body'),
                getText('widgets.generator.preview.notifications.success.title'),
            );
            disableGenerateButton();
        } catch (error) {
            console.error(error);
            notification.pushError(
                getText('widgets.generator.preview.notifications.error.body'),
                getText('widgets.generator.preview.notifications.error.title'),
            );
        }
        setIsOpen(false);
    }

    async function onGenerate(): Promise<void> {
        const data = await syncRenderData();

        const exportTarget = getExportTarget();
        if (!exportTarget) {
            return;
        }

        await renderer.createJob(data, exportTarget);
        disableGenerateButton();

        setIsOpen(false);
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
        });
    }

    useEffect(asyncInvoke(syncRenderData), [generatorData, selectedLanguage]);

    function getGenerationLabel(): ReactElement {
        let localeKey;
        switch (selectedOutputChannel) {
            case OutputChannel.Banner:
                localeKey = 'banner';
                break;
            case OutputChannel.Video:
                localeKey = 'video';
                break;
            case OutputChannel.Still:
                localeKey = 'still';
                break;
            case OutputChannel.Print:
                localeKey = 'print';
                break;
            default:
                localeKey = 'video';
                break;
        }

        return (
            <p className='text-base text-companyDarkGrey'>
                {getText(`widgets.generator.generate.${localeKey}.label`)}
                <span className='font-bold'>
                    {getText(`widgets.generator.generate.${localeKey}.count`, {
                        count: renderData.length,
                    })}
                </span>
                {getText('widgets.generator.generate.suffix')}
            </p>
        );
    }

    return (
        <div className='mt-40 flex flex-row items-center justify-end gap-24'>
            <div className='flex flex-col items-end'>{getGenerationLabel()}</div>
            <div className='flex flex-row'>
                {selectedOutputChannel !== OutputChannel.Print && (
                    <GeneratorPreviewButton
                        onGenerate={(name) => onGeneratePreview(name)}
                        disabled={renderData.length === 0 || isGenerating}
                    />
                )}
                <ActionButton
                    label={getText('widgets.generator.generate.label')}
                    variant='contained'
                    onClick={() => setIsOpen(true)}
                    disabled={renderData.length === 0 || isGenerating}
                />
            </div>
            <Dialog
                title={getText('components.dialogs.generate.title')}
                contentText={getText('components.dialogs.generate.content')}
                closeText={getText('components.dialogs.generate.close')}
                submitText={getText('components.dialogs.generate.submit')}
                onSubmit={() => onGenerate()}
                onClose={() => {
                    setIsOpen(false);
                }}
                open={isOpen}
            />
        </div>
    );
}
