import React, { ReactElement } from 'react';
import ToolbarInstanceSelect from './toolbar-instance-select';

export default function ToolbarTemplateOptions(): ReactElement {
    return (
        <div className='flex flex-row gap-2 overflow-hidden rounded-15 shadow'>
            <ToolbarInstanceSelect />
        </div>
    );
}
