import React, { ReactElement } from 'react';
import useLocale from '../../../hooks/use-locale';
import Modal from '../../../components/modal/modal';

type Props = {
    open: boolean;
    onClose(): void;
    onSubmit(): void;
};

export default function DeleteCampaignModal(props: Props): ReactElement {
    const { open, onClose, onSubmit } = props;

    const { getText } = useLocale();

    return (
        <Modal
            onClose={onClose}
            title={getText('widgets.dashboard.delete.title')}
            open={open}
            actions={[
                {
                    id: 'delete',
                    label: getText('widgets.dashboard.delete.action'),
                    variant: 'text',
                },
            ]}
            onSubmit={() => onSubmit()}
        >
            <div className='flex flex-col gap-20'>
                <p className='text-xl text-companyDarkGrey'>{getText('widgets.dashboard.delete.content')}</p>
            </div>
        </Modal>
    );
}
