import React, { ReactElement } from 'react';
import Panel from '../../components/panel/panel';
import PanelContent from '../../components/panel/panel-content';
import TemplateFormatMatrix from './template-format-matrix';
import NewGeneratorMatrixTable from './new-generator-matrix-table';
import ProviderAccordionProvider from '../../providers/provider-accordion-provider';

export default function GeneratorMatrixPanel(): ReactElement {
    // TODO Remove once new Generator Matrix is done and approved
    const oldMatrixDEBUG = false;

    return (
        <Panel className='grid max-h-660 auto-rows-auto grid-cols-1 grid-rows-[3fr]'>
            <PanelContent className='max-h-475 overflow-hidden'>
                <ProviderAccordionProvider>
                    {oldMatrixDEBUG ? <TemplateFormatMatrix /> : <NewGeneratorMatrixTable />}
                </ProviderAccordionProvider>
            </PanelContent>
            <div className='h-60 border-t-1  border-companyGrey ' />
        </Panel>
    );
}
