import { createContext } from 'react';

export interface AppConfig {
    backend: {
        apiUrl: string;
        subscriptionsUrl: string;
    };

    keycloak: {
        realm: string;
        url: string;
        clientId: string;
    };

    markerIo: {
        project: string;
    };
}

export type AppConfigContext =
    | {
          loading: true;
          config: null;
      }
    | {
          loading: false;
          config: AppConfig;
      };

// eslint-disable-next-line no-redeclare
export const AppConfigContext = createContext<AppConfigContext>({
    loading: true,
    config: null,
});
