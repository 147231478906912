/* eslint-disable prefer-destructuring */
import { Template, TemplateObject } from '../generated/gql/graphql';

function getAllObjectIds(template: Template): string[] {
    return template.objects.map((object) => object.id).filter((id) => id !== undefined) as string[];
}

function getObjectById(objectId: string, template: Template): TemplateObject | undefined {
    return template.objects.find((object) => object.id === objectId);
}

const TemplateUtils = {
    getAllObjectIds,
    getObjectById,
};

export default TemplateUtils;
