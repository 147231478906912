import { Asset } from '../generated/gql/graphql';

export type AssetQuery = {
    group: AssetGroup;
    search?: string;
};

export enum AssetGroup {
    Background = 'Background',
    Packshot = 'Packshot',
}

export type UploadedAsset = {
    file: File;
    error: string;
};

export type AssetData = {
    items: Asset[];
    limit: number;
    offset: number;
    page: number;
    totalCount: number;
};
