import React, { ReactElement, useEffect, useState } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { FileDownloadOutlined } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import { RenderingStatusChangedSubscription, TemplateFormat } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import { useSubscription } from '@apollo/client';
import { Subscriptions } from '../../repositories';
import { CustomTemplateInstance } from '../../types/template';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';
import useNotification from '../../hooks/use-notification';
import useLocale from '../../hooks/use-locale';

export default function DownloadInstance(): ReactElement {
    const { instance } = useServices();
    const templateInstance = useStoreValue<CustomTemplateInstance>(EditorSelectors.getTemplateInstance());
    const [renderInstanceId, setRenderInstanceId] = useState('');
    const selectedFormat = useStoreValue<TemplateFormat>(EditorSelectors.getCurrentFormat());

    const { pushSuccess } = useNotification();
    const { getText } = useLocale();

    const { data } = useSubscription<RenderingStatusChangedSubscription>(Subscriptions.instance.renderStatus, {
        variables: {
            renderingJobStatusChangedId: renderInstanceId,
        },
    });

    async function onDownload(): Promise<void> {
        const result = await instance.render.renderTemplateInstance(templateInstance.templateInstanceId, {
            formatId: selectedFormat.id,
        });

        if (result.id) {
            pushSuccess(
                getText('notifications.instance.download.success.body'),
                getText('notifications.instance.download.success.title'),
            );
            setRenderInstanceId(result.id);
        }
    }

    useHotkeys(ShortKeys.DIRECT_DOWNLOAD, () => onDownload(), { preventDefault: true });

    useEffect(() => {
        async function download(): Promise<void> {
            if (
                data &&
                data.renderingStatusChanged.id === renderInstanceId &&
                data.renderingStatusChanged.status === 'Finished' &&
                data.renderingStatusChanged.result?.__typename === 'RenderingResult'
            ) {
                const { downloadUrl } = data.renderingStatusChanged.result;
                const a = document.createElement('a');
                a.href = downloadUrl;
                document.body.append(a);
                a.click();
                a.remove();
            }
        }

        download();
    }, [data, renderInstanceId]);

    return (
        <IconButton onClick={() => onDownload()}>
            <FileDownloadOutlined />
        </IconButton>
    );
}
