import React, { ReactElement } from 'react';

import TextFontFamily from './text-font-family';
import TextFontSizeFactor from './text-font-size-factor';
import TextAlignment from './text-aligment';
import SpecialCharacters from './text-special-characters';

import { TemplateText } from '../../generated/gql/graphql';
import TextColorControl from './text-color-control';
import { Font, TextAlign } from '@metaphore/magnolia-rendering';
import Separator from '../../components/separator/separator';
import { FontFamilyChange } from '../../types/font';

type Props = {
    settings: TemplateText;
    fonts: Font[];
    setColor(path: string, value: string): void;
    setTextAlign(value: TextAlign): void;
    setFontFamily(value: FontFamilyChange): void;
    setFontSizeFactor(value: number): void;
    setTextCharacter(value: string): void;
};

export default function TextToolbar(props: Props): ReactElement {
    const { settings, fonts, setFontFamily, setFontSizeFactor, setTextCharacter, setTextAlign, setColor } = props;

    const dividerStyles = {
        borderWidth: 1,
        marginTop: '10px',
        marginBottom: '10px',
    };

    return (
        <div className='absolute top-[18px] z-10 flex w-full flex-row items-center gap-10 px-15'>
            <TextFontFamily
                onSetFontFamily={(v) => setFontFamily(v)}
                fontSettings={{
                    fontFamily: settings.textSettings?.fontFamily || '',
                    fontWeight: settings.textSettings?.fontWeight || undefined,
                    fontStyle: settings.textSettings?.fontStyle || undefined,
                }}
                fonts={fonts}
            />
            <TextFontSizeFactor
                onSetFontSizeFactor={(v) => setFontSizeFactor(v)}
                fontSizeFactor={settings.textSettings?.fontSizeFactor || 1}
            />
            <Separator sx={dividerStyles} orientation='vertical' variant='middle' />
            <TextColorControl
                onSetColor={setColor}
                color={settings.color || ''}
                icon='format_color_text'
                type='color'
                colorOptions={settings.colorOptions ?? undefined}
            />
            <Separator sx={dividerStyles} orientation='vertical' variant='middle' />
            <TextAlignment
                onSetTextAlign={(v) => setTextAlign(v)}
                textAlign={settings.textSettings?.textAlign || TextAlign.Left}
            />
            <Separator sx={dividerStyles} orientation='vertical' variant='middle' />
            <SpecialCharacters onSetTextCharacter={setTextCharacter} />
        </div>
    );
}
