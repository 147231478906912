import { gql } from '@apollo/client';

export const duplicateCampaign = gql`
    mutation duplicateCampaign($input: DuplicateCampaignInput!) {
        duplicateCampaign(input: $input) {
            id
            name
        }
    }
`;
