import React, { createContext, ReactElement, useMemo } from 'react';
import { Provider } from '../types/generator';

type ProviderAccordionContextType = {
    providers: Provider[];
    setProviders: (providers: Provider[]) => void;
};

export const ProviderAccordionContext = createContext<ProviderAccordionContextType>({
    providers: [],
    setProviders: () => {},
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function ProviderAccordionProvider(props: Props): ReactElement {
    const { children } = props;

    const [providers, setProviders] = React.useState<Provider[]>([]);

    const value = useMemo(
        () => ({
            providers,
            setProviders,
        }),
        [providers],
    );

    return <ProviderAccordionContext.Provider value={value}>{children}</ProviderAccordionContext.Provider>;
}
