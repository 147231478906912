import { Event } from '../types/enums/events';
import EnumUtils from '../utils/enum';
import { ApplicationPayloadObject } from '../types/events';
import { ILoggerService } from './logger';

interface IEventBusService {
    /**
     * Subscribes the given callback function to the given action.
     *
     * @param event The action to listen to
     * @param callback The function that is called when the action occurs
     * @returns A function that revokes the subscription on execution (Can only be called once!)
     */
    onEvent(event: Event, callback: (payload: ApplicationPayloadObject) => void): () => void;

    /**
     * Publish emit a new event to the bus.
     *
     * @param event The target action.
     * @param payload The payload data that is additionally added to the event.
     */
    publish(event: Event, payload?: ApplicationPayloadObject): void;
}

type Subscription = {
    id: number;
    event: Event;
    callback: (payload: ApplicationPayloadObject) => void;
};

const EventBusService = (logger: ILoggerService): IEventBusService => {
    const subscriptions: Subscription[] = [];
    let subscriptionIdCounter = 0;

    /**
     * Publish emit an event to the event bus.
     *
     * @param e The events action
     * @param payload The events payload
     */
    function publish(e: Event, payload?: ApplicationPayloadObject): void {
        subscriptions
            .filter((subscription) => subscription.event === e)
            .forEach((subscription) => {
                subscription.callback(payload);
            });
    }

    /**
     * subscribe to an action that is emitted on an event bus.
     *
     * @param event
     * @param callback
     */
    function subscribe(event: Event, callback: (payload: ApplicationPayloadObject) => void): () => void {
        const id = subscriptionIdCounter;
        subscriptionIdCounter += 1;
        subscriptions.push({ id, event, callback });

        logger.debug(
            `Registered new event subscription for action ${EnumUtils.getTsNameOfEnumValue(
                Event,
                event,
            )} with subscription ID ${id}, callback in details`,
            `${callback}`,
        );

        // Build a callback function to remove the subscription. This is implemented this way instead of
        // cancelling by id so subscriptions can only be revoked from the context they were created from.
        return (): void => {
            const subscriptionToRemove = subscriptions.find((subscription) => subscription.id === id);

            if (!subscriptionToRemove) {
                logger.warn(
                    `Could not remove subscription with ID ${id}, subscription is not present (anymore).` +
                        `You should inspect your code and eliminate the source of double calling the revoke-` +
                        `subscription-function`,
                );
                return;
            }

            subscriptions.splice(subscriptions.indexOf(subscriptionToRemove), 1);
            logger.debug(`Removed subscription with ID ${id}`);
        };
    }

    return {
        onEvent: subscribe,
        publish,
    };
};

export type { IEventBusService };
export default EventBusService;
