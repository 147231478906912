import React, { ReactElement } from 'react';
import Class from '../../utils/classes';

type Props = {
    children: ReactElement | ReactElement[];
    className?: string;
};

export default function Panel(props: Props): ReactElement {
    const { children, className } = props;

    return (
        <div className={Class.classNames('flex w-full flex-col rounded-xl bg-white shadow', className || '')}>
            {children}
        </div>
    );
}

Panel.defaultProps = {
    className: '',
};
