import { gql } from '@apollo/client';

export const getTemplateById = gql`
    query getTemplateById($templateId: ID!) {
        template(id: $templateId) {
            id
            name
            formats {
                id
                groupId
                name
                width
                height
            }
            instances {
                id
                templateId
                name
                languageId
                values {
                    id
                    path
                    value
                }
                renderSettings {
                    bannerSettings {
                        targetUrl
                        loop
                    }
                }
            }
            playerTemplate
            renderingExportTargets
            clientFormats {
                format {
                    id
                    groupId
                    name
                    width
                    height
                    layoutId
                }
            }
        }
    }
`;
