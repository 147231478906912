/* eslint-disable no-console */
import LogLevel from '../types/enums/log-level';

const names = new Map<LogLevel, string>();

names.set(LogLevel.ERROR, 'ERROR');
names.set(LogLevel.WARNING, 'WARN');
names.set(LogLevel.INFO, 'INFO');
names.set(LogLevel.DEBUG, 'DEBUG');

interface ILoggerService {
    info(message?: string, ...optionalParams: any[]): void;

    debug(message?: string, ...optionalParams: any[]): void;

    warn(message?: string, ...optionalParams: any[]): void;

    error(message?: string, ...optionalParams: any[]): void;
}

const LoggerService = (): ILoggerService => {
    const enableLogging = process.env.REACT_APP_LOGGGING === 'true';

    function info(message?: string, ...optionalParams: any[]): void {
        if (!enableLogging) {
            return;
        }

        if (optionalParams[0] === undefined) {
            console.log(`[${names.get(LogLevel.INFO)}] ${message}`);
        } else {
            console.log(`[${names.get(LogLevel.INFO)}] ${message}`, optionalParams);
        }
    }

    function debug(message?: string, ...optionalParams: any[]): void {
        if (!enableLogging) {
            return;
        }

        if (optionalParams[0] === undefined) {
            console.log(`[${names.get(LogLevel.DEBUG)}] ${message}`);
        } else {
            console.log(`[${names.get(LogLevel.DEBUG)}] ${message}`, optionalParams);
        }
    }

    function warn(message?: string, ...optionalParams: any[]): void {
        if (!enableLogging) {
            return;
        }

        if (optionalParams[0] === undefined) {
            console.warn(`[${names.get(LogLevel.WARNING)}] ${message}`);
        } else {
            console.warn(`[${names.get(LogLevel.WARNING)}] ${message}`, optionalParams);
        }
    }

    function error(message?: string, ...optionalParams: any[]): void {
        if (!enableLogging) {
            return;
        }

        if (optionalParams[0] === undefined) {
            console.error(`[${names.get(LogLevel.ERROR)}] ${message}`);
        } else {
            console.error(`[${names.get(LogLevel.ERROR)}] ${message}`, optionalParams);
        }
    }

    return {
        info,
        warn,
        debug,
        error,
    };
};

export type { ILoggerService };
export default LoggerService;
