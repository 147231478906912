import React, { ReactElement, ReactNode } from 'react';
import { Drawer as MUIDrawer } from '@mui/material';

type Props = {
    isOpen?: boolean;
    variant?: 'permanent' | 'persistent' | 'temporary';
    anchor?: 'left' | 'top' | 'right' | 'bottom';
    className?: string;
    paperClassName?: string;
    children?: ReactNode;
};

export default function Drawer(props: Props): ReactElement {
    const { isOpen, variant, anchor, className, paperClassName, children } = props;

    return (
        <MUIDrawer
            variant={variant}
            anchor={anchor}
            open={isOpen}
            className={`box-border shrink-0 whitespace-nowrap ${className}`}
            PaperProps={{ className: paperClassName }}
        >
            {children}
        </MUIDrawer>
    );
}

Drawer.defaultProps = {
    isOpen: false,
    variant: 'temporary',
    anchor: 'left',
    className: '',
    paperClassName: '',
    children: undefined,
};
