import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { useKeycloak } from '@react-keycloak/web';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { useAppConfig } from './config/app-config-use';

// eslint-disable-next-line import/no-mutable-exports
export let GraphQlClient = new ApolloClient({
    cache: new InMemoryCache(),
});

type Props = {
    children: ReactNode;
};

export default function GraphQLProvider(props: Props): ReactElement {
    const { children } = props;
    const { keycloak } = useKeycloak();
    const [graphQlInitialized, setGraphQlInitialized] = useState(false);
    const appConfig = useAppConfig();

    useEffect(() => {
        if (keycloak.authenticated && !appConfig.loading) {
            const httpLink = createUploadLink({
                uri: appConfig.config.backend.apiUrl,
            });

            const authLink = setContext((_, { headers }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${keycloak.token}`,
                    'Apollo-Require-Preflight': 'true',
                },
            }));

            const wsLink = new GraphQLWsLink(
                createClient({
                    url: appConfig.config.backend.subscriptionsUrl,
                    connectionParams: async () => ({ Authorization: `Bearer ${keycloak.token}` }),
                }),
            );

            const splitLink = split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                // @ts-ignore
                authLink.concat(httpLink),
            );

            GraphQlClient = new ApolloClient({
                cache: new InMemoryCache(),
                link: splitLink,
            });

            setGraphQlInitialized(true);
        }
    }, [keycloak, appConfig]);
    return keycloak.authenticated && graphQlInitialized ? (
        <ApolloProvider client={GraphQlClient}>{children}</ApolloProvider>
    ) : (
        <div />
    );
}
