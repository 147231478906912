import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { EditorState } from '../../slices/editor';
import { AssetGroup } from '../../../types/assets';
import { TemplateImage } from '../../../generated/gql/graphql';

export const selectImagePropertiesByAssetGroup = (assetGroup: AssetGroup): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) =>
            state.baseTemplate?.objects
                .filter((item) => item !== undefined)
                .filter((item) => item.content?.type === 'Image')
                .filter((item) => (item.content as TemplateImage)?.assetGroups?.includes(assetGroup)),
    );
