import { GraphQlClient } from '../providers/graphql-provider';
import {
    CampaignItem,
    ClientTemplateFormat,
    CreateTemplateFormatInput,
    Template,
    TemplateFormat,
    TemplateInstance,
} from '../generated/gql/graphql';
import { Mutations, Queries } from '../repositories';
import { store } from '../stores';
import { EditorActions } from '../stores/slices/editor';
import { Event } from '../types/enums/events';
import { ILoggerService } from './logger';
import { IEventBusService } from './event-bus';
import { TemplateType } from '../types/enums/template';
import { IEditorService } from './editor';

interface ITemplateService {
    getTemplateInstances(id: string): Promise<TemplateInstance[]>;

    getTemplateById(id: string): Promise<Template>;

    loadTemplate(id: string): Promise<void>;

    getTemplateFormats(id: string): Promise<TemplateFormat[]>;

    getAllTemplates(): Promise<Template[]>;

    loadBaseTemplate(id: string): Promise<Template>;

    loadTemplateByCampaignItem(campaignItem: CampaignItem): Promise<void>;

    loadTemplateInstanceById(instanceId: string): Promise<void>;

    getTemplateType(id: string): Promise<TemplateType>;

    createTemplateFormat(templateId: string, format: CreateTemplateFormatInput): Promise<ClientTemplateFormat>;
}

const TemplateService = (
    editorService: IEditorService,
    logger: ILoggerService,
    eventBus: IEventBusService,
): ITemplateService => {
    async function getTemplateInstances(id: string): Promise<TemplateInstance[]> {
        const result = await GraphQlClient.query({
            fetchPolicy: 'no-cache',
            query: Queries.template.getTemplateInstances,
            variables: { templateId: id },
        });

        if (result.error) {
            return [];
        }

        return result.data.template.instances;
    }

    async function getTemplateById(id: string): Promise<Template> {
        const result = await GraphQlClient.query({
            query: Queries.template.getTemplateById,
            fetchPolicy: 'no-cache',
            variables: { templateId: id },
        });

        if (result.error) {
            throw result.error;
        }

        const { playerTemplate, ...templateMeta } = result.data.template;
        return { ...templateMeta, ...playerTemplate };
    }

    async function loadTemplate(id: string): Promise<void> {
        const template = await getTemplateById(id);
        store.dispatch(EditorActions.setBaseTemplate({ template }));

        if (!template.formats) {
            logger.info(`Template ${template.id} has no formats`);
            return;
        }

        const format = template.formats[0];
        store.dispatch(EditorActions.setSelectedFormat({ format }));

        // ServiceInstances.eventBus.publish(Event.REDRAW);
    }

    async function loadBaseTemplate(id: string): Promise<Template> {
        const template = await getTemplateById(id);
        store.dispatch(EditorActions.setBaseTemplate({ template }));

        if (template.formats?.length === 0 || !template.formats) {
            logger.info(`Template ${template.id} has no formats`);
            return template;
        }

        const firstFormat = template.formats[0];
        store.dispatch(EditorActions.setSelectedFormat({ format: firstFormat }));
        eventBus.publish(Event.CAMPAIGN_LOADED_TEMPLATE);
        eventBus.publish(Event.CANVAS_FORCE_REDRAW);

        return template;
    }

    async function loadTemplateByCampaignItem(campaignItem: CampaignItem): Promise<void> {
        await loadBaseTemplate(campaignItem.templateId);
        editorService.setCampaignItem(campaignItem.id);
    }

    async function loadTemplateInstanceById(instanceId: string): Promise<void> {
        const instance = await GraphQlClient.query({
            fetchPolicy: 'no-cache',
            query: Queries.instance.getTemplateInstanceById,
            variables: { templateInstanceId: instanceId },
        });

        if (instance.error || instance.errors) {
            logger.error(`Unable to find instance id ${instanceId}`);
            return;
        }

        store.dispatch(
            EditorActions.setInstanceTemplate({
                instance: {
                    templateInstanceId: instanceId,
                    ...instance.data.templateInstance,
                },
            }),
        );

        const formatValues = instance.data.templateInstance.formatValues || [];
        const currentFormat = store.getState().editor.selectedFormat;

        store.dispatch(
            EditorActions.setIsFormatSpecificActive({
                isFormatSpecificActive:
                    (formatValues.find((o: any) => o.formatId === currentFormat?.id) && formatValues.length > 0) ||
                    false,
            }),
        );

        eventBus.publish(Event.CANVAS_FORCE_REDRAW);
    }

    async function getTemplateFormats(id: string): Promise<TemplateFormat[]> {
        const result = await GraphQlClient.query({
            query: Queries.template.getTemplateFormats,
            variables: { templateId: id },
        });

        if (result.error) {
            return [];
        }

        return result.data.template.formats;
    }

    async function getAllTemplates(): Promise<Template[]> {
        const result = await GraphQlClient.query({
            query: Queries.template.getAllTemplates,
        });

        if (result.error) {
            return [];
        }

        return result.data.templates;
    }

    async function getTemplateType(id: string): Promise<TemplateType> {
        const result = await GraphQlClient.query({
            query: Queries.template.getTemplateMotionLength,
            variables: { templateId: id },
        });

        if (result.error) {
            throw result.error;
        }

        return result.data.template.motion === 0 ? TemplateType.Still : TemplateType.Motion;
    }

    async function createTemplateFormat(
        templateId: string,
        format: CreateTemplateFormatInput,
    ): Promise<ClientTemplateFormat> {
        try {
            const result = await GraphQlClient.mutate({
                mutation: Mutations.template.createClientFormat,
                variables: {
                    input: {
                        templateId,
                        format,
                    },
                },
            });

            if (result.errors) {
                throw result.errors;
            }

            return result.data.createClientFormat;
        } catch (error) {
            logger.error('Failed to create format', error);
            return {} as ClientTemplateFormat;
        }
    }

    return {
        getTemplateInstances,
        getTemplateById,
        loadTemplate,
        getTemplateFormats,
        getAllTemplates,
        loadBaseTemplate,
        loadTemplateByCampaignItem,
        loadTemplateInstanceById,
        getTemplateType,
        createTemplateFormat,
    };
};

export type { ITemplateService };
export default TemplateService;
