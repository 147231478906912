import { Rectangle } from '@metaphore/magnolia-rendering';
import { ICommonInstanceService } from './common';

interface IPackshotsInstanceService {
    setPackshots(id: string, src: string): Promise<void>;

    scalePackshot(id: string, scale: number): Promise<void>;

    cropPackshot(id: string, frame: Rectangle | undefined): Promise<void>;

    resetPackshot(id: string): Promise<void>;
}

const PackshotsInstanceService = (common: ICommonInstanceService): IPackshotsInstanceService => {
    async function setPackshots(id: string, src: string): Promise<void> {
        await common.setObjectChange(id, 'content.src', src);
    }

    async function scalePackshot(id: string, scale: number): Promise<void> {
        await common.setObjectChange(id, 'scale', scale);
    }

    async function cropPackshot(id: string, frame: Rectangle | undefined): Promise<void> {
        await common.setObjectChange(id, 'content.frame', frame);
    }

    async function resetPackshot(id: string): Promise<void> {
        await common.setObjectChange(id, 'content.src', undefined, true);
    }

    return {
        setPackshots,
        scalePackshot,
        cropPackshot,
        resetPackshot,
    };
};

export type { IPackshotsInstanceService };
export default PackshotsInstanceService;
