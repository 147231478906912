import React, { ReactElement, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from '../stores';

type Props = {
    children: ReactNode;
};

export default function ReduxStoreProvider(props: Props): ReactElement {
    const { children } = props;
    return <Provider store={store}>{children}</Provider>;
}
