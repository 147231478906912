import React, { ReactElement } from 'react';
import Skeleton from './skeleton';

export default function PageLayoutSkeleton(): ReactElement {
    return (
        <>
            <div className='my-20'>
                <Skeleton variant='rectangular' className='h-30 w-200 rounded-xl' />
            </div>
            <Skeleton variant='rectangular' className='rounded-xl' />
        </>
    );
}
