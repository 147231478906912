import React, { MouseEvent, ReactElement } from 'react';
import HorizontalItemSelectionItem from './horizontal-item-selection-item';
import useLocale from '../../../hooks/use-locale';

type Props = {
    items: string[];
    selectedItems: string[];
    onItemSelect: (item: string) => void;
};

export default function HorizontalItemSelectionList(props: Props): ReactElement {
    const { items, selectedItems, onItemSelect } = props;

    const { getText } = useLocale();

    function onLanguageClick(item: string, event: MouseEvent<HTMLElement>): void {
        event.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });

        onItemSelect(item);
    }

    return (
        <ul className='flex space-x-10 pb-15'>
            {items.length > 1 && (
                <HorizontalItemSelectionItem
                    text={getText('components.list.horizontalItemSelectionList.all')}
                    isSelected={selectedItems.length === items.length}
                    onClick={(e) => onLanguageClick('All', e)}
                />
            )}
            {items.map((item) => (
                <HorizontalItemSelectionItem
                    key={item}
                    text={item}
                    isSelected={selectedItems.includes(item)}
                    onClick={(e) => onLanguageClick(item, e)}
                />
            ))}
        </ul>
    );
}
