import React, { Fragment, ReactElement } from 'react';
import Modal from '../../../components/modal/modal';
import useLocale from '../../../hooks/use-locale';
import { uuid } from 'short-uuid';

type Props = {
    open: boolean;
    onClose: () => void;
};

export default function HotkeysModal(props: Props): ReactElement {
    const { open, onClose } = props;

    const { getText } = useLocale();

    const commandList = [
        {
            command: getText('widgets.hotkeys.commands.showSelection.shortKey'),
            label: getText('widgets.hotkeys.commands.showSelection.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.undo.shortKey'),
            label: getText('widgets.hotkeys.commands.undo.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.redo.shortKey'),
            label: getText('widgets.hotkeys.commands.redo.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.fitTo100.shortKey'),
            label: getText('widgets.hotkeys.commands.fitTo100.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.fitToCanvas.shortKey'),
            label: getText('widgets.hotkeys.commands.fitToCanvas.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.download.shortKey'),
            label: getText('widgets.hotkeys.commands.download.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.nextFormat.shortKey'),
            label: getText('widgets.hotkeys.commands.nextFormat.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.previousFormat.shortKey'),
            label: getText('widgets.hotkeys.commands.previousFormat.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.videoPlayPause.shortKey'),
            label: getText('widgets.hotkeys.commands.videoPlayPause.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.videoRewind.shortKey'),
            label: getText('widgets.hotkeys.commands.videoRewind.label'),
        },
        {
            command: getText('widgets.hotkeys.commands.videoForward.shortKey'),
            label: getText('widgets.hotkeys.commands.videoForward.label'),
        },
    ];

    return (
        <Modal
            onClose={onClose}
            title={getText('widgets.hotkeys.title')}
            open={open}
            maxWidth='790px'
            showCancelButton={false}
            onSubmit={() => onClose()}
            actions={[{ id: 'close', label: 'Close' }]}
        >
            <ul className='grid grid-cols-3 space-x-20 space-y-14'>
                <li className='col-span-2 mt-14 text-lg text-companyMediumGrey'>
                    {getText('widgets.hotkeys.apple.headline')}
                </li>
                <li className='col-span-2 text-lg text-companyMediumGrey'>
                    {getText('widgets.hotkeys.windows.headline')}
                </li>
                {commandList.map((i) => (
                    <Fragment key={uuid()}>
                        <li className='col-end-1 !ml-0 text-base font-bold'>
                            {i.command.replace('mod', getText('widgets.hotkeys.apple.mod'))}
                        </li>
                        <li className='col-end-2 text-base text-companyLightText'>{i.label}</li>
                        <li className='col-end-3 text-base font-bold'>
                            {i.command.replace('mod', getText('widgets.hotkeys.windows.mod'))}
                        </li>
                        <li className='col-end-4 text-base text-companyLightText'>{i.label}</li>
                    </Fragment>
                ))}
            </ul>
        </Modal>
    );
}
