import React, { ReactElement, useState } from 'react';
import SearchField from '../../../../components/inputs/search-field';
import useLocale from '../../../../hooks/use-locale';
import { useStoreValue } from '../../../../hooks/use-store-value';
import TemplateSelector from '../../../../stores/selectors/template';
import { AssetGroup } from '../../../../types/assets.d';
import AssetTile from '../../../asset/asset-tile';
import short from 'short-uuid';
import { TemplateObject } from '@metaphore/magnolia-rendering';
import { TemplateImage, TemplateObjectChange } from '../../../../generated/gql/graphql';
import InstanceSelector from '../../../../stores/selectors/instance';

type Props = {
    label: string;
    assetGroup: AssetGroup;
    onSelectTemplateObjectId(objectId: string): void;
};

export default function AssetSearch(props: Props): ReactElement {
    const { label, assetGroup, onSelectTemplateObjectId } = props;

    const { getText } = useLocale();
    const templateAssets = useStoreValue<TemplateObject[]>(TemplateSelector.getImagePropertiesByAssetGroup(assetGroup));
    const instanceValues = useStoreValue<TemplateObjectChange[]>(InstanceSelector.getImageProperties());
    const [searchTerm, setSearchTerm] = useState('');

    function getFilteredAssets(): TemplateObject[] {
        if (!templateAssets) {
            return [];
        }

        const formattedSearchTerm = searchTerm?.toLowerCase();
        return templateAssets.filter((element) => element.name?.toLowerCase().includes(formattedSearchTerm || ''));
    }

    function getChangedInstanceSrc(id: string): string {
        const instance = instanceValues.find((v) => v.id === id);
        return instance?.value || '';
    }

    return (
        <div className='flex flex-col overflow-hidden rounded-10 shadow'>
            <div className='flex w-full flex-row items-center justify-between space-x-200 border-b-1 px-30 pb-20 pt-20'>
                <p className='text-xl uppercase text-companyMediumGrey'>{label}</p>
                <SearchField
                    placeholder={getText('widgets.canvas.searchAndReplace.searchPlaceholder')}
                    onChange={(v) => setSearchTerm(v)}
                />
            </div>
            <div
                className='max-h-420 min-h-[320px]
                overflow-y-scroll px-30 py-20 scrollbar-thin
                scrollbar-track-companyLightGrey scrollbar-thumb-companyGrey
                scrollbar-track-rounded-10 scrollbar-thumb-rounded-10'
            >
                <div className='grid grid-cols-6 gap-10'>
                    {getFilteredAssets().map((v) => (
                        <AssetTile
                            key={short.generate()}
                            id={v.id || ''}
                            url={getChangedInstanceSrc(v.id!) || (v.content as TemplateImage).src || ''}
                            dark
                            onClick={() => onSelectTemplateObjectId(v.id || '')}
                            focused={false}
                            deletable={false}
                            onDelete={() => {}}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
