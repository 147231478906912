import { useContext } from 'react';
import { ProviderAccordionContext } from '../providers/provider-accordion-provider';
import { Provider } from '../types/generator';

type ProviderAccordionApi = {
    providers: Provider[];
    setProviders: (providers: Provider[]) => void;
    expandProvider: (providerId: string, expand: boolean) => void;
    expandAllProviders: (expand: boolean) => void;
};

export default function useProviderAccordion(): ProviderAccordionApi {
    const { providers, setProviders } = useContext(ProviderAccordionContext);

    function expandProvider(providerId: string, expand: boolean): void {
        const newProviders = providers.map((p) => {
            if (p.id === providerId) {
                return { ...p, expanded: expand };
            }
            return p;
        });
        setProviders(newProviders);
    }

    function expandAllProviders(expand: boolean): void {
        const newProviders = providers.map((p) => ({ ...p, expanded: expand }));
        setProviders(newProviders);
    }

    return {
        providers,
        setProviders,
        expandProvider,
        expandAllProviders,
    };
}
