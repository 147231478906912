import React, { ChangeEvent, ReactElement } from 'react';
import { Search } from '@mui/icons-material';
import { InputBase as MUIInputBase } from '@mui/material';
import { ReactComponent as SearchAndReplaceIcon } from '../../assets/icons/search-and-replace-icon.svg';

type Props = {
    placeholder: string;
    onChange: (input: string) => void;
    label?: string;
    resultLabel?: string;
    type?: 'search' | 'replace';
    disabled?: boolean;
};

export default function SearchField(props: Props): ReactElement {
    const { placeholder, label, type, resultLabel, disabled, onChange } = props;

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        onChange(event.target.value);
    };

    return (
        <div>
            {label && <p className='mb-5 text-sm text-companyMediumGrey'>{label}</p>}
            <div
                className='bg-w flex w-full flex-row items-center gap-11 rounded-10 pb-6 pl-20 pr-20
            pt-6 shadow'
            >
                {type === 'search' ? (
                    <Search className='fill-companyMediumGrey' />
                ) : (
                    <SearchAndReplaceIcon className='text-companyMediumGrey' />
                )}
                <MUIInputBase
                    placeholder={placeholder}
                    onChange={handleChange}
                    className='w-full'
                    disabled={disabled}
                />
                {resultLabel && <span className='whitespace-nowrap text-companyMediumGrey'>{resultLabel}</span>}
            </div>
        </div>
    );
}

SearchField.defaultProps = {
    label: '',
    resultLabel: '',
    type: 'search',
    disabled: false,
};
