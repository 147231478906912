import React, { ReactElement } from 'react';
import { Tooltip as MUITooltip } from '@mui/material';

type Props = {
    title?: string;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    children: ReactElement;
};

export default function Tooltip(props: Props): ReactElement {
    const { title, placement, children } = props;

    if (!title) {
        return children;
    }

    return (
        <MUITooltip title={title} placement={placement}>
            {children}
        </MUITooltip>
    );
}

Tooltip.defaultProps = {
    title: undefined,
    placement: 'bottom',
};
