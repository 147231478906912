import ServiceContext from '../providers/service-provider';
import { useContext } from 'react';
import { Services } from '../services';

/**
 * It returns the Services object that was passed to the Provider component
 * @returns The useServices hook returns the Services object.
 */
export function useServices(): Services {
    return useContext(ServiceContext);
}
