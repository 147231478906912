import React, { ReactElement } from 'react';
import MUIButton from '@mui/material/Button';

type Props = {
    label: string;

    onClick(): void;
    variant?: 'text' | 'outlined' | 'contained';
    className?: string;
    disabled?: boolean;
};

export default function Button(props: Props): ReactElement {
    const { label, variant, onClick, className, disabled } = props;

    return (
        <MUIButton variant={variant} onClick={onClick} className={className} disabled={disabled}>
            {label}
        </MUIButton>
    );
}

Button.defaultProps = {
    variant: 'text',
    className: '',
    disabled: false,
};
