import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { EditorState } from '../../slices/editor';

export const selectTextPropertyById = (id: string): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) =>
            state.instanceTemplate?.values
                ?.filter((item) => item.path === 'content.text')
                .find((item) => item.id === id),
    );
