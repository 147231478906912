import React, { ReactElement, useEffect, useState } from 'react';
import { LogoutOutlined, PersonOutline, SettingsOutlined } from '@mui/icons-material';
import TextIconButton from '../buttons/text-icon-button';
import useLocale from '../../hooks/use-locale';
import { useKeycloak } from '@react-keycloak/web';
import { useServices } from '../../hooks/use-services';
import { asyncInvoke } from '../../utils/function';
import HotkeysModal from '../../widgets/modal/settings/hotkeys-modal';
import FallbackLogo from '../../assets/metaphore-logo-red.svg';

export default function AppHeader(): ReactElement {
    const { getText } = useLocale();
    const { keycloak } = useKeycloak();
    const { me } = useServices();

    const [logoUrl, setLogoUrl] = useState<string>();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    async function loadClientLogo(): Promise<void> {
        try {
            const url = await me.getClientLogo();
            setLogoUrl(url);
        } catch (error) {
            console.error('Error loading client logo', error);
        }
    }

    useEffect(asyncInvoke(loadClientLogo), []);

    function getKeycloakExternalLink(): string {
        return `${keycloak.authServerUrl}realms/${keycloak.realm}/account/?referrer=${
            keycloak.clientId
        }&referrer_uri=${encodeURIComponent(window.location.origin)}`;
    }

    return (
        <div className='mb-30 flex w-full flex-row justify-between'>
            <div className='flex items-center'>
                {logoUrl ? (
                    <img src={logoUrl} alt='Company Logo' width='100%' height='100%' className='max-h-50 max-w-250' />
                ) : (
                    <img
                        src={FallbackLogo}
                        alt='Company Logo'
                        width='100%'
                        height='100%'
                        className='max-h-50 max-w-250'
                    />
                )}
            </div>
            <div className='flex flex-row gap-30'>
                <TextIconButton
                    label={getText('components.appHeader.account')}
                    icon={<PersonOutline className='fill-companyDarkGrey' />}
                    onClick={() => {
                        window.location.href = getKeycloakExternalLink();
                    }}
                />
                <TextIconButton
                    label={getText('components.appHeader.settings')}
                    icon={<SettingsOutlined className='fill-companyDarkGrey' />}
                    onClick={() => setIsSettingsOpen(true)}
                />
                {keycloak.authenticated ? (
                    <TextIconButton
                        label={getText('components.appHeader.logout')}
                        icon={<LogoutOutlined className='fill-companyDarkGrey' />}
                        onClick={() => {
                            keycloak.logout();
                        }}
                    />
                ) : (
                    <TextIconButton
                        label={getText('components.appHeader.login')}
                        icon={<LogoutOutlined className='fill-companyDarkGrey' />}
                        onClick={() => {
                            keycloak.login();
                        }}
                    />
                )}
            </div>
            <HotkeysModal open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
        </div>
    );
}
