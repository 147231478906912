import {
    createPixiTemplateRenderer,
    isTemplateImage,
    isTemplateText,
    PointerDownEvent,
    Rectangle,
    TemplateObject,
    TemplatePlayer,
    TemplatePlayerCurrentTimeChangeEvent,
    TemplateRenderer,
} from '@metaphore/magnolia-rendering';
import { TemplateFormat } from '../generated/gql/graphql';
import { store } from '../stores';
import { CanvasActions } from '../stores/slices/canvas';
import { ILoggerService } from './logger';
import TemplateUtils from '../utils/template';
import { IEventBusService } from './event-bus';
import { Event } from '../types/enums/events';
import { ICommonInstanceService } from './instance/common';
import { EditorActions } from '../stores/slices/editor';
import { EditMode } from '../types/enums/edit-mode';

interface ICanvasService {
    init(ref: HTMLDivElement, format: TemplateFormat): void;

    render(playtime?: number): void;

    play(): void;

    pause(): void;

    stop(): void;

    forward(): void;

    rewind(): void;

    setLoop(looping: boolean): void;

    setEditMode(editmode: EditMode): void;

    setPlaytime(playtime: number): void;

    showOutlines(visible: boolean): void;

    selectObject(objectId: string | undefined): void;

    resize(format: TemplateFormat): void;

    crop(id: string, path: string, frame: object | string): void;

    getObjectBounds(id: string): Rectangle | undefined;

    setObjectOpacity(id: string, value: number): void;

    getTemplateObjectsAtPoint(x: number, y: number): TemplateObject[];
}

const CanvasService = (
    logger: ILoggerService,
    eventBus: IEventBusService,
    common: ICommonInstanceService,
): ICanvasService => {
    let renderer: TemplateRenderer;
    let player: TemplatePlayer;

    /**
     * Initialize template renderer on specified reference element.
     * @param ref Target canvas.
     * @param format Target format.
     */
    async function init(ref: HTMLDivElement, format: TemplateFormat): Promise<void> {
        renderer = await createPixiTemplateRenderer({
            container: ref,
            loadResourcesOptions: {
                // ToDo: Add Fonts
            },
        });

        player = renderer.createPlayer();
        player.template = store.getState().editor.baseTemplate;
        player.instance = store.getState().editor.instanceTemplate;
        player.format = format;
        player.currentTime = store.getState().canvas.playtime;
        player.playMode = store.getState().canvas.isPlaying;
        player.looping = store.getState().canvas.isLooping;
        player.events.addListener('currentTimeChange', (e: TemplatePlayerCurrentTimeChangeEvent) =>
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleCurrentTimeChange(e),
        );

        renderer.resize(format.width, format.height);

        const { outlines } = store.getState().canvas;
        renderer.selection.selectionFrameConfig = outlines.config;
        renderer.selection.highlightFrameConfig = outlines.selectedConfig;

        renderer.events.addListener('pointerdown', (e: PointerDownEvent) => {
            if (e.button === 0) {
                eventBus.publish(Event.CANVAS_SELECTED_OBJECT, { objectId: e.templateObjectId });
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        eventBus.onEvent(Event.CANVAS_FORCE_REDRAW, forceRedraw);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        eventBus.onEvent(Event.CANVAS_SELECTED_OBJECT, (e) => selectObject(e.objectId));
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        eventBus.onEvent(Event.CAMPAIGN_LOADED_TEMPLATE, cleanup);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        eventBus.onEvent(Event.SET_PLAY_TIME, (e) => setPlaytime(e.playtime));

        eventBus.publish(Event.CANVAS_INITIALIZED);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await forceRedraw();
    }

    /**
     * Render canvas with current template and template instance.
     * @param playtime (Video only) specify a timestamp.
     */
    async function render(playtime?: number): Promise<void> {
        if (!renderer || renderer.rendering) {
            return;
        }

        const t = store.getState().editor.baseTemplate;
        if (!t) {
            logger.info('no template');
            return;
        }

        const i = store.getState().editor.instanceTemplate;
        const f = store.getState().editor.selectedFormat;

        player.template = t;
        player.instance = i;
        player.format = f;
        if (playtime !== undefined) {
            player.currentTime = playtime;
        }

        store.dispatch(EditorActions.setTemplateObjectChange({ templateObjectChange: undefined }));
    }

    /**
     * Sets the visible outline state for objects in the canvas.
     * @param visible New visible state
     * @param objectIds Target object IDs. Default: it is applied to all object IDs.
     */
    async function showOutlines(visible: boolean, objectIds?: string[]): Promise<void> {
        if (!renderer) {
            return;
        }

        store.dispatch(CanvasActions.displayObjectOutlines({ visible }));

        if (!visible) {
            renderer.selection.setHighlightedTemplateObjectIds([]);
            eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            return;
        }

        const template = store.getState().editor.baseTemplate;
        if (!template) {
            logger.warn('Outlines cannot be set to true because no template are selected.');
            return;
        }

        const targetIds = objectIds || [];
        if (!objectIds) {
            const allObjectIds = TemplateUtils.getAllObjectIds(template);

            allObjectIds.forEach(
                (id) => TemplateUtils.getObjectById(id, template)?.editable === true && targetIds.push(id),
            );
        }

        renderer.selection.setHighlightedTemplateObjectIds(targetIds);
        eventBus.publish(Event.CANVAS_FORCE_REDRAW);
    }

    function selectObject(objectId: string | undefined): void {
        if (objectId === store.getState().canvas.selectObjectId) {
            logger.warn('Object already selected');
            return;
        }

        renderer.selection.setSelectedTemplateObjectId(objectId);

        const template = store.getState().editor.baseTemplate;

        if (!template) {
            logger.warn('Object cannot be selected, because no template are selected.');
            return;
        }

        const templateObject = TemplateUtils.getObjectById(objectId!, template);

        if (templateObject?.editable) {
            eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            store.dispatch(CanvasActions.selectObject({ objectId }));

            return;
        }

        eventBus.publish(Event.CANVAS_FORCE_REDRAW);
        store.dispatch(CanvasActions.selectObject({ objectId: undefined }));
    }

    function play(): void {
        store.dispatch(CanvasActions.setEnded({ ended: false }));
        store.dispatch(CanvasActions.setPlaying({ playing: true }));
        player.playMode = true;
    }

    function pause(): void {
        store.dispatch(CanvasActions.setPlaying({ playing: false }));
        player.playMode = false;
    }

    function stop(): void {
        store.dispatch(CanvasActions.setEnded({ ended: true }));
        store.dispatch(CanvasActions.setPlaying({ playing: false }));
        player.playMode = false;
    }

    function forward(): void {
        const length = store.getState().editor.baseTemplate?.motion?.length || 0;
        if (player.currentTime + 1 > length) {
            player.currentTime = length;
        } else {
            player.currentTime += 1;
        }
        store.dispatch(CanvasActions.setPlaytime({ playtime: player.currentTime }));
    }

    function rewind(): void {
        if (player.currentTime - 1 < 0) {
            player.currentTime = 0;
        } else {
            player.currentTime -= 1;
        }
        store.dispatch(CanvasActions.setPlaytime({ playtime: player.currentTime }));
    }

    function setLoop(looping: boolean): void {
        store.dispatch(CanvasActions.setLooping({ looping }));
        player.looping = looping;
    }

    function setPlaytime(time: number): void {
        store.dispatch(CanvasActions.setPlaytime({ playtime: time }));
        player.currentTime = time;

        const length = store.getState().editor.baseTemplate?.motion?.length || 0;
        if (time < length && store.getState().canvas.isEnded) {
            store.dispatch(CanvasActions.setEnded({ ended: false }));
        }
    }

    function setEditMode(editmode: EditMode): void {
        store.dispatch(CanvasActions.setEditMode({ editmode }));
    }

    function getObjectBounds(id: string): Rectangle | undefined {
        return renderer.renderingInfo.getTemplateObjectBounds(id);
    }

    function getTemplateObjectsAtPoint(x: number, y: number): TemplateObject[] {
        const template = store.getState().editor.baseTemplate;
        if (!template) {
            return [];
        }

        const result = renderer.hitTester.getTemplateObjectsAtPosition({ x, y });
        if (result.templateObjectIds.length > 0) {
            const templateObjects: TemplateObject[] = [];
            result.templateObjectIds.forEach((id) => {
                const templateObject = TemplateUtils.getObjectById(id, template);

                if ((templateObject && templateObject.editable === false) || !templateObject) {
                    return;
                }

                if (isTemplateImage(templateObject.content) || isTemplateText(templateObject.content)) {
                    templateObjects.push(templateObject);
                }
            });
            return templateObjects;
        }
        return [];
    }

    async function setObjectOpacity(objectId: string, value: number): Promise<void> {
        const template = store.getState().editor.baseTemplate;

        if (template) {
            const templateObjectChange = TemplateUtils.getObjectById(objectId, template);

            if (templateObjectChange) {
                store.dispatch(
                    EditorActions.setTemplateObjectChange({
                        templateObjectChange: {
                            ...templateObjectChange,
                            opacity: value,
                        },
                    }),
                );

                eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            }
        }
    }

    async function resize(format: TemplateFormat): Promise<void> {
        if (player) {
            player.format = format;
        }
        if (renderer) {
            renderer.resize(format.width, format.height);
        }

        eventBus.publish(Event.CANVAS_FORCE_REDRAW);
    }

    async function crop(id: string, path: string, frame: object | string): Promise<void> {
        await common.setObjectChange(id, path, frame);
    }

    async function forceRedraw(): Promise<void> {
        const { isPlaying } = store.getState().canvas;
        if (isPlaying) {
            return;
        }

        const time = store.getState().canvas.playtime;
        await render(time);
    }

    async function cleanup(): Promise<void> {
        const outlinesVisible = store.getState().canvas.outlines.visible;
        if (outlinesVisible) {
            await showOutlines(true);
        }

        setPlaytime(0);

        // eslint-disable-next-line unicorn/no-useless-undefined
        selectObject(undefined);
    }

    function handleCurrentTimeChange(e: TemplatePlayerCurrentTimeChangeEvent): void {
        store.dispatch(CanvasActions.setPlaytime({ playtime: e.currentTime }));
        const length = store.getState().editor.baseTemplate?.motion?.length || 0;
        if (Math.floor(player.currentTime) >= length) {
            stop();
        }
    }

    return {
        init,
        render,
        showOutlines,
        play,
        pause,
        stop,
        forward,
        rewind,
        setLoop,
        setEditMode,
        setPlaytime,
        resize,
        selectObject,
        crop,
        getObjectBounds,
        setObjectOpacity,
        getTemplateObjectsAtPoint,
    };
};

export type { ICanvasService };
export default CanvasService;
