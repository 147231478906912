import { useSelector } from 'react-redux';

/**
 * It takes a selector function and returns the value of the selector function
 * @param {any} v - any - this is the value you want to get from the store.
 * @returns The value of the state that is being passed in.
 */
export function useStoreValue<T>(v: any): T {
    return useSelector((state: T) => v(state)) as T;
}
