/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import MultiCheckbox from '../../components/checkboxs/multi-checkbox';
import short from 'short-uuid';
import TableHeadCell from '../../components/table/table-head-cell';
import useLocale from '../../hooks/use-locale';
import { CheckStatus } from '../../types/enums/check-status';
import { GeneratorCampaignItem, GeneratorFormat } from '../../types/generator';
import Switch from '../../components/switch/switch';
import useProviderAccordion from '../../hooks/use-provider-accordion';

type Props = {
    checkMarkMatrix: GeneratorFormat[];
    campaignItems: GeneratorCampaignItem[];
    updateCheckMarkMatrix(newArray: GeneratorFormat[]): void;
    onExpandAllProviders(expand: boolean): void;
};

export default function NewGeneratorMatrixHeadRow(props: Props): ReactElement {
    const { checkMarkMatrix, campaignItems, updateCheckMarkMatrix, onExpandAllProviders } = props;
    const { getText } = useLocale();
    const { providers } = useProviderAccordion();

    function getColumnCheckStatus(itemId: string): CheckStatus {
        const array = [] as boolean[];

        checkMarkMatrix.forEach((entry) => {
            if (entry.campaignItemId === itemId && !entry.disabled) {
                array.push(entry.checked);
            }
        });

        const checkStatus = array.includes(true)
            ? array.includes(false)
                ? CheckStatus.INDETERMINATE
                : CheckStatus.CHECKED
            : CheckStatus.UNCHECKED;

        return checkStatus;
    }

    function updateColumn(itemId: string): void {
        const currentCheckStatus = getColumnCheckStatus(itemId);
        const updateValue =
            currentCheckStatus === CheckStatus.INDETERMINATE || currentCheckStatus === CheckStatus.UNCHECKED;

        const newCheckMarkMatrix = [] as GeneratorFormat[];

        checkMarkMatrix.forEach((entry) => {
            if (entry.campaignItemId === itemId && !entry.disabled) {
                newCheckMarkMatrix.push({
                    ...entry,
                    checked: updateValue,
                });
            } else {
                newCheckMarkMatrix.push(entry);
            }
        });

        updateCheckMarkMatrix(newCheckMarkMatrix);
    }

    return (
        <thead className='w-full border-b-1'>
            <tr className='flex items-start justify-start '>
                <TableHeadCell className='z-20 flex h-100 w-320 items-start justify-center border-b-1 border-r-1 bg-white !pl-0 !pr-0 after:hidden '>
                    <div className='flex h-full w-full flex-col items-center justify-between'>
                        <h2 className='px-10 text-xl uppercase text-companyMediumGrey'>
                            {getText('widgets.partnerSelect.label')}
                        </h2>
                        <div className='flex items-center'>
                            <Switch
                                checked={providers.every((provider) => !provider.expanded)}
                                onChange={(v) => onExpandAllProviders(!v)}
                            />
                            <p className='text-lg'>{getText('widgets.partnerSelect.toggle')}</p>
                        </div>
                    </div>
                </TableHeadCell>
                {campaignItems.map((item) => (
                    <TableHeadCell
                        key={short.generate()}
                        className='z-10 flex h-100 w-135 flex-col items-center justify-end overflow-visible border-b-1 border-r-1 bg-white !pl-0 !pr-0 after:hidden hover:z-20 '
                    >
                        <MultiCheckbox status={getColumnCheckStatus(item.id)} onClick={() => updateColumn(item.id)} />
                        <div className='group '>
                            <p className='max-w-90 select-none overflow-hidden overflow-ellipsis'>{item.name}</p>
                            <p className='pointer-events-none absolute left-1/2 top-0 -translate-x-1/2 rounded-md bg-gray-500 p-10 text-white opacity-0 transition-opacity group-hover:opacity-100'>
                                {item.name}
                            </p>
                        </div>
                    </TableHeadCell>
                ))}
            </tr>
        </thead>
    );
}
