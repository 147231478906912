import React, { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useRef } from 'react';
import TextField from '../../components/inputs/text-field';
import { useStoreValue } from '../../hooks/use-store-value';
import InstanceSelector from '../../stores/selectors/instance';
import { TemplateObject, TemplateObjectChange, TemplateText } from '../../generated/gql/graphql';
import TemplateSelector from '../../stores/selectors/template';
import Skeleton from '../../components/skeleton/skeleton';
import { useServices } from '../../hooks/use-services';
import TextToolbar from './text-toolbar';
import { Font, isNil, Template, TextAlign } from '@metaphore/magnolia-rendering';
import EditorSelectors from '../../stores/selectors/editor';
import CanvasSelectors from '../../stores/selectors/canvas';
import { FontFamilyChange } from '../../types/font';

type Props = {
    id: string;
    focused: boolean;
};

export default function TextProperty(props: Props): ReactElement {
    const { id, focused } = props;

    const [selectionText, setSelectionText] = React.useState<{ start: number; end: number }>({ start: 0, end: 0 });

    const object = useStoreValue<TemplateObject>(TemplateSelector.getTextPropertyById(id));
    const template = useStoreValue<Template>(EditorSelectors.getActiveTemplate());

    const textChangeValue = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.text'),
    );
    const fontSizeFactor = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.textSettings.fontSizeFactor'),
    );
    const textAlign = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.textSettings.textAlign'),
    );
    const textColor = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.color'),
    );
    const fontFamily = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.textSettings.fontFamily'),
    );
    const fontWeight = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.textSettings.fontWeight'),
    );
    const fontStyle = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getChangedPropertyById(id, 'content.textSettings.fontStyle'),
    );

    const isCanvasPlaying = useStoreValue<boolean>(CanvasSelectors.isPlaying());

    const ref = useRef<null | HTMLDivElement>(null);

    const isDisabled = object.disabled || !object.editable || isCanvasPlaying || false;

    const { instance, canvas } = useServices();

    useEffect(() => {
        if (focused && ref.current) {
            ref.current.scrollIntoView({ block: 'nearest' });
        }
    }, [focused, ref]);

    useEffect(() => {
        function disableUndoRedo(event: KeyboardEvent): void {
            // Check if the user is trying to undo (Ctrl + Z or Command + Z)
            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                event.preventDefault();
            }

            // Check if the user is trying to redo (Ctrl + Y or Command + Shift + Z)
            if ((event.ctrlKey || event.metaKey) && (event.key === 'y' || (event.key === 'z' && event.shiftKey))) {
                event.preventDefault();
            }
        }
        if (ref.current) {
            ref.current.addEventListener('keydown', disableUndoRedo);
        }
        return () => {
            if (ref.current) {
                ref.current.removeEventListener('keydown', disableUndoRedo);
            }
        };
    }, [ref.current]);

    if (!object) {
        return <Skeleton variant='text' />;
    }

    const name = object.name || '';
    const templateText = object.content as TemplateText;

    const textSettings = {
        ...templateText,
        color: textColor?.value || templateText.color,
        textSettings: {
            ...templateText.textSettings,
            fontSizeFactor: fontSizeFactor?.value || templateText.textSettings?.fontSizeFactor || 1,
            textAlign: textAlign?.value || templateText.textSettings?.textAlign || 'left',
            fontFamily: fontFamily?.value || templateText.textSettings?.fontFamily || '',
            fontStyle: fontStyle?.value || 'normal',
            fontWeight: fontWeight?.value || templateText.textSettings?.fontWeight || '',
        },
    };

    const fonts = template.fonts as Font[];
    const text = textChangeValue?.value || (object.content as TemplateText).text || '';

    async function onChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
        await instance.text.setText(id, e.target.value);
    }

    async function onFocus(): Promise<void> {
        canvas.selectObject(id);
    }

    async function onSetColor(path: string, value: string): Promise<void> {
        await instance.text.setColor(id, path, value);
    }

    async function onSetTextAlign(value: TextAlign): Promise<void> {
        await instance.text.setTextAlign(id, value);
    }

    async function onSetTextCharacter(value: string): Promise<void> {
        const replace = `${text.slice(0, selectionText.start)}${value}${text.slice(selectionText.end)}`;
        await instance.text.setText(id, replace);
    }

    async function onSetFontFamily(value: FontFamilyChange): Promise<void> {
        await instance.text.setFontFamily(id, value);
    }

    // async function onSetBackgroundColor(background: TemplateObjectBackground): Promise<void> {
    //     await instance.text.setBackgroundColor(id, background);
    // }

    async function onSetFontSizeFactor(value: number): Promise<void> {
        await instance.text.setFontSizeFactor(id, value);
    }

    function onSelectionText(e: SyntheticEvent<HTMLInputElement, Event>): void {
        const target = e.target as HTMLInputElement;

        if (!isNil(target.selectionStart) && !isNil(target.selectionEnd)) {
            setSelectionText({
                start: target.selectionStart,
                end: target.selectionEnd,
            });
        }
    }

    return (
        <div className='relative'>
            {focused && !isDisabled && (
                <TextToolbar
                    settings={textSettings as TemplateText}
                    setColor={(path, value) => onSetColor(path, value)}
                    fonts={fonts}
                    // setBackgroundColor={(value) => onSetBackgroundColor(value)}
                    setTextAlign={(value) => onSetTextAlign(value)}
                    setFontFamily={(value) => onSetFontFamily(value)}
                    setFontSizeFactor={(value) => onSetFontSizeFactor(value)}
                    setTextCharacter={(value) => onSetTextCharacter(value)}
                />
            )}
            <TextField
                ref={ref}
                multiline
                className='template-text'
                focused={focused}
                disabled={isDisabled}
                label={name}
                value={text}
                onChange={(e) => onChange(e)}
                onFocus={() => onFocus()}
                onSelect={(e) => onSelectionText(e)}
            />
        </div>
    );
}
