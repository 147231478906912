import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function TableBody(props: Props): ReactElement {
    const { children } = props;
    return <tbody>{children}</tbody>;
}
