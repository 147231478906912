export enum Routes {
    CAMPAIGNS = '/',
    CAMPAIGN = '/campaign/:id',
    VIDEO = '/editor/video',
    STILL = '/editor/still',
    BANNER = '/editor/banner',
    GENERATOR = '/generator',
}

export default Routes;
