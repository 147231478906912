import { gql } from '@apollo/client';

export const getAllTemplates = gql`
    query getAllTemplates {
        templates {
            id
            name
        }
    }
`;
