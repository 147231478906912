import React, { ReactElement } from 'react';
import {
    Button,
    Dialog as MUIDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

type Props = {
    title: string;
    contentText: string;
    submitText: string;
    closeText: string;
    open: boolean;
    onClose(): void;
    onSubmit(): void;
};

export default function Dialog(props: Props): ReactElement {
    const { title, contentText, closeText, submitText, open, onClose, onSubmit } = props;

    return (
        <MUIDialog open={open}>
            <DialogTitle className='p-24 pb-32'>{title}</DialogTitle>
            <DialogContent className='space-y-15 pb-0'>
                <DialogContentText className='text-base'>{contentText}</DialogContentText>
            </DialogContent>
            <DialogActions className='p-24 pb-20 pt-16'>
                <Button onClick={onClose}>{closeText}</Button>
                <Button onClick={() => onSubmit()}>{submitText}</Button>
            </DialogActions>
        </MUIDialog>
    );
}
