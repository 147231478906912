import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { EditorState } from '../../slices/editor';

export const selectChangedPropertyById = (id: string, path: string): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) => {
            if (state.isFormatSpecificActive) {
                const obj = state.instanceTemplate?.formatValues?.find(
                    (item) => item.formatId === state.selectedFormat?.id || '',
                );

                if (obj && obj.values) {
                    const values = obj.values.filter((item) => item.path === path);

                    const value = values.find((item) => item.id === id);
                    if (value) {
                        return value;
                    }
                }
            }
            return state.instanceTemplate?.values?.filter((item) => item.path === path).find((item) => item.id === id);
        },
    );
