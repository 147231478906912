import React, { ReactElement } from 'react';
import MUIButton from '@mui/material/Button';
import Class from '../../utils/classes';

type Props = {
    label: string;
    variant?: 'outlined' | 'contained';
    onClick: () => void;
    className?: string;
    disabled?: boolean;
};

export default function ActionButton(props: Props): ReactElement {
    const { label, variant, onClick, className, disabled } = props;
    return (
        <MUIButton
            variant={variant}
            className={Class.classNames(
                'w-180 rounded-15 pb-7 pt-7 text-base normal-case drop-shadow-none',
                className || '',
                variant === 'outlined' ? 'border-company' : '',
                variant === 'contained' ? 'border-1 border-solid border-transparent bg-company text-white' : '',
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </MUIButton>
    );
}

ActionButton.defaultProps = {
    variant: 'outlined',
    disabled: false,
    className: '',
};
