import React, { ReactElement, useEffect, useState } from 'react';
import TextField from '../../../components/inputs/text-field';
import useLocale from '../../../hooks/use-locale';
import Modal from '../../../components/modal/modal';
import ListItemButton from '../../../components/list/list-item-button';
import { Checkbox } from '@mui/material';
import Button from '../../../components/buttons/button';
import { useServices } from '../../../hooks/use-services';
import { asyncInvoke } from '../../../utils/function';
import { Language } from '../../../types/language';

type Props = {
    open: boolean;
    onClose(): void;
    onSubmit(name: string, languageIds: string[]): void;
};

export default function NewCampaignForm(props: Props): ReactElement {
    const { open, onClose, onSubmit } = props;

    const [campaignName, setCampaignName] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

    const [languages, setLanguages] = useState<Language[]>([]);
    const [allowSubmission, setAllowSubmission] = useState(false);

    useEffect(() => {
        setAllowSubmission(selectedLanguages.length > 0 && !!campaignName);
    }, [campaignName, selectedLanguages]);

    const { language } = useServices();
    const { getText } = useLocale();

    async function loadLanguages(): Promise<void> {
        const result = await language.getAllLanguages();
        setLanguages(result);
    }

    useEffect(asyncInvoke(loadLanguages), []);

    function handleSubmit(action: string): void {
        if (action !== 'submit') {
            return;
        }

        if (selectedLanguages.length === 0 || !campaignName) {
            return;
        }

        onSubmit(campaignName, selectedLanguages);
    }

    function handleLanguageChange(id: string): void {
        if (selectedLanguages?.includes(id)) {
            setSelectedLanguages(selectedLanguages.filter((item) => item !== id));
        } else {
            setSelectedLanguages([...(selectedLanguages || []), id]);
        }
    }

    function onSelectAllLanguages(): void {
        setSelectedLanguages(languages.map((item) => item.id));
    }

    function onClearAllLanguages(): void {
        setSelectedLanguages([]);
    }

    return (
        <Modal
            open={open}
            title={getText('components.dialogs.campaign.create.title')}
            actions={[
                {
                    id: 'submit',
                    label: getText('widgets.forms.common.actions.create'),
                },
            ]}
            onSubmit={(v) => handleSubmit(v)}
            onClose={onClose}
            disableSubmitActionIds={!allowSubmission ? ['submit'] : undefined}
            maxWidth='600px'
            maxHeight='760px'
        >
            <h3 className='text-xl'>
                {getText(
                    'components.dialogs.campaign.create.campaignName.label',
                    <span className='text-base text-companyDarkGrey'>*</span>,
                )}
            </h3>
            <TextField
                className='w-full'
                type='text'
                placeholder={getText('components.dialogs.campaign.create.campaignName.placeholder')}
                value={campaignName}
                onChange={(v) => setCampaignName(v.target.value)}
            />

            <div className='flex items-center justify-between'>
                <h3 className='my-auto w-320 text-xl'>
                    {getText(
                        'components.dialogs.campaign.create.selectLanguages',
                        <span className='text-base text-companyDarkGrey'>*</span>,
                    )}
                </h3>
                <div>
                    <Button
                        className='my-auto text-sm text-companyDarkGrey'
                        label='Select all'
                        variant='text'
                        onClick={() => onSelectAllLanguages()}
                    />
                    <Button
                        className='my-auto text-sm text-companyDarkGrey'
                        label='Clear all'
                        variant='text'
                        onClick={() => onClearAllLanguages()}
                    />
                </div>
            </div>
            <p className='text-base'> {getText('components.dialogs.campaign.create.selectLanguagesDesc')}</p>

            <div className='h-[288px] overflow-y-scroll'>
                {languages.map((item) => (
                    <ListItemButton
                        key={item.id}
                        className='flex h-72 w-full text-base'
                        onClick={() => handleLanguageChange(item.id)}
                    >
                        <Checkbox checked={selectedLanguages?.includes(item.id)} />
                        <p className='w-full'>{item.name}</p>
                    </ListItemButton>
                ))}
            </div>
        </Modal>
    );
}
