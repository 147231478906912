import { EditorState } from '../../slices/editor';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export const selectIsFormatSpecificActive = (): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) =>
            state.isFormatSpecificActive === true ||
            state.instanceTemplate?.formatValues?.find((item) => item.formatId === state.selectedFormat?.id || ''),
    );
