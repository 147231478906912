import React, { ReactElement } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { FastForwardOutlined } from '@mui/icons-material';
import useLocale from '../../hooks/use-locale';
import { useServices } from '../../hooks/use-services';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';

export default function CanvasForwardToggle(): ReactElement {
    const { getText } = useLocale();

    const { canvas } = useServices();

    useHotkeys(ShortKeys.VIDEO_FORWARD, () => canvas.forward(), { preventDefault: true });

    return (
        <IconButton tooltip={getText('widgets.canvas.controls.forward.tooltip')} onClick={() => canvas.forward()}>
            <FastForwardOutlined />
        </IconButton>
    );
}
