import React, { ReactElement, useRef, useState } from 'react';
import Modal from '../../../components/modal/modal';
import useLocale from '../../../hooks/use-locale';
import SelectButton from '../../../components/buttons/select-button';
import TextReplaceModal from './content/text-replace-content';
import { CustomTemplateObjectChange } from '../../../types/template';
import usePrevious from '../../../hooks/use-previous';
import { useServices } from '../../../hooks/use-services';
import AssetReplaceContent, { IAssetReplace } from './content/asset-replace-content';
import { AssetGroup } from '../../../types/assets.d';
import useNotification from '../../../hooks/use-notification';

type Props = {
    open: boolean;
    onClose(): void;
};

enum State {
    SELECT = 'select',
    BACKGROUND_SELECT = 'background',
    BACKGROUND_REPLACE = 'background-replace',
    PACKSHOT_SELECT = 'packshot',
    PACKSHOT_REPLACE = 'packshot-replace',
    TEXT = 'text',
    CONFIRM = 'confirm',
}

export default function SearchAndReplaceModal(props: Props): ReactElement {
    const { open, onClose } = props;
    const { getText } = useLocale();

    const [state, setState] = useState<State>(State.SELECT);
    const [templateObjectChanges, setTemplateObjectChanges] = useState<CustomTemplateObjectChange[]>([]);

    const { common } = useServices();
    const notification = useNotification();

    const backgroundReplaceRef = useRef<IAssetReplace>(null);
    const packshotReplaceRef = useRef<IAssetReplace>(null);

    const previousState = usePrevious(state);

    function handleClose(): void {
        setTimeout(() => {
            setState(State.SELECT);
        }, 300);

        setTemplateObjectChanges([]);
        onClose();
    }

    function onContinue(changes: CustomTemplateObjectChange[]): void {
        setTemplateObjectChanges(changes);

        switch (state) {
            case State.BACKGROUND_REPLACE:
            case State.PACKSHOT_REPLACE:
            case State.TEXT:
                break;
            default:
                setState(State.CONFIRM);
                break;
        }
    }

    async function handleSubmit(): Promise<void> {
        try {
            await common.setObjectChangesForTemplate(templateObjectChanges);
            handleClose();
            notification.pushSuccess(
                getText('widgets.canvas.searchAndReplace.notification.success.body', {
                    count: templateObjectChanges.length,
                }),
                getText('widgets.canvas.searchAndReplace.notification.success.title'),
            );
        } catch (error) {
            notification.pushError(
                getText('widgets.canvas.searchAndReplace.notification.error.body'),
                getText('widgets.canvas.searchAndReplace.notification.error.title'),
            );
            console.error(error);
        }
    }

    function getContent(): ReactElement {
        switch (state) {
            case State.BACKGROUND_SELECT:
            case State.BACKGROUND_REPLACE:
                return (
                    <AssetReplaceContent
                        ref={backgroundReplaceRef}
                        assetGroup={AssetGroup.Background}
                        onContinue={() => setState(State.BACKGROUND_REPLACE)}
                        onSubmit={(o) => onContinue(o)}
                    />
                );
            case State.PACKSHOT_SELECT:
            case State.PACKSHOT_REPLACE:
                return (
                    <AssetReplaceContent
                        ref={packshotReplaceRef}
                        assetGroup={AssetGroup.Packshot}
                        onContinue={() => setState(State.PACKSHOT_REPLACE)}
                        onSubmit={(o) => onContinue(o)}
                    />
                );
            case State.TEXT:
                return <TextReplaceModal onSubmit={(o) => onContinue(o)} />;
            case State.CONFIRM:
                return <p>{getText('widgets.canvas.searchAndReplace.confirm.body')}</p>;
            default:
                return (
                    <>
                        <p className='text-base'>{getText('widgets.canvas.searchAndReplace.select.headline')}</p>
                        <div className='flex flex-col items-start space-y-15'>
                            <SelectButton
                                onClick={() => setState(State.BACKGROUND_SELECT)}
                                label={getText('widgets.canvas.searchAndReplace.select.background')}
                            />
                            <SelectButton
                                onClick={() => setState(State.PACKSHOT_SELECT)}
                                label={getText('widgets.canvas.searchAndReplace.select.packshot')}
                            />
                            <SelectButton
                                onClick={() => setState(State.TEXT)}
                                label={getText('widgets.canvas.searchAndReplace.select.text')}
                            />
                        </div>
                    </>
                );
        }
    }

    function getTitle(): string {
        switch (state) {
            case State.BACKGROUND_SELECT:
            case State.BACKGROUND_REPLACE:
                return getText('widgets.canvas.searchAndReplace.headline');
            case State.PACKSHOT_SELECT:
            case State.PACKSHOT_REPLACE:
                return getText('widgets.canvas.searchAndReplace.headline');
            case State.TEXT:
                return getText('widgets.canvas.searchAndReplace.headline');
            default:
                return getText('widgets.canvas.searchAndReplace.headline');
        }
    }

    function getSubtitle(): string {
        switch (state) {
            case State.BACKGROUND_SELECT:
            case State.BACKGROUND_REPLACE:
            case State.PACKSHOT_SELECT:
            case State.PACKSHOT_REPLACE:
            case State.TEXT:
            case State.CONFIRM:
                return '';
            default:
                return getText('widgets.canvas.searchAndReplace.subtitle');
        }
    }

    function getBackCallback(): undefined | (() => void) {
        switch (state) {
            case State.BACKGROUND_SELECT:
            case State.PACKSHOT_SELECT:
            case State.TEXT:
                return () => setState(State.SELECT);
            case State.BACKGROUND_REPLACE:
                return () => {
                    backgroundReplaceRef.current?.onBack();
                    setState(State.BACKGROUND_SELECT);
                };
            case State.PACKSHOT_REPLACE:
                return () => {
                    packshotReplaceRef.current?.onBack();
                    setState(State.PACKSHOT_SELECT);
                };
            case State.CONFIRM:
                if (previousState === State.BACKGROUND_REPLACE) {
                    return () => setState(State.BACKGROUND_SELECT);
                }
                if (previousState === State.PACKSHOT_REPLACE) {
                    return () => setState(State.PACKSHOT_SELECT);
                }
                return () => setState(previousState);
            default:
                return undefined;
        }
    }

    function getActionCallback(actionId: string): undefined | void | Promise<void> {
        switch (actionId) {
            case 'replace-text':
            case 'replace-asset':
                return setState(State.CONFIRM);
            case 'confirm':
                return handleSubmit();
            default:
                return undefined;
        }
    }

    function getActions(): { id: string; label: string }[] {
        switch (state) {
            case State.BACKGROUND_REPLACE:
            case State.PACKSHOT_REPLACE:
                return [{ id: 'replace-asset', label: getText('widgets.canvas.searchAndReplace.asset.submit') }];
            case State.TEXT:
                return [{ id: 'replace-text', label: getText('widgets.canvas.searchAndReplace.text.submit') }];
            case State.CONFIRM:
                return [{ id: 'confirm', label: getText('widgets.canvas.searchAndReplace.replace') }];
            default:
                return [];
        }
    }

    function getDisabledActions(): string[] {
        switch (state) {
            case State.BACKGROUND_REPLACE:
            case State.PACKSHOT_REPLACE:
                return templateObjectChanges.length === 0 ? ['replace-asset'] : [];
            case State.TEXT:
                return templateObjectChanges.length === 0 ? ['replace-text'] : [];
            default:
                return [];
        }
    }

    function getMaxWidth(): string | undefined {
        switch (state) {
            case State.BACKGROUND_SELECT:
            case State.BACKGROUND_REPLACE:
            case State.PACKSHOT_SELECT:
            case State.PACKSHOT_REPLACE:
            case State.TEXT:
                return '960px';
            default:
                return undefined;
        }
    }

    return (
        <Modal
            onClose={() => handleClose()}
            open={open}
            title={getTitle()}
            subtitle={getSubtitle()}
            showCancelIcon={false}
            onBack={getBackCallback()}
            maxWidth={getMaxWidth()}
            actions={getActions()}
            disableSubmitActionIds={getDisabledActions()}
            onSubmit={(a) => getActionCallback(a)}
        >
            {getContent()}
        </Modal>
    );
}
