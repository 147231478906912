import { UpdateBannerSettingsMutation, UpdateBannerSettingsMutationVariables } from '../../generated/gql/graphql';
import { GraphQlClient } from '../../providers/graphql-provider';
import { Mutations } from '../../repositories';

export type BannerSettingsInput = NonNullable<
    NonNullable<UpdateBannerSettingsMutationVariables['data']['renderSettings']>['bannerSettings']
>;

interface IBannerInstanceService {
    updateSettings(instanceId: string, bannerSettings: BannerSettingsInput): Promise<void>;
}

const BannerInstanceService = (): IBannerInstanceService => {
    async function updateSettings(instanceId: string, bannerSettings: BannerSettingsInput): Promise<void> {
        const result = await GraphQlClient.mutate<UpdateBannerSettingsMutation, UpdateBannerSettingsMutationVariables>({
            mutation: Mutations.instance.updateBannerSettings,
            variables: {
                updateTemplateInstanceId: instanceId,
                data: {
                    renderSettings: {
                        bannerSettings,
                    },
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }
    }

    return {
        updateSettings,
    };
};

export type { IBannerInstanceService };
export default BannerInstanceService;
