import { gql } from '@apollo/client';

export const updateInstanceChangeObject = gql`
    mutation updateInstanceChangeObject($updateTemplateInstanceId: ID!, $data: TemplateInstanceUpdate!) {
        updateTemplateInstance(id: $updateTemplateInstanceId, data: $data) {
            name
            values {
                id
                path
                value
            }
            formatValues {
                formatId
                values {
                    id
                    path
                    value
                }
            }
        }
    }
`;
