import { selectActiveTemplate } from './select-active-template';
import { selectActiveCampaignId } from './select-active-campaign-id';
import { selectActiveTemplateId } from './select-active-template-id';
import { selectCurrentFormat } from './select-current-format';
import { selectTemplateInstance } from './select-template-instance';
import { selectTemplateInstanceId } from './select-template-instance-id';
import { selectActiveTemplateName } from './select-active-template-name';
import { selectActiveCampaignName } from './select-active-campaign-name';
import { selectActiveCampaignItem } from './select-active-campaign-item';
import { selectActiveCampaignItemId } from './select-active-campaign-item-id';
import { selectActiveCampaign } from './select-active-campaign';
import { selectCampaignItems } from './select-campaign-items';
import { selectTemplateInstanceFormatValues } from './select-template-instance-format-values';
import { selectTemplateInstanceFormatValuesById } from './select-template-instance-format-values-by-id';
import { selectIsFormatSpecificActive } from './select-is-format-specific-active';
import { selectActiveCampaignItemInstances } from './select-active-campaign-item-instances';

const EditorSelectors = {
    getActiveTemplate: selectActiveTemplate,
    getActiveTemplateId: selectActiveTemplateId,
    getActiveCampaignItemInstances: selectActiveCampaignItemInstances,
    getActiveCampaignName: selectActiveCampaignName,
    getActiveCampaign: selectActiveCampaign,
    getActiveTemplateName: selectActiveTemplateName,
    getActiveCampaignId: selectActiveCampaignId,
    getCurrentFormat: selectCurrentFormat,
    getTemplateInstance: selectTemplateInstance,
    getTemplateInstanceId: selectTemplateInstanceId,
    getTemplateInstanceFormatValues: selectTemplateInstanceFormatValues,
    getTemplateInstanceFormatValuesById: selectTemplateInstanceFormatValuesById,
    getIsFormatSpecificActive: selectIsFormatSpecificActive,
    getActiveCampaignItem: selectActiveCampaignItem,
    getActiveCampaignItemId: selectActiveCampaignItemId,
    getCampaignItems: selectCampaignItems,
};

export default EditorSelectors;
