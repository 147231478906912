import { selectTextPropertyById } from './select-text-property-by-id';
import { selectImagePropertyById } from './select-image-property-by-id';
import { selectChangedPropertyById } from './select-changed-property-by-id';
import { selectInstanceHistory } from './select-changed-history';
import { selectImageProperties } from './select-image-properties';

const InstanceSelector = {
    getTextPropertyById: selectTextPropertyById,
    getImagePropertyById: selectImagePropertyById,
    getImageProperties: selectImageProperties,
    getChangedPropertyById: selectChangedPropertyById,
    getInstanceHistory: selectInstanceHistory,
};

export default InstanceSelector;
