import React, { ReactElement } from 'react';
import CampaignCardItem from './campaign-card-item';
import useLocale from '../../hooks/use-locale';
import ListItem from '../../components/list/list-item';
import List from '../../components/list/list';
import CampaignContextOperation from '../../types/enums/campaign-context-operation';
import { CampaignListType } from '../../types/campaign';

type Props = {
    campaignList: CampaignListType;
    onClick(id: string): void;
    onContextOperation(operation: CampaignContextOperation, id: string): void;
};

export default function CampaignCardList(props: Props): ReactElement {
    const { campaignList, onClick, onContextOperation } = props;

    const { getText } = useLocale();

    const noCampaignsCreated = campaignList.length === 0;

    return !noCampaignsCreated ? (
        <List className='flex w-full flex-col p-0'>
            <ListItem className='flex flex-row gap-16 pb-0 pl-20 pr-48 pt-0 text-base text-companyMediumGrey'>
                <div className='basis-2/3 border-r pl-20 text-center'>{getText('widgets.dashboard.list.name')}</div>
                <div className='basis-2/6 border-r text-center'>{getText('widgets.dashboard.list.templateAmount')}</div>
                <div className='basis-2/6 border-r text-center'>{getText('widgets.dashboard.list.languages')}</div>
                <div className='basis-2/6 border-r text-center'>{getText('widgets.dashboard.list.lastEdit')}</div>
                <div className='basis-2/6 border-r text-center'>{getText('widgets.dashboard.list.createdAt')}</div>
                <div className='w-60 text-center' />
            </ListItem>
            {campaignList.map((p) => (
                <CampaignCardItem
                    key={p.id}
                    onOpenTemplate={(id) => onClick(id)}
                    onContextOperation={(op, id) => onContextOperation(op, id)}
                    campaign={{
                        id: p.id,
                        name: p.name,
                        templateCount: p.items?.length || 0,
                        languages: p.languages?.map((i) => i.name || '') || [],
                        createdAt: p.createdAt,
                        updatedAt: p.updatedAt,
                    }}
                />
            ))}
        </List>
    ) : (
        <p>{getText('widgets.dashboard.list.noCampaignWarning')}</p>
    );
}
