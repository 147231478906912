import { selectIsLooping } from './select-is-looping';
import { selectIsPlaying } from './select-is-playing';
import { selectDisplayObjectOutlines } from './select-display-object-outlines';
import { selectPlaytime } from './select-playtime';
import { selectSelectedObjectId } from './select-selected-object-id';
import { selectIsEnded } from './select-is-ended';
import { selectEditMode } from './select-editmode';

const CanvasSelectors = {
    isLooping: selectIsLooping,
    isPlaying: selectIsPlaying,
    isEnded: selectIsEnded,
    editMode: selectEditMode,
    displayObjectOutlines: selectDisplayObjectOutlines,
    playtime: selectPlaytime,
    getSelectedObjectId: selectSelectedObjectId,
};

export default CanvasSelectors;
