import React, { ReactElement } from 'react';
import DownloadInstance from '../control/download-instance';

export default function StillControls(): ReactElement {
    return (
        <div className='flex w-full flex-row justify-end pl-15 pr-15'>
            <DownloadInstance />
        </div>
    );
}
