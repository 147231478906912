import { AdvertisingProvider } from '../../types/advertising-provider';
import { createSlice } from '@reduxjs/toolkit';
import { ReduxAction } from '../../types/redux-action';
import { TemplateFormat } from '../../generated/gql/graphql';
import { SelectedInstanceFormats } from '../../types/selected-instance-formats';
import { OutputChannel } from '../../types/enums/template';
import { GeneratorFormat } from '../../types/generator';

export type GeneratorState = {
    selectedOutputChannel: OutputChannel;
    selectedCampaignId?: string;
    selectedLocals: string[];
    selectedInstanceIds: string[];
    selectedProviders: AdvertisingProvider[];
    selectedFormats: SelectedInstanceFormats[];
    generatorData: GeneratorFormat[];
};

const initialState: GeneratorState = {
    selectedOutputChannel: OutputChannel.Video,
    selectedLocals: [],
    selectedInstanceIds: [],
    selectedProviders: [],
    selectedFormats: [],
    generatorData: [],
};

const slice = createSlice({
    name: 'generator',
    initialState,
    reducers: {
        setSelectedOutputChannel: (state, action: ReduxAction<{ outputChannel: OutputChannel }>) => ({
            ...state,
            selectedOutputChannel: action.payload.outputChannel,
        }),
        setSelectedCampaignId: (state, action: ReduxAction<{ campaignId: string }>) => ({
            ...state,
            selectedCampaignId: action.payload.campaignId,
        }),
        setSelectedLocals: (state, action: ReduxAction<{ locals: string[] }>) => ({
            ...state,
            selectedLocals: action.payload.locals,
        }),
        setSelectedInstanceIds: (state, action: ReduxAction<{ instanceIds: string[] }>) => ({
            ...state,
            selectedInstanceIds: action.payload.instanceIds,
        }),
        setSelectedProviders: (state, action: ReduxAction<{ providers: AdvertisingProvider[] }>) => ({
            ...state,
            selectedProviders: action.payload.providers,
        }),
        setSelectedFormats: (
            state,
            action: ReduxAction<{ formats: { instanceId: string; formats: TemplateFormat[] }[] }>,
        ) => ({
            ...state,
            selectedFormats: action.payload.formats,
        }),
        setGeneratorData: (state, action: ReduxAction<{ data: GeneratorFormat[] }>) => ({
            ...state,
            generatorData: action.payload.data,
        }),
    },
});

export const GeneratorActions = {
    ...slice.actions,
};
export default slice.reducer;
