/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import TableCell from '../../components/table/table-cell';
import short from 'short-uuid';
import MultiCheckbox from '../../components/checkboxs/multi-checkbox';
import { GeneratorCampaignItem, GeneratorFormat, GeneratorTemplateFormat } from '../../types/generator';
import { CheckStatus } from '../../types/enums/check-status';

type Props = {
    format: GeneratorTemplateFormat;
    campaignItems: GeneratorCampaignItem[];
    checkMarkMatrix: GeneratorFormat[];
    updateCheckMarkMatrix(newArray: GeneratorFormat[]): void;
};

export default function NewGeneratorMatrixRow(props: Props): ReactElement {
    const { format, campaignItems, checkMarkMatrix, updateCheckMarkMatrix } = props;

    function getGeneratorEntryCheckStatus(columnId: string): CheckStatus {
        let isChecked = CheckStatus.UNCHECKED;

        checkMarkMatrix.forEach((entry) => {
            if (entry.formatId === format.id && entry.campaignItemId === columnId) {
                isChecked = entry.checked ? CheckStatus.CHECKED : CheckStatus.UNCHECKED;
            }
        });

        return isChecked;
    }

    function getGeneratorEntryDisabledStatus(columnId: string): boolean {
        let disabled = false;

        checkMarkMatrix.forEach((entry) => {
            if (entry.formatId === format.id && entry.campaignItemId === columnId) {
                disabled = entry.disabled;
            }
        });

        return disabled;
    }

    function updateGeneratorEntry(columnId: string): void {
        const currentCheckStatus = getGeneratorEntryCheckStatus(columnId);
        const updateValue = currentCheckStatus !== CheckStatus.CHECKED;

        const newCheckMarkMatrix = [] as GeneratorFormat[];

        checkMarkMatrix.forEach((entry) => {
            if (entry.formatId === format.id && entry.campaignItemId === columnId) {
                newCheckMarkMatrix.push({
                    ...entry,
                    checked: updateValue,
                });
            } else {
                newCheckMarkMatrix.push(entry);
            }
        });

        updateCheckMarkMatrix(newCheckMarkMatrix);
    }

    return (
        <div className='flex first:border-b-1 last:border-b-1'>
            <div className='flex w-320 justify-end border-r-1 px-20 py-15 text-right'>{`${format.name} (${format.width}x${format.height})`}</div>
            <div className='flex'>
                {campaignItems.map((item) => (
                    <TableCell className='flex h-full w-135 justify-center border-r-1' key={short.generate()}>
                        <MultiCheckbox
                            status={getGeneratorEntryCheckStatus(item.id)}
                            disabled={getGeneratorEntryDisabledStatus(item.id)}
                            onClick={() => updateGeneratorEntry(item.id)}
                        />
                    </TableCell>
                ))}
            </div>
        </div>
    );
}
