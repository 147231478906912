import React, { ChangeEvent, forwardRef, SyntheticEvent } from 'react';
import MUITextField from '@mui/material/TextField';

type Props = {
    value: string;
    type?: 'text' | 'email';
    label?: string | undefined;
    disabled?: boolean | undefined;
    focused?: boolean | undefined;
    multiline?: boolean | undefined;
    placeholder?: string | undefined;
    onChange(e: ChangeEvent<HTMLInputElement>): void;
    onFocus?(): void;
    onSelect?(e: SyntheticEvent<HTMLInputElement, Event>): void;
    className?: string | undefined;
};

const TextField = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { label, type, value, disabled, focused, placeholder, className, multiline, onChange, onFocus, onSelect } =
        props;

    return (
        <MUITextField
            ref={ref}
            multiline={multiline}
            type={type}
            className={className}
            focused={focused}
            disabled={disabled}
            label={label}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={onFocus}
            onSelect={onSelect}
        />
    );
});

export default TextField;

TextField.defaultProps = {
    type: 'text',
    label: undefined,
    disabled: false,
    focused: false,
    multiline: false,
    onFocus: undefined,
    onSelect: undefined,
    placeholder: undefined,
    className: undefined,
};
