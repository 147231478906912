import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Accordion from '../../components/accordions/accordion';
import AccordionDetails from '../../components/accordions/accordion-details';
import FilterChip from '../../components/chips/filter-chip';
import { Filter } from '../../types/filter';
import BackgroundPanelHeader from './background-panel-header';
import SizeFilter from '../filter/size-filter';
import ColorFilter from '../filter/color-filter';
import useLocale from '../../hooks/use-locale';
import { useStoreValue } from '../../hooks/use-store-value';
import { TemplateObject } from '../../generated/gql/graphql';
import TemplateSelector from '../../stores/selectors/template';
import CanvasSelectors from '../../stores/selectors/canvas';
import { EditMode } from '../../types/enums/edit-mode';
import { useServices } from '../../hooks/use-services';
import BackgroundAssets from './background-assets';

export default function BackgroundSelect(): ReactElement {
    const [selectedSizeFilters, setSelectedSizeFilters] = useState<Filter[]>([]);
    const [selectedColorFilters, setSelectedColorFilters] = useState<Filter[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const imageProps = useStoreValue<TemplateObject[]>(TemplateSelector.getImageProperties());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const currentEditMode = useStoreValue<EditMode>(CanvasSelectors.editMode());

    const expanded = imageProps?.some((item) => item.id === selectedObjectId) || false;

    const assetGroup = useStoreValue<string>(TemplateSelector.getImageAssetGroupById(selectedObjectId));
    const isBackgroundAsset = assetGroup === 'Background' || assetGroup === undefined;

    const { getText } = useLocale();
    const { canvas } = useServices();

    const scrollRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded, currentEditMode, selectedObjectId]);

    function onExpand(expand: boolean): void {
        canvas.setEditMode(EditMode.NOT_EDITING);
        setIsExpanded(expand);
    }

    const handleDeleteAll = (): void => {
        setSelectedSizeFilters([]);
        setSelectedColorFilters([]);
    };

    function handleAddSizeFilter(filter: Filter): void {
        if (!selectedSizeFilters.some((f) => f.id === filter.id)) setSelectedSizeFilters((prev) => [...prev, filter]);
    }

    function handleDeleteSizeFilter(filterId: string): void {
        const foundFilter = selectedSizeFilters.find((f) => f.id === filterId);
        if (foundFilter) setSelectedSizeFilters(selectedSizeFilters.filter((f) => f.id !== foundFilter.id));
    }

    function handleAddColorFilter(filter: Filter): void {
        if (!selectedColorFilters.some((f) => f.id === filter.id)) setSelectedColorFilters((prev) => [...prev, filter]);
    }

    function handleDeleteColorFilter(filterId: string): void {
        const foundFilter = selectedColorFilters.find((f) => f.id === filterId);
        if (foundFilter) setSelectedColorFilters(selectedColorFilters.filter((f) => f.id !== foundFilter.id));
    }

    function handleSelectAllSizeFilter(filters: Filter[]): void {
        setSelectedSizeFilters(filters);
    }

    function handleSelectAllColorFilters(filters: Filter[]): void {
        setSelectedColorFilters(filters);
    }

    function handleSearchInput(searchInput: string): void {
        setSearchTerm(searchInput);
    }

    const showBackgroundFiltersDebug = false;

    return (
        <Accordion
            label={getText('widgets.backgroundSelect.label')}
            summaryChildren={<BackgroundPanelHeader onSearch={(input) => handleSearchInput(input)} />}
            expanded={isExpanded && currentEditMode === EditMode.NOT_EDITING && isBackgroundAsset}
            onExpand={(e) => onExpand(e)}
        >
            <AccordionDetails ref={scrollRef}>
                {showBackgroundFiltersDebug && (
                    <div className='mb-10 flex flex-wrap gap-10'>
                        <SizeFilter
                            activeFilterIds={selectedSizeFilters.map((filter) => filter.id)}
                            onAdd={(filter) => handleAddSizeFilter(filter)}
                            onDelete={(id) => handleDeleteSizeFilter(id)}
                            onDeleteAll={() => {
                                setSelectedSizeFilters([]);
                            }}
                            onAddAll={(filters) => {
                                handleSelectAllSizeFilter(filters);
                            }}
                        />
                        <ColorFilter
                            activeFilterIds={selectedColorFilters.map((filter) => filter.id)}
                            onAdd={(filter) => handleAddColorFilter(filter)}
                            onDelete={(id) => handleDeleteColorFilter(id)}
                            onDeleteAll={() => {
                                setSelectedColorFilters([]);
                            }}
                            onAddAll={(filters) => {
                                handleSelectAllColorFilters(filters);
                            }}
                        />
                    </div>
                )}

                <div className='flex flex-wrap gap-10'>
                    {selectedSizeFilters.map((filter) => (
                        <FilterChip
                            key={filter.id}
                            label={filter.name}
                            id={filter.id}
                            count={filter.count}
                            onDelete={(id) => handleDeleteSizeFilter(id)}
                        />
                    ))}
                    {selectedColorFilters.map((filter) => (
                        <FilterChip
                            key={filter.id}
                            label={filter.name}
                            id={filter.id}
                            count={filter.count}
                            onDelete={(id) => handleDeleteColorFilter(id)}
                        />
                    ))}
                    {selectedSizeFilters.length > 0 || selectedColorFilters.length > 0 ? (
                        <button type='button' className='text-base text-companyMediumGrey' onClick={handleDeleteAll}>
                            {getText('widgets.backgroundSelect.deleteAllFilter')}
                        </button>
                    ) : undefined}
                </div>
                <BackgroundAssets
                    filterIds={[
                        ...selectedSizeFilters.map((filter) => filter.id),
                        ...selectedColorFilters.map((filter) => filter.id),
                    ]}
                    searchTerm={searchTerm}
                    scrollRef={scrollRef}
                />
            </AccordionDetails>
        </Accordion>
    );
}
