export enum Event {
    DEV = 'dev',

    // Campaign
    CAMPAIGN_LOADED_TEMPLATE = 'campaign_loaded_template',

    // Canvas
    CANVAS_INITIALIZED = 'canvas_initialized',
    CANVAS_SELECTED_OBJECT = 'canvas_selected_object',
    CANVAS_FORCE_REDRAW = 'canvas_force_redraw',
    CANVAS_RESIZE = 'canvas_resize',
    SET_PLAY_TIME = 'set_play_time',

    // Instance
    CAMPAIGN_SAVING_INSTANCE = 'campaign_saving_instance',

    // Assets
    BACKGROUND_ASSET_UPLOADED = 'background_asset_uploaded',
    PACKSHOT_ASSET_UPLOADED = 'packshot_asset_uploaded',
}
