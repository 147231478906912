import { useState } from 'react';
import { useServices } from './use-services';
import { AssetDeleteStatus } from '../types/enums/asset-delete-status';

interface IAssetDelete {
    status: AssetDeleteStatus;
    deleteAsset: (id: string) => Promise<void>;
}

export default function useAssetDelete(): IAssetDelete {
    const [status, setStatus] = useState<AssetDeleteStatus>(AssetDeleteStatus.NONE);

    const { asset } = useServices();

    async function deleteAsset(id: string): Promise<void> {
        try {
            setStatus(AssetDeleteStatus.LOADING);
            await asset.deleteAsset(id);
            setStatus(AssetDeleteStatus.SUCCESS);
        } catch {
            setStatus(AssetDeleteStatus.ERROR);
        }
    }

    return {
        status,
        deleteAsset,
    };
}
