import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icon';

export default function StillIcon(props: IconProps): ReactElement {
    const { width, height, className, color } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M19.2 4.08008H4.80002C3.47456 4.08008 2.40002 5.15462 2.40002 6.48008V17.2801C2.40002 18.6055 3.47456 19.6801 4.80002 19.6801H19.2C20.5255 19.6801 21.6 18.6055 21.6 17.2801V6.48008C21.6 5.15462 20.5255 4.08008 19.2 4.08008ZM19.8 17.2801C19.8 17.6109 19.5309 17.8801 19.2 17.8801H4.80002C4.46918 17.8801 4.20002 17.6109 4.20002 17.2801V6.48008C4.20002 6.14924 4.46918 5.88008 4.80002 5.88008H19.2C19.5309 5.88008 19.8 6.14924 19.8 6.48008V17.2801ZM17.6353 12.1153C17.8688 12.3489 18 12.6656 18 12.9958V15.64C18 15.883 17.803 16.08 17.56 16.08H6.44C6.197 16.08 6.00002 15.883 6.00002 15.64V14.7958C6.00002 14.4656 6.13124 14.1489 6.36476 13.9153L7.78892 12.4912C7.96076 12.3193 8.23935 12.3193 8.41118 12.4912L9.28892 13.3689C9.46076 13.5408 9.73935 13.5408 9.91118 13.3689L14.0889 9.19118C14.2608 9.01934 14.5393 9.01934 14.7112 9.19118L17.6353 12.1153H17.6353ZM7.20002 9.18008C7.20002 8.35166 7.8716 7.68008 8.70002 7.68008C9.52844 7.68008 10.2 8.35166 10.2 9.18008C10.2 10.0085 9.52844 10.6801 8.70002 10.6801C7.8716 10.6801 7.20002 10.0085 7.20002 9.18008Z'
                fill={color}
            />
        </svg>
    );
}
