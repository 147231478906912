import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
};

export default function MainContainer(props: Props): ReactElement {
    const { children } = props;
    return <main className='flex min-h-screen min-w-[100vw] bg-companyLightGrey'>{children}</main>;
}

MainContainer.defaultProps = {
    children: undefined,
};
