import { CampaignItem } from '../generated/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { OutputChannel } from '../types/enums/template';
import Routes from '../types/enums/routes';

interface IRouterNavigation {
    navigateToEditor(campaignId: string, campaignItem: CampaignItem): void;
}

export default function useRouterNavigation(): IRouterNavigation {
    const navigate = useNavigate();

    function navigateToEditor(campaignId: string, campaignItem: CampaignItem): void {
        if (campaignItem.template?.outputChannels?.some((c) => c === OutputChannel.Banner)) {
            navigate({
                pathname: Routes.BANNER,
                search:
                    `campaignId=${campaignId}&campaignItemId=${campaignItem.id}` +
                    `&templateId=${campaignItem.templateId}`,
            });
        }
        if (campaignItem.template?.outputChannels?.some((c) => c === OutputChannel.Video)) {
            navigate({
                pathname: Routes.VIDEO,
                search:
                    `campaignId=${campaignId}&campaignItemId=${campaignItem.id}` +
                    `&templateId=${campaignItem.templateId}`,
            });
        }
        if (campaignItem.template?.outputChannels?.some((c) => c === OutputChannel.Still)) {
            navigate({
                pathname: Routes.STILL,
                search:
                    `campaignId=${campaignId}&campaignItemId=${campaignItem.id}` +
                    `&templateId=${campaignItem.templateId}`,
            });
        }
    }

    return {
        navigateToEditor,
    };
}
