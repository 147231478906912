import { graphql } from '../../../generated/gql';

export const subscribeRenderingStatusChanged = graphql(`
    subscription RenderingStatusChanged($renderingStatusChangedId: ID) {
        renderingStatusChanged(id: $renderingStatusChangedId) {
            id
            status
            result {
                ... on RenderingResult {
                    downloadUrl
                }
                ... on RenderingError {
                    message
                }
            }
        }
    }
`);
