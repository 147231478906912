import React, { ReactElement, ReactNode } from 'react';
import { List as MUIList } from '@mui/material';

type Props = {
    className?: string;
    children?: ReactNode;
};

export default function List(props: Props): ReactElement {
    const { className, children } = props;

    return <MUIList className={className}>{children}</MUIList>;
}

List.defaultProps = {
    className: '',
    children: undefined,
};
