function mmToPx(mm: number): number {
    const dpi = 300;
    const mmPerInch = 25.4;
    return (mm / mmPerInch) * dpi;
}

function pxToMm(px: number): number {
    const dpi = 300;
    const mmPerInch = 25.4;
    return (px / dpi) * mmPerInch;
}

const UnitConvert = {
    mmToPx,
    pxToMm,
};

export default UnitConvert;
