/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import EditorSelectors from '../../stores/selectors/editor';
import { AssetGroup, Template, TemplateImage, TemplateObject, TemplateObjectChange } from '../../generated/gql/graphql';
import TemplateUtils from '../../utils/template';
import { AssetData } from '../../types/assets.d';
import AssetTile from '../asset/asset-tile';
import TemplateSelector from '../../stores/selectors/template';
import InstanceSelector from '../../stores/selectors/instance';
import { asyncInvoke } from '../../utils/function';
import short from 'short-uuid';
import { Event } from '../../types/enums/events';

type Props = {
    searchTerm?: string;
    filterIds: string[];
    scrollRef?: RefObject<HTMLDivElement | undefined>;
};

export default function BackgroundAssets(props: Props): ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filterIds, searchTerm, scrollRef } = props;
    const baseTemplate = useStoreValue<Template | undefined>(EditorSelectors.getActiveTemplate());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const isCanvasPlaying = useStoreValue<boolean>(CanvasSelectors.isPlaying());

    const object = useStoreValue<TemplateObject>(TemplateSelector.getImagePropertyById(selectedObjectId));
    const currentInstance = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getImagePropertyById(selectedObjectId),
    );

    const { logger, instance, asset, eventBus } = useServices();

    const content = (object?.content as TemplateImage) || undefined;
    const optionsValue = content?.options ? content.options[0].src : undefined;
    let value = content?.src || optionsValue || '';

    if (currentInstance) {
        value = currentInstance.value;
    }

    const assetLimit = 50;
    const [assets, setAssets] = useState<AssetData>({
        items: [],
        limit: 0,
        offset: 0,
        page: 1,
        totalCount: 0,
    });
    const assetsRef = useRef<AssetData>(assets);

    async function loadPackshotAssets(page?: number, initial?: boolean): Promise<void> {
        const loadedAssets = await asset.getAssetsByQuery(
            { group: AssetGroup.Background, search: searchTerm },
            {
                limit: assetLimit,
                offset: 0,
                page: page || 1,
            },
        );

        setAssets((prev) => {
            const combinedItems = [...(prev?.items || []), ...(loadedAssets.items || [])];

            const newAssets = {
                items: initial
                    ? [...(loadedAssets.items || [])]
                    : combinedItems.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i),
                limit: loadedAssets.limit || 0,
                offset: loadedAssets.offset || 0,
                page: loadedAssets.page || 1,
                totalCount: loadedAssets.totalCount || 0,
            };
            assetsRef.current = newAssets;

            return newAssets;
        });
    }

    useEffect(asyncInvoke(loadPackshotAssets), []);

    useEffect(() => {
        async function search(): Promise<void> {
            await loadPackshotAssets(1, true);
        }

        search();
    }, [searchTerm]);

    useEffect(() => {
        eventBus.onEvent(Event.PACKSHOT_ASSET_UPLOADED, asyncInvoke(loadPackshotAssets));
    }, []);

    function onScroll(): void {
        if (scrollRef && scrollRef.current) {
            const scrollable = scrollRef.current;
            if (!scrollable) {
                return;
            }

            if (
                scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight &&
                assetsRef.current.items.length < assetsRef.current.totalCount
            ) {
                loadPackshotAssets(assetsRef.current.page + 1);
            }
        }
    }

    useEffect(() => {
        const ref = scrollRef?.current;
        if (ref) {
            ref.addEventListener('scroll', onScroll);
        }

        return () => {
            if (ref) {
                ref.removeEventListener('scroll', onScroll);
            }
        };
    }, [scrollRef]);

    async function onClickElement(url: string): Promise<void> {
        if (!baseTemplate) {
            logger.info('No base template found');
            return;
        }

        const targetObject = TemplateUtils.getObjectById(selectedObjectId, baseTemplate);
        if (!targetObject) {
            logger.info('No object found');
            return;
        }

        if (assets.items) await instance.background.setBackground(selectedObjectId, url);
    }

    function onDelete(id: string): void {
        setAssets((prev) => {
            const newAssets = {
                ...prev,
                items: prev.items.filter((v) => v.id !== id),
            };
            assetsRef.current = newAssets;
            return newAssets;
        });
    }

    return (
        <div className='mt-30 grid grid-cols-6 gap-10'>
            {assets.items.map((v) => (
                <AssetTile
                    id={v.id}
                    key={short.generate()}
                    url={v.thumbnailUrl}
                    dark={!v.bright || true}
                    onClick={() => onClickElement(v.url)}
                    focused={v.url === value}
                    disabled={isCanvasPlaying}
                    deletable={v.deletable || false}
                    onDelete={() => onDelete(v.id)}
                />
            ))}
        </div>
    );
}

BackgroundAssets.defaultProps = {
    searchTerm: undefined,
    scrollRef: undefined,
};
