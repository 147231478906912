import { ICommonInstanceService } from './common';
import { TemplateObjectBackground } from '@metaphore/magnolia-rendering/dist/templates/TemplateObjectBackground';
import { FontFamilyChange } from '../../types/font';
import { TextAlign } from '@metaphore/magnolia-rendering';

interface ITextInstanceService {
    setText(id: string, text: string): Promise<void>;

    setTextAlign(id: string, textAlign: TextAlign): Promise<void>;

    setFontFamily(id: string, fontFamily: FontFamilyChange): Promise<void>;

    setColor(id: string, path: string, color: string): Promise<void>;

    setBackgroundColor(id: string, background: TemplateObjectBackground): Promise<void>;

    setFontSizeFactor(id: string, value: number): Promise<void>;
}

const TextInstanceService = (common: ICommonInstanceService): ITextInstanceService => {
    async function setText(id: string, text: string): Promise<void> {
        await common.setObjectChange(id, 'content.text', text);
    }

    async function setTextAlign(id: string, textAlign: TextAlign): Promise<void> {
        await common.setObjectChange(id, 'content.textSettings.textAlign', textAlign);
    }

    async function setFontFamily(id: string, value: FontFamilyChange): Promise<void> {
        await common.setObjectChange(id, 'content.textSettings.fontFamily', value.family);
        await common.setObjectChange(id, 'content.textSettings.fontWeight', value.weight);
        await common.setObjectChange(id, 'content.textSettings.fontStyle', value.style);
    }

    async function setColor(id: string, path: string, color: string): Promise<void> {
        await common.setObjectChange(id, path, color);
    }

    async function setBackgroundColor(id: string, background: TemplateObjectBackground): Promise<void> {
        await common.setObjectChange(id, 'content.background', { ...background });
    }

    async function setFontSizeFactor(id: string, value: number): Promise<void> {
        await common.setObjectChange(id, 'content.textSettings.fontSizeFactor', value);
    }

    return {
        setText,
        setTextAlign,
        setFontFamily,
        setColor,
        setBackgroundColor,
        setFontSizeFactor,
    };
};

export type { ITextInstanceService };
export default TextInstanceService;
