import React, { ReactElement } from 'react';
import EditorContentContainer from '../../components/layout/editor/editor-content-container';
import EditorLeftContent from '../../components/layout/editor/editor-left-content';
import EditorRightContent from '../../components/layout/editor/editor-right-content';
import EditorToolbar from '../../widgets/toolbar/editor-toolbar';
import VideoCanvas from '../../widgets/video-canvas/video-canvas';
import BackgroundSelect from '../../widgets/background/background-select';
import TextInspector from '../../widgets/text/text-inspector';
import useEditorParams from '../../hooks/use-editor-params';
import { CampaignItemType } from '../../types/enums/campaign-item-type';
import PackshotSelect from '../../widgets/packshots/packshot-select';
import ImageEditPalette from '../../widgets/image-editing/image-edit-palette';

export default function VideoPage(): ReactElement {
    useEditorParams();

    return (
        <>
            <EditorToolbar campaignItemType={CampaignItemType.VIDEO} />
            <EditorContentContainer>
                <EditorLeftContent>
                    <VideoCanvas />
                </EditorLeftContent>
                <EditorRightContent>
                    <ImageEditPalette />
                    <BackgroundSelect />
                    <PackshotSelect />
                    <TextInspector />
                </EditorRightContent>
            </EditorContentContainer>
        </>
    );
}
