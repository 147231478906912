import React, { ReactElement } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { VolumeOffOutlined } from '@mui/icons-material';
import CanvasPlayToggle from '../canvas/canvas-play-toggle';
import CanvasLoopToggle from '../canvas/canvas-loop-toggle';
import VideoProgressBar from '../canvas/video-progress-bar';
import DownloadInstance from '../control/download-instance';
import CanvasForwardToggle from '../canvas/canvas-forward-toggle';
import CanvasRewindToggle from '../canvas/canvas-rewind-toggle';

export default function VideoControls(): ReactElement {
    return (
        <div className='flex w-full flex-col'>
            <VideoProgressBar />
            <div className='flex pb-16'>
                <div className='flex w-1/3 justify-center space-x-2'>
                    <IconButton disabled>
                        <VolumeOffOutlined />
                    </IconButton>
                    <CanvasLoopToggle />
                </div>
                <div className='flex w-1/3 justify-center space-x-2'>
                    <CanvasRewindToggle />
                    <CanvasPlayToggle />
                    <CanvasForwardToggle />
                </div>
                <div className='ml-auto pl-15 pr-15'>
                    <DownloadInstance />
                </div>
            </div>
        </div>
    );
}
