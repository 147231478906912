import React, { ReactElement, useState } from 'react';
import Drawer from '../drawer/drawer';
import List from '../list/list';
import ListItem from '../list/list-item';
import ListItemButton from '../list/list-item-button';
import ListItemText from '../list/list-item-text';
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    DownloadOutlined,
    FolderOutlined,
    ImageOutlined,
    SmartDisplayOutlined,
} from '@mui/icons-material';
import ListItemIcon from '../list/list-item-icon';
import Class from '../../utils/classes';
import useLocale from '../../hooks/use-locale';
import BannerIcon from '../icons/banner-icon';
import { Routes } from '../../types/enums/routes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStoreValue } from '../../hooks/use-store-value';
import { Template } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import { RenderingExportTarget } from '../../types/enums/rendering-export-targets';
import { useServices } from '../../hooks/use-services';

export default function MainSidebar(): ReactElement {
    const [isOpen, setOpen] = useState<boolean>(false);
    const currentLocation = window.location.pathname;
    const [searchParams] = useSearchParams();
    const currentRoute = useLocation();

    const { editor } = useServices();

    const { getText } = useLocale();
    const navigate = useNavigate();

    const activeTemplate = useStoreValue<Template | undefined>(EditorSelectors.getActiveTemplate());
    const activeCampaignId = useStoreValue<string | undefined>(EditorSelectors.getActiveCampaignId());
    const exportTargets = activeTemplate?.renderingExportTargets;

    const isStillsTemplate = exportTargets?.includes(RenderingExportTarget.Png);
    const isBannerTemplate = exportTargets?.includes(RenderingExportTarget.GoogleAd);
    const isVideoTemplate = exportTargets?.includes(RenderingExportTarget.Mp4);

    const routeListItems = [
        {
            label: getText('components.navigation.campaigns'),
            icon: <FolderOutlined />,
            route: Routes.CAMPAIGNS,
            disabled: false,
        },
        {
            label: getText('components.navigation.video'),
            icon: <SmartDisplayOutlined />,
            route: Routes.VIDEO,
            disabled: !isVideoTemplate,
        },
        {
            label: getText('components.navigation.still'),
            icon: <ImageOutlined />,
            route: Routes.STILL,
            disabled: !isStillsTemplate,
        },
        {
            label: getText('components.navigation.banner'),
            icon: <BannerIcon width={25} height={25} />,
            route: Routes.BANNER,
            disabled: !isBannerTemplate,
        },
        {
            label: getText('components.navigation.download'),
            icon: <DownloadOutlined />,
            route: Routes.GENERATOR,
            disabled: !activeCampaignId,
        },
    ];

    function onClick(route: string): void {
        let fromParameter = currentRoute.pathname.replaceAll('/', '');

        if (fromParameter === '') {
            fromParameter = 'campaigns';
        }

        if (route === Routes.CAMPAIGNS) {
            editor.reset();
        }

        searchParams.set('from', fromParameter);

        navigate(`${route}?${searchParams}`);
    }

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            isOpen={isOpen}
            paperClassName={Class.classNames('fixed transition-all shadow border', isOpen ? 'w-100' : 'w-60')}
            className={Class.classNames('sticky z-50 overflow-hidden transition-all', isOpen ? 'w-100' : 'w-60')}
        >
            <List>
                <ListItem disablePadding className='border-b'>
                    <ListItemButton onClick={() => setOpen(!isOpen)} className='min-h-100 justify-center'>
                        {isOpen ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
                    </ListItemButton>
                </ListItem>
                {routeListItems.map((element) => (
                    <ListItem key={element.label} disablePadding className='border-b'>
                        <ListItemButton
                            onClick={() => onClick(element.route)}
                            className={Class.classNames(
                                'relative flex min-h-100 flex-col items-center justify-center transition-all',
                                isOpen ? 'w-100' : 'w-0',
                            )}
                            disabled={element.disabled}
                        >
                            <ListItemIcon
                                className={Class.classNames(
                                    currentLocation === element.route
                                        ? 'fill-company [&>svg]:fill-company'
                                        : 'fill-companyDarkGrey [&>svg]:fill-companyDarkGrey',
                                )}
                            >
                                {element.icon}
                            </ListItemIcon>
                            <ListItemText
                                className={Class.classNames(
                                    'flex-none overflow-hidden text-center text-lg transition-all',
                                    isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0',
                                    currentLocation === element.route ? 'text-company' : 'text-companyDarkGrey',
                                )}
                                spanClassName='pl-3'
                            >
                                {element.label}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
}
