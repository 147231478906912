import React, { ReactElement, useRef, useState } from 'react';
import { AddOutlined, ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import IconButton from '../buttons/icon-button';
import HorizontalSliderSelectorItem from './horizontal-slider-selector-item';
import Slider from 'react-slick';

type Props = {
    selectedIndex: number;
    options: string[];
    onClickItem: (index: number) => void;
    onAddOption?: () => void;
};

export default function HorizontalSliderSelector(props: Props): ReactElement {
    const { selectedIndex, options, onAddOption, onClickItem } = props;
    const sliderRef = useRef<Slider>(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const slidesToShow = options.length === 1 ? 1 : 2;
    const maxSlideIndex = options.length - slidesToShow;

    function onPrev(): void {
        sliderRef.current?.slickPrev();
    }

    function onNext(): void {
        sliderRef.current?.slickNext();
    }

    return (
        <div className='flex flex-row gap-10 bg-white p-10'>
            <IconButton
                className='hidden max-h-34 max-w-34 rounded-full border border-1 border-solid border-companyGrey'
                disabled
                onClick={onAddOption}
            >
                <AddOutlined />
            </IconButton>
            <IconButton className='max-h-34 max-w-34' onClick={() => onPrev()} disabled={currentIndex === 0}>
                <ChevronLeftRounded />
            </IconButton>
            <div className='flex flex-row items-center'>
                {options.length === 0 ? (
                    <p className='select-none'>...</p>
                ) : (
                    <Slider
                        ref={sliderRef}
                        className={`grid h-full  ${options.length > 1 ? 'w-150' : 'w-72'} items-center`}
                        slidesToShow={slidesToShow}
                        slidesToScroll={slidesToShow}
                        swipeToSlide={false}
                        arrows={false}
                        adaptiveHeight
                        infinite={false}
                        swipe={false}
                        pauseOnFocus={false}
                        pauseOnHover={false}
                        beforeChange={(oldIndex: number, newIndex: number) => {
                            setCurrentIndex(newIndex);
                        }}
                    >
                        {options.map((option, index) => (
                            <HorizontalSliderSelectorItem
                                index={index}
                                key={`${option}-${index.toString()}`}
                                selectedIndex={selectedIndex}
                                onClick={onClickItem}
                                option={option}
                            />
                        ))}
                    </Slider>
                )}
            </div>
            <IconButton
                className='max-h-34 max-w-34'
                onClick={() => onNext()}
                disabled={currentIndex === maxSlideIndex || maxSlideIndex === 0}
            >
                <ChevronRightRounded />
            </IconButton>
        </div>
    );
}

HorizontalSliderSelector.defaultProps = {
    onAddOption: undefined,
};
