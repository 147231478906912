import React, { ReactElement } from 'react';
import MuiSwitch from '@mui/material/Switch';

type Props = {
    label?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

export default function Switch(props: Props): ReactElement {
    const { label, checked, onChange } = props;

    return (
        <div className='flex'>
            {label && <p className='my-auto whitespace-nowrap text-base'>{label}</p>}
            <MuiSwitch
                value={checked}
                checked={checked}
                onChange={(event) => onChange(event.target.checked)}
                sx={{
                    '.MuiSwitch-thumb': {
                        color: '#FAFAFA',
                    },
                    '.Mui-checked+.MuiSwitch-track': {
                        opacity: '1 !important',
                    },
                }}
            />
        </div>
    );
}

Switch.defaultProps = {
    label: undefined,
};
