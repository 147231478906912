import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignItem } from '../generated/gql/graphql';
import { useServices } from '../hooks/use-services';
import { CampaignItemListItem } from '../types/campaign';
import CampaignItemList from '../widgets/campaign/campaign-item-list';
import { asyncInvoke } from '../utils/function';
import DeleteCampaignItemForm from '../widgets/forms/delete-campaign-item/form';
import useNotification from '../hooks/use-notification';
import NewCampaignItemForm from '../widgets/forms/new-campaign-item/form';
import CampaignHeader from '../widgets/campaign/campaign-header';
import RenameCampaignItemForm from '../widgets/forms/rename-campaign-item/form';
import CampaignItemContextOperation from '../types/enums/campaign-item-context-operation';
import PageLayoutSkeleton from '../components/skeleton/page-layout-skeleton';
import useLocale from '../hooks/use-locale';
import Routes from '../types/enums/routes';
import DuplicateCampaignItemForm from '../widgets/forms/duplicate-campaign-item/form';
import FormatSpecificCount from '../utils/format-specific-count';

export default function CampaignPage(): ReactElement {
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);

    const [selectedCampaignListItem, setSelectedCampaignListItem] = useState<CampaignItem>();

    const [listItems, setListItems] = useState<CampaignItemListItem[]>([]);
    const [items, setItems] = useState<CampaignItem[]>([]);

    const { campaign } = useServices();
    const { getText } = useLocale();
    const notification = useNotification();
    const navigate = useNavigate();

    const loadCampaignItems = useCallback(async () => {
        if (!id) {
            return;
        }

        setIsLoading(true);

        const result = await campaign.getCampaignItemsById(id);

        const loadedItems: CampaignItemListItem[] = [];

        if (!result) {
            return;
        }

        result?.forEach((item) => {
            loadedItems.push({
                id: item.id,
                templateName: item.template?.name || '',
                templateId: item.template?.id || '',
                name: item.name || '',
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                thumbnailUrl: item.instances?.[0]?.thumbnailUrl || '',
                formatSpecificCount: FormatSpecificCount.countByCampaignItemInstances(item.instances || []),
            });
        });

        setListItems(loadedItems);
        setItems(result || []);

        setIsLoading(false);
    }, [id, campaign]);

    useEffect(asyncInvoke(loadCampaignItems), []);

    async function handleAddClick(name: string, templateId: string): Promise<void> {
        if (!id) {
            return;
        }

        const result = await campaign.getById(id);
        const campaignItems = result.items || [];

        // @ts-ignore
        campaignItems.push({
            name,
            templateId,
        });

        await campaign.updateCampaignItems(
            id,
            campaignItems.map((item) => ({
                id: item.id,
                name: item.name,
                templateId: item.templateId,
            })),
        );

        await loadCampaignItems();
        setShowDialog(false);
    }

    function handleContextOperation(type: CampaignItemContextOperation, item: CampaignItem): void {
        setSelectedCampaignListItem(item);

        switch (type) {
            case CampaignItemContextOperation.Delete:
                setShowDeleteDialog(true);
                break;
            case CampaignItemContextOperation.Rename:
                setShowRenameDialog(true);
                break;
            case CampaignItemContextOperation.Duplicate:
                setShowDuplicateDialog(true);
                break;
            default:
                console.error(`Unknown context operation: ${type}`);
        }
    }

    async function handleDeleteItem(): Promise<void> {
        if (!id) {
            return;
        }

        if (!selectedCampaignListItem) {
            return;
        }

        const result = await campaign.getById(id);
        const campaignItems = result.items || [];

        const filteredItems = campaignItems.filter((i) => i.id !== selectedCampaignListItem.id);

        try {
            await campaign.updateCampaignItems(
                id,
                filteredItems.map((item) => ({
                    id: item.id,
                    name: item.name,
                    templateId: item.templateId,
                })),
            );

            await loadCampaignItems();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('widgets.campaigns.delete.error'));
        }

        setSelectedCampaignListItem(undefined);
        setShowDeleteDialog(false);
    }

    async function handleRenameItem(name: string): Promise<void> {
        if (!id || !selectedCampaignListItem) {
            return;
        }

        const updatedItems = items.map((item) => {
            if (item.id === selectedCampaignListItem.id) {
                return {
                    ...item,
                    name,
                };
            }

            return item;
        });

        try {
            await campaign.updateCampaignItems(
                id,
                updatedItems.map((item) => ({
                    id: item.id,
                    name: item.name,
                    templateId: item.templateId,
                })),
            );

            await loadCampaignItems();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('widgets.campaigns.rename.error'));
        }

        setSelectedCampaignListItem(undefined);
        setShowRenameDialog(false);
    }

    async function handleDuplicateItem(name: string): Promise<void> {
        if (!id || !selectedCampaignListItem) {
            return;
        }

        try {
            await campaign.duplicateCampaignItem(selectedCampaignListItem.id, name);

            await loadCampaignItems();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('widgets.campaigns.duplicate.error'));
        }

        setSelectedCampaignListItem(undefined);
        setShowDuplicateDialog(false);
    }

    if (!id) {
        navigate(Routes.CAMPAIGNS);
        return <PageLayoutSkeleton />;
    }

    if (isLoading) {
        return <PageLayoutSkeleton />;
    }

    return (
        <>
            <CampaignHeader campaignId={id} onClickCreateCampaign={() => setShowDialog(true)} />

            <CampaignItemList
                campaignId={id}
                listItems={listItems}
                items={items}
                onContextOperation={(op, v) => handleContextOperation(op, v)}
            />

            <NewCampaignItemForm
                open={showDialog}
                onClose={() => setShowDialog(false)}
                onSubmit={(name, templateId) => handleAddClick(name, templateId)}
            />

            <DeleteCampaignItemForm
                open={showDeleteDialog}
                onSubmit={() => handleDeleteItem()}
                onClose={() => {
                    setSelectedCampaignListItem(undefined);
                    setShowDeleteDialog(false);
                }}
            />

            <RenameCampaignItemForm
                open={showRenameDialog}
                input={selectedCampaignListItem?.name || ''}
                onClose={() => {
                    setSelectedCampaignListItem(undefined);
                    setShowRenameDialog(false);
                }}
                onSubmit={(name) => handleRenameItem(name)}
            />

            <DuplicateCampaignItemForm
                open={showDuplicateDialog}
                input={`${selectedCampaignListItem?.name} (copy)` || ''}
                onClose={() => {
                    setSelectedCampaignListItem(undefined);
                    setShowDuplicateDialog(false);
                }}
                onSubmit={(name) => handleDuplicateItem(name)}
            />
        </>
    );
}
