import { createSlice } from '@reduxjs/toolkit';
import { ReduxAction } from '../../types/redux-action';
import { TemplateSelectionFrameConfig, TemplateSelectionFrameType } from '@metaphore/magnolia-rendering';
import { EditMode } from '../../types/enums/edit-mode';

export type CanvasState = {
    isLooping: boolean;
    isPlaying: boolean;
    isEnded: boolean;
    editMode: EditMode;
    outlines: {
        visible: boolean;
        config: TemplateSelectionFrameConfig;
        selectedConfig: TemplateSelectionFrameConfig;
    };
    playtime: number;
    selectObjectId?: string;
};

const initialState: CanvasState = {
    isLooping: false,
    isPlaying: false,
    isEnded: false,
    editMode: EditMode.NOT_EDITING,
    outlines: {
        visible: false,
        config: {
            borderWidth: 2,
            borderColor: 0x80_c5_ff,
            borderRadius: 2,
        },
        selectedConfig: {
            type: TemplateSelectionFrameType.Dashed,
            borderWidth: 2,
            borderColor: 0x80_c5_ff,
        },
    },
    playtime: 0,
};

const slice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        setLooping: (state, action: ReduxAction<{ looping: boolean }>) => ({
            ...state,
            isLooping: action.payload.looping,
        }),
        setPlaying: (state, action: ReduxAction<{ playing: boolean }>) => ({
            ...state,
            isPlaying: action.payload.playing,
        }),
        setEnded: (state, action: ReduxAction<{ ended: boolean }>) => ({
            ...state,
            isEnded: action.payload.ended,
        }),
        setEditMode: (state, action: ReduxAction<{ editmode: EditMode }>) => ({
            ...state,
            editMode: action.payload.editmode,
        }),
        setPlaytime: (state, action: ReduxAction<{ playtime: number }>) => ({
            ...state,
            playtime: action.payload.playtime,
        }),
        displayObjectOutlines: (state, action: ReduxAction<{ visible: boolean }>) => ({
            ...state,
            outlines: {
                ...state.outlines,
                visible: action.payload.visible,
            },
        }),
        selectObject: (state, action: ReduxAction<{ objectId: string | undefined }>) => ({
            ...state,
            selectObjectId: action.payload.objectId,
        }),
    },
});

export const CanvasActions = {
    setLooping: slice.actions.setLooping,
    setPlaying: slice.actions.setPlaying,
    setEnded: slice.actions.setEnded,
    setEditMode: slice.actions.setEditMode,
    displayObjectOutlines: slice.actions.displayObjectOutlines,
    setPlaytime: slice.actions.setPlaytime,
    selectObject: slice.actions.selectObject,
};

export default slice.reducer;
