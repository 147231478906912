import { gql } from '@apollo/client';

export const getAll = gql`
    query Campaigns {
        campaigns {
            id
            name
            items {
                id
                name
                instances {
                    templateInstance {
                        formatValues {
                            formatId
                        }
                    }
                }
            }
            languages {
                id
                name
            }
            createdAt
        }
    }
`;
