import React, { ReactElement } from 'react';

type Props = {
    title: string;
};

export default function PageTitle(props: Props): ReactElement {
    const { title } = props;

    return <h1 className='text-2xl uppercase text-companyDarkGrey'>{title}</h1>;
}
