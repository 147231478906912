import React, { ReactElement, useEffect, useState } from 'react';
import Table from '../../components/table/table';
import TableBody from '../../components/table/table-body';
import { GeneratorCampaignItem, GeneratorFormat, Provider } from '../../types/generator';
import NewGeneratorMatrixHeadRow from './new-generator-matrix-head-row';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import EditorSelectors from '../../stores/selectors/editor';
import { Campaign, ClientTemplateFormat, TemplateFormatGroup } from '../../generated/gql/graphql';
import NewProviderAccordion from './new-provider-accordion';
import useProviderAccordion from '../../hooks/use-provider-accordion';

export default function NewGeneratorMatrixTable(): ReactElement {
    const activeCampaign = useStoreValue<Campaign>(EditorSelectors.getActiveCampaign());
    const { providers, setProviders, expandProvider, expandAllProviders } = useProviderAccordion();

    const [checkMarkMatrix, setCheckMarkMatrix] = useState<GeneratorFormat[]>([]);
    const { generator } = useServices();

    function getUniqueProviders(
        groups: (TemplateFormatGroup | null | undefined)[],
        clientFormats?: ClientTemplateFormat[],
    ): Provider[] {
        const mergedGroups: TemplateFormatGroup[] = Object.values(
            // eslint-disable-next-line unicorn/no-array-reduce
            groups.reduce((acc: { [key: string]: TemplateFormatGroup }, item) => {
                if (item) {
                    const existingItem = acc[item.id];
                    if (existingItem && existingItem.formats && item.formats) {
                        acc[item.id] = { ...existingItem, formats: [...existingItem.formats, ...item.formats] };
                    } else {
                        acc[item.id] = item;
                    }
                    return acc;
                }
                return acc;
            }, {}),
        );

        const merged = mergedGroups.map((group) => ({
            ...group,
            formats: group.formats!.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id)),
            expanded: false,
        }));

        if (clientFormats) {
            const clientGroups = Array.from(new Map(clientFormats.map((item) => [item.formatId, item])).values());
            const clientCustomProviders = {
                id: 'custom-client-formats',
                name: 'Custom Formats',
                expanded: false,
                formats: clientGroups.map((item) => ({
                    id: item.formatId,
                    name: item.format!.name,
                    width: item.format!.width,
                    height: item.format!.height,
                })),
            };

            return [...merged, clientCustomProviders];
        }

        return merged;
    }

    function createCheckMatrix(): GeneratorFormat[] {
        if (!activeCampaign || !activeCampaign.items) {
            return [];
        }

        const templates = activeCampaign.items.flatMap((item) => item.template);
        const loadedProviders = templates
            .map((item) => item?.formatGroups)
            .filter((item) => item !== undefined)
            .flat();

        const loadedClientFormats = templates
            .map((item) => item?.clientFormats)
            .filter((item) => item !== undefined)
            .flat() as ClientTemplateFormat[];

        const uniqueProviders = getUniqueProviders(loadedProviders, loadedClientFormats);

        setProviders(uniqueProviders);

        const items = activeCampaign.items?.map((item) => {
            const out = [] as GeneratorFormat[];

            uniqueProviders.forEach((provider) => {
                provider?.formats?.forEach((format) => {
                    item.instances?.forEach((instance) => {
                        const disabled =
                            !item.template?.formats?.some((v) => v.id === format.id) &&
                            !item.template?.clientFormats?.some((v) => v.formatId === format.id);

                        out.push({
                            campaignId: activeCampaign.id,
                            formatId: format.id,
                            providerId: provider.id,
                            campaignItemId: item.id,
                            templateId: item.templateId,
                            checked: false,
                            campaignItemInstanceId: instance.id,
                            language: {
                                id: instance.language?.id || '',
                                name: instance.language?.name || '',
                            },
                            disabled,
                        });
                    });
                });
            });

            return out;
        });

        return items?.flat() || [];
    }

    useEffect(() => {
        const out = createCheckMatrix();
        setCheckMarkMatrix(out);
    }, [activeCampaign]);

    function updateCheckMarkMatrix(newArray: GeneratorFormat[]): void {
        setCheckMarkMatrix(newArray);
        generator.setGeneratorData(newArray);
    }

    return (
        <Table className='relative block h-full border-separate border-spacing-0 overflow-scroll'>
            <NewGeneratorMatrixHeadRow
                onExpandAllProviders={(v) => {
                    expandAllProviders(v);
                }}
                checkMarkMatrix={checkMarkMatrix}
                campaignItems={(activeCampaign?.items as GeneratorCampaignItem[]) || []}
                updateCheckMarkMatrix={(newArray) => updateCheckMarkMatrix(newArray)}
            />
            <TableBody>
                {providers.map((provider) => (
                    <NewProviderAccordion
                        key={provider.id}
                        expanded={provider.expanded}
                        provider={provider}
                        checkMarkMatrix={checkMarkMatrix}
                        updateCheckMarkMatrix={(newArray) => updateCheckMarkMatrix(newArray)}
                        campaignItems={(activeCampaign?.items as GeneratorCampaignItem[]) || []}
                        onExpand={(providerId, expand) => {
                            expandProvider(providerId, expand);
                        }}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
