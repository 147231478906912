import React, { ReactElement, ReactNode } from 'react';
import { ListItemText as MUIListItemText } from '@mui/material';

type Props = {
    className?: string;
    spanClassName?: string;
    children?: ReactNode;
};

export default function ListItemText(props: Props): ReactElement {
    const { className, spanClassName, children } = props;

    return (
        <MUIListItemText className={className} primaryTypographyProps={{ className: spanClassName }}>
            {children}
        </MUIListItemText>
    );
}

ListItemText.defaultProps = {
    className: '',
    spanClassName: '',
    children: undefined,
};
