import { AdvertisingProvider } from '../types/advertising-provider';
import { store } from '../stores';
import { GeneratorActions } from '../stores/slices/generator';
import { Template, TemplateFormat } from '../generated/gql/graphql';
import { SelectedInstanceFormats } from '../types/selected-instance-formats';
import { OutputChannel } from '../types/enums/template';
import { GeneratorFormat } from '../types/generator';

interface IGeneratorService {
    setCampaignId(campaignId: string): void;

    setOutputChannel(outputChannel: string): void;

    setLocals(locals: string[]): void;

    setInstanceIds(instanceIds: string[]): void;

    setProviders(providers: AdvertisingProvider[]): void;

    setFormats(formats: { instanceId: string; formats: TemplateFormat[] }[]): void;

    selectAllByProvider(name: string, template: Template[], formats: TemplateFormat[]): void;

    areAllProviderFormatsSelected(
        name: string,
        template: Template[],
        formats: TemplateFormat[],
    ): { allSelected: boolean; selectionLength: number };

    setGeneratorData(data: GeneratorFormat[]): void;
}

const GeneratorService = (): IGeneratorService => {
    function setOutputChannel(outputChannel: OutputChannel): void {
        store.dispatch(GeneratorActions.setSelectedOutputChannel({ outputChannel }));
    }

    function setCampaignId(campaignId: string): void {
        store.dispatch(GeneratorActions.setSelectedCampaignId({ campaignId }));
    }

    function setLocals(locals: string[]): void {
        store.dispatch(GeneratorActions.setSelectedLocals({ locals }));
    }

    function setInstanceIds(instanceIds: string[]): void {
        store.dispatch(GeneratorActions.setSelectedInstanceIds({ instanceIds }));
    }

    function setProviders(providers: AdvertisingProvider[]): void {
        store.dispatch(GeneratorActions.setSelectedProviders({ providers }));
    }

    function setFormats(formats: { instanceId: string; formats: TemplateFormat[] }[]): void {
        store.dispatch(GeneratorActions.setSelectedFormats({ formats }));
    }

    function selectAllByProvider(name: string, template: Template[], formats: TemplateFormat[]): void {
        const newFormats: SelectedInstanceFormats[] = [];

        template.forEach((t) => {
            t.instances?.forEach((i) => {
                if (i) {
                    newFormats.push({
                        instanceId: i.id,
                        language: i.languageId || undefined,
                        name,
                        formats: formats.filter((f) =>
                            t.formats?.some(
                                (tf) => `${f.name}_${f.width}_${f.height}` === `${tf.name}_${tf.width}_${tf.height}`,
                            ),
                        ),
                    });
                }
            });
        });

        const { selectedFormats } = store.getState().generator;

        const newSelection: SelectedInstanceFormats[] = [];
        selectedFormats
            .filter((sf) => sf.name === name)
            .forEach((sf) => {
                newSelection.push(sf);
            });

        newSelection.push(...newFormats);

        setFormats(newSelection);
    }

    function areAllProviderFormatsSelected(
        name: string,
        template: Template[],
    ): { allSelected: boolean; selectionLength: number } {
        const { selectedFormats } = store.getState().generator;
        const selectedProviderFormats = selectedFormats.filter((sf) => sf.name === name).map((sf) => sf.name);

        const newSelectedProviderFormats = selectedProviderFormats.filter(
            (n, index) => selectedProviderFormats.indexOf(n) === index,
        );
        const allProviderFormatNames = template.flatMap((t) => t.formats?.map((f) => f.name));
        const filterProvider = allProviderFormatNames.filter((f) => f === name);

        return {
            allSelected: filterProvider.length === newSelectedProviderFormats.length * template.length,
            selectionLength: selectedProviderFormats.length,
        };
    }

    function setGeneratorData(data: GeneratorFormat[]): void {
        store.dispatch(GeneratorActions.setGeneratorData({ data }));
    }

    return {
        setCampaignId,
        setOutputChannel,
        setLocals,
        setInstanceIds,
        setProviders,
        setFormats,
        selectAllByProvider,
        areAllProviderFormatsSelected,
        setGeneratorData,
    };
};

export type { IGeneratorService };
export default GeneratorService;
