import React, { ReactElement, useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';

import { Font, FontStyle, FontWeight, getFontWeightValue } from '@metaphore/magnolia-rendering';
import { FontFamilyChange } from '../../types/font';

type Props = {
    fontSettings: {
        fontFamily?: string;
        fontWeight?: FontWeight;
        fontStyle?: FontStyle;
    };
    fonts: Font[];
    onSetFontFamily(value: FontFamilyChange): void;
};

export default function TextFontFamily(props: Props): ReactElement {
    const { fontSettings, fonts, onSetFontFamily } = props;
    const [fontface, setFontface] = React.useState('');

    useEffect(() => {
        if (fontSettings) {
            const { fontFamily, fontWeight, fontStyle } = fontSettings;

            fonts.find((font, index) => {
                if (font.face.family !== fontFamily) {
                    return false;
                }

                if (getFontWeightValue(fontWeight) !== getFontWeightValue(font.face.weight)) {
                    return false;
                }

                if ((fontStyle ?? FontStyle.Normal) !== (font.face.style ?? FontStyle.Normal)) {
                    return false;
                }

                setFontface(index.toString());

                return true;
            });
        }
    }, [fonts, fontSettings]);

    function getFontName(font: Font): string {
        let name = font.face.family;
        if (font.face.style && font.face.style !== FontStyle.Normal) {
            name += ` - ${font.face.style}`;
        }
        if (font.face.weight) {
            name += ` (${getFontWeightValue(font.face.weight)})`;
        }
        return name;
    }

    function handleOnChange(value: string): void {
        const fontChange = {
            family: fonts[Number(value)].face.family,
            style: fonts[Number(value)].face.style,
            weight: fonts[Number(value)].face.weight,
        } as FontFamilyChange;

        onSetFontFamily(fontChange);
    }

    return (
        <FormControl sx={{ minWidth: 160 }} size='small'>
            <InputLabel id='select-font-family'>Choose font</InputLabel>
            <Select
                onChange={(e) => handleOnChange(e.target.value)}
                value={fontface}
                label='Choose font'
                labelId='select-font-family'
            >
                {fonts.map((font, index) => (
                    <MenuItem key={font.src + font.face.family} value={index}>
                        {getFontName(font)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
