import React, { forwardRef, ReactNode } from 'react';
import { IconButton as MUIIconButton } from '@mui/material';
import Tooltip from '../tooltip/tooltip';

type Props = {
    disabled?: boolean;
    onClick?: () => void;
    children?: ReactNode;
    className?: string;
    tooltip?: string;
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
};

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { onClick, children, className, disabled, tooltip, color } = props;

    return (
        <Tooltip title={tooltip}>
            <MUIIconButton ref={ref} color={color} onClick={onClick} className={className} disabled={disabled}>
                {children}
            </MUIIconButton>
        </Tooltip>
    );
});

export default IconButton;

IconButton.defaultProps = {
    disabled: false,
    onClick: undefined,
    children: undefined,
    className: '',
    tooltip: '',
    color: 'default',
};
