import React, {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import useLocale from '../../../../hooks/use-locale';
import AssetSearch from './asset-search';
import AssetReplace from './asset-replace';
import { AssetGroup } from '../../../../types/assets';
import { CustomTemplateObjectChange } from '../../../../types/template';
import { useStoreValue } from '../../../../hooks/use-store-value';
import EditorSelectors from '../../../../stores/selectors/editor';
import { useServices } from '../../../../hooks/use-services';
import { CampaignItem } from '../../../../generated/gql/graphql';

type Props = {
    assetGroup: AssetGroup;
    onContinue(): void;
    onSubmit(templateObjectChanges: CustomTemplateObjectChange[]): void;
};

enum State {
    SEARCH = 'search',
    REPLACE = 'replace',
}

export interface IAssetReplace extends ForwardRefExoticComponent<ReactElement> {
    onBack: () => void;
}

const AssetReplaceContent = forwardRef<IAssetReplace, Props>((props, ref) => {
    const { assetGroup, onContinue, onSubmit } = props;

    const { getText } = useLocale();
    const { campaign } = useServices();

    const activeCampaignItem = useStoreValue<CampaignItem | undefined>(EditorSelectors.getActiveCampaignItem());

    const [campaignItem, setCampaignItem] = useState<CampaignItem | undefined>(undefined);
    const [state, setState] = useState<State>(State.SEARCH);
    const [selectedObjectId, setSelectedObjectId] = useState('');

    useEffect(() => {
        async function fetch(): Promise<void> {
            if (activeCampaignItem) {
                const result = await campaign.getCampaignItemTemplateInstancesById(activeCampaignItem.id);
                setCampaignItem(result);
            }
        }

        fetch();
    }, [activeCampaignItem]);

    function handleOnSubmit(assetUrl: string): void {
        if (campaignItem && campaignItem.instances) {
            const templateObjectChanges: CustomTemplateObjectChange[] = campaignItem.instances.map((instance) => ({
                instanceId: instance.templateInstanceId,
                id: selectedObjectId,
                path: 'content.src',
                value: assetUrl,
            }));
            onSubmit(templateObjectChanges);
        }
    }

    function handleSelectTemplateObjectId(objectId: string): void {
        onContinue();
        setState(State.REPLACE);
        setSelectedObjectId(objectId);
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                onBack: () => {
                    setState(State.SEARCH);
                    setSelectedObjectId('');
                },
            } as IAssetReplace),
    );

    return (
        <>
            <p>
                {state === State.SEARCH
                    ? getText('widgets.canvas.searchAndReplace.asset.search.subline')
                    : getText('widgets.canvas.searchAndReplace.asset.replace.subline')}
            </p>
            {state === State.SEARCH ? (
                <AssetSearch
                    assetGroup={assetGroup}
                    label={
                        assetGroup
                            ? getText('widgets.canvas.searchAndReplace.select.background')
                            : getText('widgets.canvas.searchAndReplace.select.packshot')
                    }
                    onSelectTemplateObjectId={(id) => handleSelectTemplateObjectId(id)}
                />
            ) : (
                <AssetReplace
                    assetGroup={assetGroup}
                    label={
                        assetGroup
                            ? getText('widgets.canvas.searchAndReplace.select.background')
                            : getText('widgets.canvas.searchAndReplace.select.packshot')
                    }
                    onSelectAsset={(u) => handleOnSubmit(u)}
                />
            )}
        </>
    );
});

export default AssetReplaceContent;
