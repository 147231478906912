import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
};

export default function EditorRightContent(props: Props): ReactElement {
    const { children } = props;
    return <div className='flex flex-1 flex-col gap-1 space-y-10'>{children}</div>;
}

EditorRightContent.defaultProps = {
    children: undefined,
};
