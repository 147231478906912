import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useServices } from '../../hooks/use-services';
import Select from '../../components/inputs/select';
import EditorSelectors from '../../stores/selectors/editor';
import { useStoreValue } from '../../hooks/use-store-value';
import useLocale from '../../hooks/use-locale';
import { useSearchParams } from 'react-router-dom';
import { CampaignItemType } from '../../types/enums/campaign-item-type';
import { CampaignItem } from '../../generated/gql/graphql';
import { OutputChannel } from '../../types/enums/template';
import Skeleton from '../../components/skeleton/skeleton';

type Props = {
    campaignItemType: CampaignItemType;
};

export default function ToolbarTemplateSelect(props: Props): ReactElement {
    const { campaignItemType } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const activeCampaignItemId = useStoreValue<string | undefined>(EditorSelectors.getActiveCampaignItemId());
    const campaignItems = useStoreValue<CampaignItem[]>(EditorSelectors.getCampaignItems());
    const [filteredCampaignItems, setFilteredCampaignItems] = useState<CampaignItem[]>([]);

    const { template } = useServices();

    const { getText } = useLocale();

    const getFilteredCampaignItems = useCallback(
        (type: CampaignItemType) => {
            if (!campaignItems) return [];
            setIsLoading(false);
            return campaignItems
                .filter((item) => {
                    switch (type) {
                        case CampaignItemType.BANNER:
                            return item.template?.outputChannels?.some((c) => c === OutputChannel.Banner);
                        case CampaignItemType.VIDEO:
                            return item.template?.outputChannels?.some((c) => c === OutputChannel.Video);
                        case CampaignItemType.STILL:
                            return item.template?.outputChannels?.some((c) => c === OutputChannel.Still);
                        default:
                            return false;
                    }
                })
                .map((item) => item);
        },
        [campaignItems],
    );

    useEffect(() => {
        setIsLoading(true);
        setFilteredCampaignItems(getFilteredCampaignItems(campaignItemType));
    }, [campaignItemType, getFilteredCampaignItems]);

    async function onTemplateChange(index: number): Promise<void> {
        const target = filteredCampaignItems[index];
        await template.loadTemplateByCampaignItem(target);
        setSearchParams(
            {
                campaignId: searchParams.get('campaignId') || '',
                campaignItemId: target.id,
                templateId: target.templateId,
            },
            { replace: true },
        );
    }

    return (
        <div className='flex flex-row gap-2 overflow-hidden rounded-15 shadow'>
            {isLoading ? (
                <Skeleton variant='rectangular' className='w-250 rounded-15' />
            ) : (
                <Select
                    label={getText('widgets.templateSelect.label')}
                    options={filteredCampaignItems.map((i) => `${i.name} - ${i.template!.name}`)}
                    selectedOption={
                        filteredCampaignItems.findIndex((i) => i.id === activeCampaignItemId).toString() || ''
                    }
                    onChange={(i) => onTemplateChange(i)}
                    className='max-w-250'
                />
            )}
        </div>
    );
}
