import React, { ReactElement } from 'react';

interface Props {
    selectedIndex: number;
    index: number;
    option: string;

    onClick(index: number): void;
}

export default function HorizontalSliderSelectorItem(props: Props): ReactElement {
    const { selectedIndex, index, option, onClick } = props;

    return (
        <button
            type='button'
            className={`w-full overflow-hidden text-ellipsis whitespace-nowrap pl-5 pr-5 text-center ${
                selectedIndex === index ? 'text-company' : ''
            }`}
            onClick={() => onClick(index)}
        >
            {option}
        </button>
    );
}
