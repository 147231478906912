import React, { ReactElement } from 'react';
import { Chip as MUIChip } from '@mui/material';
import { Cancel } from '@mui/icons-material';

type Props = {
    id: string;
    label: string;
    count?: number;
    onDelete?: (id: string) => void;
};

export default function FilterChip(props: Props): ReactElement {
    const { id, label, count, onDelete } = props;

    const handleDelete = (): void => {
        if (onDelete) {
            onDelete(id);
        }
    };

    return (
        <MUIChip
            label={
                <span className='text-sm text-companyDarkGrey'>
                    {label}
                    {count && <span className='ml-5'>({count})</span>}
                </span>
            }
            className='bg-companyLightGrey pb-4 pl-10 pr-5 pt-4'
            onDelete={handleDelete}
            classes={{ label: 'pl-0' }}
            deleteIcon={<Cancel className='h-16 w-16' />}
        />
    );
}

FilterChip.defaultProps = {
    onDelete: undefined,
    count: undefined,
};
