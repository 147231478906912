import React, { createContext, ReactElement, ReactNode } from 'react';
import { ServiceInstances, Services } from '../services';

const ServiceContext = createContext<Services>(ServiceInstances);

type Props = {
    children: ReactNode;
};

export function ServiceProvider(props: Props): ReactElement {
    const { children } = props;
    return <ServiceContext.Provider value={ServiceInstances}>{children}</ServiceContext.Provider>;
}

export default ServiceContext;
