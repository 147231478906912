import React from 'react';
import App from './app';
import { StyledEngineProvider } from '@mui/material/styles';
import './styles/globals.css';
import { createRoot } from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import GraphQLProvider from './providers/graphql-provider';
import { ServiceProvider } from './providers/service-provider';
import ReduxStoreProvider from './providers/redux-store-provider';
import MuiThemeProvider from './providers/mui-theme-provider';
import KeycloakProvider from './providers/keycloak-provider';
import NotificationProvider from './providers/notification-provider';
import MarkerIoProvider from './providers/marker-io-provider';
import FormatProvider from './providers/format-provider';
import { AppConfigProvider } from './providers/config/app-config-provider';

const rootElement = document.querySelector('#root');
const root = createRoot(rootElement!);

root.render(
    <NotificationProvider>
        <AppConfigProvider>
            <MarkerIoProvider>
                <KeycloakProvider>
                    <GraphQLProvider>
                        <ReduxStoreProvider>
                            <ServiceProvider>
                                <StyledEngineProvider injectFirst>
                                    <MuiThemeProvider>
                                        <CssBaseline />
                                        <FormatProvider>
                                            <App />
                                        </FormatProvider>
                                    </MuiThemeProvider>
                                </StyledEngineProvider>
                            </ServiceProvider>
                        </ReduxStoreProvider>
                    </GraphQLProvider>
                </KeycloakProvider>
            </MarkerIoProvider>
        </AppConfigProvider>
    </NotificationProvider>,
);
