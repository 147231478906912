import React, { ReactElement } from 'react';
import { Icon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import short from 'short-uuid';

type Props = {
    characters: { value: string }[];
    setTextCharacter: (value: string) => void;
};

export default function CharacterDropdown(props: Props): ReactElement {
    const { characters, setTextCharacter } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
    const open = Boolean(anchorEl);

    function handleClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(value?: string): void {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setAnchorEl(undefined);

        if (value) {
            setTextCharacter(value);
        }
    }

    return (
        <>
            <Icon onClick={(event) => handleClick(event)}>keyboard</Icon>
            <Menu
                id='character-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {characters.map((item) => (
                    <MenuItem key={short.generate()} className='inline px-10' onClick={() => handleClose(item.value)}>
                        {item.value}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
