import { gql } from '@apollo/client';

export const getTemplateInstanceById = gql`
    query TemplateInstance($templateInstanceId: ID!) {
        templateInstance(id: $templateInstanceId) {
            formatValues {
                formatId
                values {
                    id
                    path
                    value
                }
            }
            values {
                id
                path
                value
            }
            name
            id
            templateId
            languageId
            variantId
            renderSettings {
                bannerSettings {
                    targetUrl
                    loop
                }
            }
        }
    }
`;
