import { TemplateObjectChange } from '../../generated/gql/graphql';
import { createSlice } from '@reduxjs/toolkit';
import { ReduxAction } from '../../types/redux-action';
import { History } from '../../types/history';

export type HistoryState = {
    instanceHistory: History;
};

const initialState: HistoryState = {
    instanceHistory: {
        past: [],
        present: {} as TemplateObjectChange,
        future: [],
    },
};

const slice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        add: (state, action: ReduxAction<{ templateObjectChange: TemplateObjectChange }>) => {
            const { past, present } = state.instanceHistory;
            const limit = 20;
            const newPast = [...past];

            const { length } = newPast;

            let ignorePresent = false;

            if (limit <= length) newPast.shift();
            const newPresent = action.payload.templateObjectChange;

            if (
                length > 1 &&
                newPast[length - 1] &&
                newPast[length - 1].id === newPresent.id &&
                newPast[length - 1].path === 'content.text' &&
                newPresent.path === 'content.text'
            ) {
                ignorePresent = true;
            }

            return {
                ...state,
                instanceHistory: {
                    past: [...newPast, ...(Object.keys(present).length === 0 || ignorePresent ? [] : [present])],
                    present: newPresent,
                    future: [],
                },
            };
        },
        undo: (state) => {
            const { past, present, future } = state.instanceHistory;
            const previous = past[past.length - 1];
            const newPast = past.slice(0, -1);

            return {
                ...state,
                instanceHistory: {
                    past: newPast,
                    present: previous,
                    future: [present, ...future],
                },
            };
        },
        redo: (state) => {
            const { past, present, future } = state.instanceHistory;

            const next = future[0];
            const newFuture = future.slice(1);
            return {
                ...state,
                instanceHistory: {
                    past: [...past, present],
                    present: next,
                    future: newFuture,
                },
            };
        },
    },
});

export const HistoryActions = {
    undo: slice.actions.undo,
    redo: slice.actions.redo,
    add: slice.actions.add,
};

export default slice.reducer;
