/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { ReactElement, useState, useEffect } from 'react';
import useLocale from '../../hooks/use-locale';
import { useStoreValue } from '../../hooks/use-store-value';
import TemplateSelector from '../../stores/selectors/template';
import CanvasSelectors from '../../stores/selectors/canvas';
import { Slider } from '@mui/material';
import { useServices } from '../../hooks/use-services';
import InstanceSelector from '../../stores/selectors/instance';
import usePrevious from '../../hooks/use-previous';
import { TemplateObject, TemplateObjectChange } from '../../generated/gql/graphql';

export default function ScaleSlider(): ReactElement {
    const imageProps = useStoreValue<TemplateObject[]>(TemplateSelector.getImageProperties());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const previousSelectedObjectId = usePrevious(selectedObjectId);

    const isImageSelected = imageProps?.some((item) => item.id === selectedObjectId) || false;

    const currentInstance = useStoreValue<TemplateObjectChange>(
        InstanceSelector.getChangedPropertyById(selectedObjectId, 'scale'),
    );

    const [scaleValue, setScaleValue] = useState<number | undefined>(undefined);

    const { getText } = useLocale();
    const { instance } = useServices();

    function getValueInPercent(value: number): string {
        const valueInPercent = Math.round(value * 100);
        return `${valueInPercent}%`;
    }

    async function handleOnChange(value: number): Promise<void> {
        setScaleValue(value);
    }

    useEffect(() => {
        if (currentInstance) {
            setScaleValue(currentInstance.value);
        }
    }, [currentInstance]);

    useEffect(() => {
        async function scale(): Promise<void> {
            if (scaleValue) await instance.packshots.scalePackshot(selectedObjectId, scaleValue);
        }

        if (selectedObjectId === previousSelectedObjectId && isImageSelected) {
            scale();
        }
    }, [scaleValue, selectedObjectId, instance]);

    const marks = [
        {
            value: 1,
            label: '100%',
        },
    ];

    return (
        <div className='w-full'>
            <p className='-translate-y-5 text-gray-500'>
                {getText('widgets.imageEditing.scaleLabel', getValueInPercent(currentInstance?.value || 1))}
            </p>
            <div className='flex items-center'>
                <p className='mb-15 mr-20 font-bold text-gray-500'>{getText('widgets.imageEditing.scaleMin')}</p>
                <Slider
                    value={currentInstance?.value || 1}
                    getAriaValueText={getValueInPercent}
                    valueLabelDisplay='auto'
                    valueLabelFormat={getValueInPercent}
                    min={0}
                    size='small'
                    step={0.05}
                    max={3}
                    marks={marks}
                    onChange={(e, v) => handleOnChange(v as number)}
                    disabled={!isImageSelected}
                />
                <p className='mb-15 ml-20 font-bold text-gray-500'>{getText('widgets.imageEditing.scaleMax')}</p>
            </div>
        </div>
    );
}
