import React, { ReactElement, useState } from 'react';
import SearchField from '../../components/inputs/search-field';
import { FileUploadOutlined, ReplayOutlined } from '@mui/icons-material';
import useLocale from '../../hooks/use-locale';
import IconButton from '../../components/buttons/icon-button';
import AssetUpload from '../asset/asset-upload';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import { useServices } from '../../hooks/use-services';
import Modal from '../../components/modal/modal';
import { TemplateObjectChange } from '../../generated/gql/graphql';
import InstanceSelector from '../../stores/selectors/instance';
import { AssetGroup } from '../../types/assets.d';

type Props = {
    onSearch: (input: string) => void;
};

export default function BackgroundPanelHeader(props: Props): ReactElement {
    const { onSearch } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [input, setInput] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const currentInstance = useStoreValue<TemplateObjectChange | undefined>(
        InstanceSelector.getImagePropertyById(selectedObjectId),
    );

    const { getText } = useLocale();
    const { instance } = useServices();

    const [isUploadOpen, setIsUploadOpen] = useState(false);

    function handleOnChange(value: string): void {
        setInput(value);
        onSearch(value);
    }

    function handleOnOpen(): void {
        setIsModalOpen(true);
    }

    async function handleOnReset(): Promise<void> {
        setIsModalOpen(false);
        await instance.background.resetBackground(selectedObjectId);
    }

    return (
        <>
            <SearchField
                placeholder={getText('widgets.backgroundSelect.searchPlaceholder')}
                onChange={(v) => handleOnChange(v)}
            />
            <div className='mr-60 flex flex-row items-center gap-40'>
                <IconButton
                    className='max-h-34 max-w-34'
                    onClick={() => handleOnOpen()}
                    disabled={currentInstance === undefined}
                >
                    <ReplayOutlined className='fill-companyMediumGrey' />
                </IconButton>
                <IconButton className='max-h-34 max-w-34' onClick={() => setIsUploadOpen(true)}>
                    <FileUploadOutlined className='fill-companyMediumGrey' />
                </IconButton>
            </div>
            <AssetUpload
                open={isUploadOpen}
                onClose={() => setIsUploadOpen(false)}
                headline={getText('widgets.assetUpload.background.headline')}
                assetGroup={AssetGroup.Background}
            />
            <Modal
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                title={getText('widgets.backgroundSelect.resetModal.headline')}
                onSubmit={() => handleOnReset()}
                actions={[
                    {
                        id: 'reset',
                        label: getText('widgets.backgroundSelect.resetModal.confirm'),
                    },
                ]}
            >
                <p>{getText('widgets.backgroundSelect.resetModal.content')}</p>
            </Modal>
        </>
    );
}
