import { GraphQlClient } from '../providers/graphql-provider';
import { Mutations } from '../repositories';
import { AssetGroup, AssetsQuery, Pagination } from '../generated/gql/graphql';
import { IEventBusService } from './event-bus';
import { Event } from '../types/enums/events';
import { ILoggerService } from './logger';
import { graphql } from '../generated/gql';
import { ArrayElement } from '../utils/graphql-utils';
import { AssetQuery } from '../types/assets';

export const AssetsQueryDocument = graphql(`
    query Assets($assetQuery: AssetQuery!) {
        assets(query: $assetQuery) {
            items {
                id
                name
                groups
                url
                thumbnailUrl
                bright
                deletable
            }
            limit
            offset
            page
            totalCount
        }
    }
`);

export type AssetQueryResult = AssetsQuery['assets'];
export type AssetQueryItem = ArrayElement<AssetsQuery['assets']['items']>;

interface IAssetService {
    getAssetsByQuery: (query: AssetQuery, pagination?: Pagination) => Promise<AssetQueryResult>;
    uploadAsset: (files: File[], assetGroup: AssetGroup) => Promise<void>;
    deleteAsset: (id: string) => Promise<void>;
}

const AssetService = (logger: ILoggerService, eventBus: IEventBusService): IAssetService => {
    async function getAssetsByQuery(query: AssetQuery, pagination?: Pagination): Promise<AssetQueryResult> {
        const result = await GraphQlClient.query({
            query: AssetsQueryDocument,
            fetchPolicy: 'no-cache',
            variables: {
                assetQuery: {
                    filter: {
                        groups: { anyOf: [query.group] },
                        name: { contains: query.search },
                    },
                    pagination: { limit: pagination?.limit, offset: pagination?.offset, page: pagination?.page },
                },
            },
        });

        if (result.error) {
            throw result.error;
        }

        if (result.data.assets?.items) {
            return result.data.assets;
        }

        return {
            items: [],
            limit: 0,
            offset: 0,
            page: 0,
            totalCount: 0,
        };
    }

    async function uploadAsset(files: File[], assetGroup: AssetGroup): Promise<void> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.asset.uploadAsset,
            variables: {
                file: files[0],
                input: {
                    bright: true,
                    groups: [assetGroup],
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }

        switch (assetGroup) {
            case AssetGroup.Background:
                eventBus.publish(Event.BACKGROUND_ASSET_UPLOADED, {});
                break;
            case AssetGroup.Packshot:
                eventBus.publish(Event.PACKSHOT_ASSET_UPLOADED, {});
                break;
            default:
                logger.info('Asset group not implemented', { assetGroup });
        }
    }

    async function deleteAsset(id: string): Promise<void> {
        const result = await GraphQlClient.mutate({
            mutation: Mutations.asset.deleteAsset,
            variables: {
                deleteAssetId: id,
            },
        });
        if (result.errors) {
            throw result.errors;
        }
    }

    return {
        getAssetsByQuery,
        uploadAsset,
        deleteAsset,
    };
};

export default AssetService;
