/* eslint-disable max-len */
import React, { ReactElement } from 'react';
import IconButton from '../buttons/icon-button';

type Props = {
    title: string;
    icon: ReactElement;
    onClickButton(): void;
    toolTip?: string | undefined;
};

export default function TitleWithIconButton(props: Props): ReactElement {
    const { title, icon, onClickButton, toolTip } = props;

    return (
        <div className='flex space-x-24'>
            <h1 className='my-auto text-2xl uppercase text-companyDarkGrey'>{title}</h1>
            <div className='group relative'>
                <IconButton className='p-17 h-40 w-40 rounded-xl bg-white shadow' onClick={onClickButton}>
                    {icon}
                </IconButton>
                {toolTip !== undefined && (
                    <div className='pointer-events-none absolute left-full top-1/2 mx-20 -translate-y-1/2 whitespace-nowrap rounded-md bg-gray-500 p-5 text-base text-white opacity-0 transition-opacity group-hover:opacity-100'>
                        {toolTip}
                    </div>
                )}
            </div>
        </div>
    );
}

TitleWithIconButton.defaultProps = {
    toolTip: undefined,
};
