/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useStoreValue } from '../../hooks/use-store-value';
import GeneratorSelector from '../../stores/selectors/generator';
import useLocale from '../../hooks/use-locale';
import { useServices } from '../../hooks/use-services';
import BannerIcon from '../../components/icons/banner-icon';
import { OutputChannel } from '../../types/enums/template';
import PrintIcon from '../../components/icons/print-icon';
import StillIcon from '../../components/icons/still-icon';
import VideoIcon from '../../components/icons/video-icon';
import { useSearchParams } from 'react-router-dom';
import { Template } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import { RenderingExportTarget } from '../../types/enums/rendering-export-targets';

export default function GeneratorCampaignTypeSelector(): ReactElement {
    const selectedOutputChannel = useStoreValue<OutputChannel>(GeneratorSelector.getSelectedOutputChannel);

    const { getText } = useLocale();
    const [searchParams] = useSearchParams();
    const { generator } = useServices();
    const activeTemplate = useStoreValue<Template | undefined>(EditorSelectors.getActiveTemplate());
    const exportTargets = activeTemplate?.renderingExportTargets;

    const isStillsTemplate = exportTargets?.includes(RenderingExportTarget.Png);
    const isBannerTemplate = exportTargets?.includes(RenderingExportTarget.GoogleAd);
    const isVideoTemplate = exportTargets?.includes(RenderingExportTarget.Mp4);
    const isPrintTemplate = exportTargets?.includes(RenderingExportTarget.Pdf);

    function onSetOutputChannel(outputChannel: OutputChannel): void {
        generator.setOutputChannel(outputChannel);
    }

    function getIconColor(outputType: OutputChannel): string {
        if (outputType === selectedOutputChannel) {
            return '#DD2127';
        }

        return '#4A4A4A';
    }

    function setOutputChannelFromURL(): void {
        const fromParameter = searchParams.get('from');

        switch (fromParameter) {
            case 'editorstill':
                generator.setOutputChannel(OutputChannel.Still);
                break;
            case 'editorvideo':
                generator.setOutputChannel(OutputChannel.Video);
                break;
            case 'editorbanner':
                generator.setOutputChannel(OutputChannel.Banner);
                break;
            default:
                generator.setOutputChannel(OutputChannel.Still);
        }
    }

    useEffect(() => {
        setOutputChannelFromURL();
    }, []);

    // ToDo: Wrap MUI Tabs in a custom component
    return (
        <Tabs className='my-auto ml-30' value={selectedOutputChannel}>
            <Tab
                value={OutputChannel.Still}
                label={getText(`common.campaignItemType.${OutputChannel.Still.toLowerCase()}`)}
                icon={
                    <StillIcon
                        className={!isStillsTemplate ? 'opacity-50' : ''}
                        width={25}
                        height={25}
                        color={getIconColor(OutputChannel.Still)}
                    />
                }
                iconPosition='start'
                onClick={() => onSetOutputChannel(OutputChannel.Still)}
                disabled={!isStillsTemplate}
            />
            <Tab
                value={OutputChannel.Video}
                label={getText(`common.campaignItemType.${OutputChannel.Video.toLowerCase()}`)}
                icon={
                    <VideoIcon
                        className={!isVideoTemplate ? 'opacity-50' : ''}
                        width={25}
                        height={25}
                        color={getIconColor(OutputChannel.Video)}
                    />
                }
                iconPosition='start'
                onClick={() => onSetOutputChannel(OutputChannel.Video)}
                disabled={!isVideoTemplate}
            />
            <Tab
                value={OutputChannel.Banner}
                label={getText(`common.campaignItemType.${OutputChannel.Banner.toLowerCase()}`)}
                icon={
                    <BannerIcon
                        className={!isBannerTemplate ? 'opacity-50' : ''}
                        width={25}
                        height={25}
                        color={getIconColor(OutputChannel.Banner)}
                    />
                }
                iconPosition='start'
                onClick={() => onSetOutputChannel(OutputChannel.Banner)}
                disabled={!isBannerTemplate}
            />
            <Tab
                value={OutputChannel.Print}
                label={getText(`common.campaignItemType.${OutputChannel.Print.toLowerCase()}`)}
                icon={
                    <PrintIcon
                        className={!isPrintTemplate ? 'opacity-50' : ''}
                        width={25}
                        height={25}
                        color={getIconColor(OutputChannel.Print)}
                    />
                }
                iconPosition='start'
                onClick={() => onSetOutputChannel(OutputChannel.Print)}
                disabled={!isPrintTemplate}
            />
        </Tabs>
    );
}
