import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Class from '../../../utils/classes';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
    children?: ReactNode;
    className?: string;
};

export default function EditorLeftContent(props: Props): ReactElement {
    const { children, className } = props;
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [position, setPosition] = useState<number | undefined>();
    const [size, setSize] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement>(null);
    const canvasContainerRef = useRef<HTMLDivElement>(null);

    function onDrag(event: any): void {
        if (isDragging && ref.current && position && size && event.clientX !== 0 && canvasContainerRef.current) {
            ref.current.style.flexBasis = `calc(${size}px + ${event.clientX - position}px)`;
            canvasContainerRef.current.style.width = `${ref.current.clientWidth}px`;
        }
    }

    function onDragEnd(event: any): void {
        setPosition(event.clientX);
        setIsDragging(false);
    }

    function onResize(): void {
        if (canvasContainerRef.current && ref.current) {
            canvasContainerRef.current.style.height = `calc(${document.documentElement.clientHeight}px - ${ref.current.offsetTop}px - 30px)`;
            canvasContainerRef.current.style.maxHeight = `calc(${document.documentElement.clientHeight}px - ${ref.current.offsetTop}px - 30px)`;
            canvasContainerRef.current.style.width = `${ref.current.clientWidth}px`;
        }
    }

    useEffect(() => {
        if (ref.current && canvasContainerRef.current) {
            canvasContainerRef.current.style.width = `${ref.current.clientWidth}px`;
        }
    }, [canvasContainerRef, ref]);

    useEffect(() => {
        window.addEventListener('mousemove', onDrag);
        window.addEventListener('mouseup', onDragEnd);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('mouseup', onDragEnd);
            window.removeEventListener('resize', onResize);
        };
    });

    useEffect(() => onResize(), []);

    function onDragStart(event: any): void {
        setPosition(event.clientX);
        setSize(ref.current?.offsetWidth);
        setIsDragging(true);
    }

    return (
        <div
            className={Class.classNames('relative z-10 flex min-w-[300px] basis-1/2 flex-col gap-15', className || '')}
            ref={ref}
        >
            <div ref={canvasContainerRef} className='sticky top-50 flex h-screen flex-col '>
                {children}
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className='absolute -right-14 top-0 z-10 h-full w-2 cursor-col-resize bg-companyGrey'
                onMouseDown={onDragStart}
                onMouseUp={onDragEnd}
            >
                <div className='absolute left-1/2 top-1/2 flex h-46 w-10 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-3 bg-companyGrey'>
                    <MoreVertIcon className='fill-companyDarkGrey' />
                </div>
            </div>
        </div>
    );
}

EditorLeftContent.defaultProps = {
    children: undefined,
    className: '',
};
