import React, { ReactElement } from 'react';
import BannerLoopSetting from './banner-loop-setting';
import BannerUrl from './banner-url';
import useLocale from '../../hooks/use-locale';

export default function BannerSettings(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='mt-10 rounded-xl bg-white px-15 py-10 shadow'>
            <div className='grid grid-cols-2'>
                <div className='flex flex-col'>
                    <h2 className='text-sm text-companyMediumGrey'>{getText('widgets.bannerSettings.label')}</h2>
                    <div className='my-20 flex space-x-10'>
                        <BannerLoopSetting />
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <BannerUrl />
            </div>
        </div>
    );
}
