/* eslint-disable max-len */
import React, { ReactElement, useEffect, useState } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import { TemplateObject } from '../../generated/gql/graphql';
import TemplateSelector from '../../stores/selectors/template';
import CanvasSelectors from '../../stores/selectors/canvas';
import ScaleSlider from './scale-slider';
import AccordionImageEdit from '../../components/accordions/accordion-image-edit';
import { EditMode } from '../../types/enums/edit-mode';
import AccordionDetails from '../../components/accordions/accordion-details';
import ImageEditButton from './image-edit-button';
import CropSliders from './crop-sliders';
import IconButton from '../../components/buttons/icon-button';
import { ReplayOutlined } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';

export default function ImageEditPalette(): ReactElement {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const imageProps = useStoreValue<TemplateObject[]>(TemplateSelector.getImageProperties());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());
    const currentEditMode = useStoreValue<EditMode>(CanvasSelectors.editMode());

    const isImageSelected = imageProps?.some((item) => item.id === selectedObjectId) || false;

    const { instance, canvas } = useServices();

    useEffect(() => {
        setIsExpanded(isImageSelected && currentEditMode !== EditMode.NOT_EDITING);

        if (!isImageSelected) {
            canvas.setEditMode(EditMode.NOT_EDITING);
        }
    }, [isImageSelected, currentEditMode]);

    async function resetEdits(): Promise<void> {
        await instance.packshots.cropPackshot(selectedObjectId, undefined);
        await instance.packshots.scalePackshot(selectedObjectId, 1);
    }

    return (
        <div className='mt-15'>
            <div>
                <ImageEditButton disabled={!isImageSelected} editMode={EditMode.CROP_SCALE} />
            </div>

            <AccordionImageEdit expanded={isExpanded}>
                <AccordionDetails className='!overflow-y-auto border-t-0'>
                    <div className='absolute left-0 top-0 flex w-full items-center justify-end gap-20 p-15'>
                        <IconButton className='z-10 rounded-none p-20' onClick={() => resetEdits()}>
                            <ReplayOutlined className='fill-companyMediumGrey' />
                        </IconButton>
                    </div>
                    <div className='scale-slider relative mt-15 flex flex-wrap gap-10'>
                        <ScaleSlider />
                        <CropSliders />
                        {!isImageSelected && (
                            <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white bg-opacity-60' />
                        )}
                    </div>
                </AccordionDetails>
            </AccordionImageEdit>
        </div>
    );
}
