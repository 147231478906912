import { gql } from '@apollo/client';

export const getClientLogo = gql`
    query getClientLogo {
        me {
            client {
                logoUrl
            }
        }
    }
`;
