import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { EditorState } from '../../slices/editor';

export const selectTextProperties = (): any =>
    createSelector(
        (state: RootState) => state.editor,
        (state: EditorState) =>
            state.baseTemplate?.objects
                .filter((item) => item !== undefined)
                .filter((item) => item.content?.type === 'Text'),
    );
