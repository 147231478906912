import React, { ReactElement, useRef, useState } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { MoreHorizOutlined } from '@mui/icons-material';
import ListItem from '../../components/list/list-item';
import ListItemButton from '../../components/list/list-item-button';
import TextList from '../../components/list/text-list';
import Popper from '../../components/popper/popper';
import PopperItem from '../../components/popper/popper-item';
import useLocale from '../../hooks/use-locale';
import CampaignContextOperation from '../../types/enums/campaign-context-operation';
import CampaignIcon from '../../assets/icons/campaign.svg';
import { CampaignItemProps } from '../../types/campaign';
import StringFormatUtils from '../../utils/string-format';

type Props = {
    campaign: CampaignItemProps;
    onOpenTemplate(id: string): void;
    onContextOperation(operation: CampaignContextOperation, id: string): void;
};

export default function CampaignCardItem(props: Props): ReactElement {
    const { campaign, onOpenTemplate, onContextOperation } = props;

    const [isOpen, setIsOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const { getText } = useLocale();

    const handleOnOpen = (): void => {
        onOpenTemplate(campaign.id);
    };

    return (
        <ListItem
            className='h-64 border-b-1 border-companyGrey p-0'
            secondaryAction={
                <IconButton ref={anchorRef} onClick={() => setIsOpen(true)}>
                    <MoreHorizOutlined />
                </IconButton>
            }
        >
            <ListItemButton className='flex w-full flex-row items-center gap-16' onClick={handleOnOpen}>
                <div className='flex basis-2/3 space-x-10'>
                    <img className='h-50 w-50 object-cover' src={CampaignIcon} alt={campaign.name} />
                    <p className='my-auto text-companyDarkGrey'>{campaign.name}</p>
                </div>
                <div className='basis-2/6 text-center'>{campaign.templateCount}</div>
                <TextList className='basis-2/6 text-center' texts={campaign.languages} />
                <div className='basis-2/6 text-center'>
                    {campaign.updatedAt ? StringFormatUtils.formatTimestamp(campaign.updatedAt) : '-'}
                </div>
                <div className='basis-2/6 text-center'>
                    {campaign.createdAt ? StringFormatUtils.formatTimestamp(campaign.createdAt) : '-'}
                </div>
                <div className='w-60' />
            </ListItemButton>
            <Popper open={isOpen} onOpen={(b) => setIsOpen(b)} anchorEl={anchorRef.current}>
                <PopperItem
                    label={getText('widgets.dashboard.context.rename')}
                    onClick={() => onContextOperation(CampaignContextOperation.Rename, campaign.id)}
                />

                <PopperItem
                    label={getText('widgets.dashboard.context.delete')}
                    onClick={() => onContextOperation(CampaignContextOperation.Delete, campaign.id)}
                />
                <PopperItem
                    label={getText('widgets.dashboard.context.duplicate')}
                    onClick={() => onContextOperation(CampaignContextOperation.Duplicate, campaign.id)}
                />
            </Popper>
        </ListItem>
    );
}
