export enum ShortKeys {
    SHOW_SELECTION = 'mod+e',
    UNDO = 'mod+z',
    REDO = 'shift+mod+z',
    FIT_TO_CANVAS = 'mod+2',
    FIT_TO_100 = 'mod+1',
    DIRECT_DOWNLOAD = 'mod+shift+s',
    NEXT_FORMAT = 'mod+up',
    PREVIOUS_FORMAT = 'mod+down',
    VIDEO_PLAY_PAUSE = 'p',
    VIDEO_REWIND = 'mod+left',
    VIDEO_FORWARD = 'mod+right',
}
