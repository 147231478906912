/**
 *
 * @param _enum A enum with string associations
 * @param _string The string to search for
 * @returns The value of the enum whose string representation
 * matches the given string or undefined if no match was found.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
function getTsNameOfEnumValue(_enum: any, _string: string | undefined): any {
    if (!_string) return undefined;
    // eslint-disable-next-line no-restricted-syntax
    for (const myEnumValue in _enum) {
        if (_enum[myEnumValue] === _string) {
            return myEnumValue;
        }
    }
    return undefined;
}

const EnumUtils = {
    getTsNameOfEnumValue,
};

export default EnumUtils;
