import { CampaignItem, CampaignItemInstance } from '../generated/gql/graphql';

function countFormatSpecificValuesByCampaignItemInstances(instances: CampaignItemInstance[]): number {
    let totalCount = 0;

    instances.forEach((instance) => {
        totalCount += instance.templateInstance?.formatValues?.length || 0;
    });

    return totalCount;
}

function countFormatSpecificValuesByCampaignItems(items: CampaignItem[]): number {
    let totalCount = 0;

    items.forEach((item) => {
        totalCount += countFormatSpecificValuesByCampaignItemInstances(item.instances || []);
    });

    return totalCount;
}

const FormatSpecificCount = {
    countByCampaignItems: countFormatSpecificValuesByCampaignItems,
    countByCampaignItemInstances: countFormatSpecificValuesByCampaignItemInstances,
};

export default FormatSpecificCount;
