import React, { ReactElement } from 'react';
import ListItem from '../../components/list/list-item';
import List from '../../components/list/list';
import CampaignTemplateItem from './campaign-template-item';
import { CampaignItemListItem } from '../../types/campaign';
import useLocale from '../../hooks/use-locale';
import { CampaignItem } from '../../generated/gql/graphql';
import CampaignItemContextOperation from '../../types/enums/campaign-item-context-operation';
import useRouterNavigation from '../../hooks/use-router-navigation';

type Props = {
    campaignId: string;
    items: CampaignItem[];
    listItems: CampaignItemListItem[];
    onContextOperation(operation: CampaignItemContextOperation, item: CampaignItem): void;
};

export default function CampaignItemList(props: Props): ReactElement {
    const { campaignId, items, listItems, onContextOperation } = props;

    const { getText } = useLocale();
    const { navigateToEditor } = useRouterNavigation();

    function handleCampaignItemClick(item: CampaignItem): void {
        navigateToEditor(campaignId, item);
    }

    const noCampaignItems = listItems.length === 0;

    return (
        <div className='flex flex-col rounded-xl bg-white p-50 shadow'>
            {!noCampaignItems ? (
                <List className='flex w-full flex-col p-0'>
                    <ListItem className='flex flex-row gap-16 pb-0 pl-20 pr-48 pt-0 text-base text-companyMediumGrey'>
                        <div className='basis-2/6 border-r'>{getText('widgets.campaigns.list.name')}</div>
                        <div className='basis-2/6 border-r'>{getText('widgets.campaigns.list.template')}</div>
                        <div className='basis-2/6 border-r text-center'>
                            {getText('widgets.campaigns.list.lastEdit')}
                        </div>
                        <div className='basis-2/6 border-r text-center'>
                            {getText('widgets.campaigns.list.createdAt')}
                        </div>
                        <div className='w-60 text-center' />
                    </ListItem>
                    {listItems.map((item, index) => (
                        <CampaignTemplateItem
                            key={item.id}
                            listItem={item}
                            item={items[index]}
                            onClick={(v) => handleCampaignItemClick(v)}
                            onPressDelete={(v) => onContextOperation(CampaignItemContextOperation.Delete, v)}
                            onPressRename={(v) => onContextOperation(CampaignItemContextOperation.Rename, v)}
                            onPressDuplicate={(v) => onContextOperation(CampaignItemContextOperation.Duplicate, v)}
                        />
                    ))}
                </List>
            ) : (
                <p>{getText('widgets.campaigns.list.noCampaignItemWarning')}</p>
            )}
        </div>
    );
}
