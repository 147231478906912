import React, { ReactElement } from 'react';
import Toolbar from '../../components/toolbar/toolbar';
import ToolbarSave from './toolbar-save';
import ToolbarTemplateOptions from './toolbar-template-options';
import ToolbarTemplateSelect from './toolbar-template-select';
import { CampaignItemType } from '../../types/enums/campaign-item-type';
import ToolbarCampaignName from './toolbar-campaign-name';
import ToolbarFormatSelect from './toolbar-format-select';

type Props = {
    campaignItemType: CampaignItemType;
};

export default function EditorToolbar(props: Props): ReactElement {
    const { campaignItemType } = props;

    return (
        <Toolbar className='mb-24 flex-col justify-between gap-30 2xl:flex-row'>
            <div className='flex w-full flex-row justify-between gap-30'>
                <div className='flex select-none flex-row gap-20'>
                    <ToolbarCampaignName />
                    <ToolbarTemplateSelect campaignItemType={campaignItemType} />
                    <ToolbarTemplateOptions />
                    <ToolbarFormatSelect />
                </div>
                <ToolbarSave />
            </div>
        </Toolbar>
    );
}
