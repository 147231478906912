import React, { ReactElement, useContext, useRef } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import { useServices } from '../../hooks/use-services';
import EditorSelectors from '../../stores/selectors/editor';
import { TemplateFormat } from '../../generated/gql/graphql';
import useLocale from '../../hooks/use-locale';
import Select, { ISelect } from '../../components/inputs/select';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';
import { TemplateInstanceFormatValues } from '@metaphore/magnolia-rendering';
import { ReactComponent as FormatOverrideIcon } from '../../assets/icons/format-override-icon.svg';
import { FormatProviderContext } from '../../providers/format-provider';

export default function ToolbarFormatSelect(): ReactElement {
    const { setSelectedProvider, availableProviders, setSelectedFormat, selectedProvider, selectedFormat } =
        useContext(FormatProviderContext);

    const formatValues = useStoreValue<TemplateInstanceFormatValues[]>(
        EditorSelectors.getTemplateInstanceFormatValues(),
    );

    const isFormatSpecificActive = useStoreValue<boolean>(EditorSelectors.getIsFormatSpecificActive());
    const currentFormat = useStoreValue<TemplateFormat>(EditorSelectors.getCurrentFormat());

    const { getText } = useLocale();
    const { editor, canvas } = useServices();

    const selectFormatRef = useRef<ISelect>(null);

    function onProviderChange(index: number, latest?: boolean): void {
        const v = availableProviders[index];
        if (!v) {
            return;
        }

        setSelectedProvider(v);
        setSelectedFormat(latest ? v.formats[v.formats.length - 1] : v.formats[0]);
        editor.setFormat(latest ? v.formats[v.formats.length - 1] : v.formats[0]);
    }

    function getFormatRatioValue(name: string, width: number, height: number): string {
        return `${name} (${width}x${height})`;
    }

    function onFormatChange(index: number): void {
        canvas.pause();

        const v = selectedProvider?.formats[index];
        if (!v) {
            return;
        }

        setSelectedFormat(v);
        editor.setFormat(v);
    }

    function getCurrentIndex(): number {
        if (!selectedProvider?.name) return 0;

        return availableProviders.findIndex((p) => p.name === selectedProvider.name);
    }

    function isPreviousDisabled(): boolean {
        return (
            !!selectedProvider &&
            availableProviders.findIndex((p) => p.name === selectedProvider.name) === 0 &&
            selectedProvider.formats.findIndex((f) => f.id === selectedFormat!.id) === 0
        );
    }

    function isNextDisabled(): boolean {
        return (
            !!selectedProvider &&
            availableProviders.findIndex((p) => p.name === selectedProvider.name) === availableProviders.length - 1 &&
            selectedProvider.formats.findIndex((f) => f.id === selectedFormat!.id) ===
                selectedProvider.formats.length - 1
        );
    }

    useHotkeys(ShortKeys.NEXT_FORMAT, () => selectFormatRef.current?.next(), { preventDefault: true });
    useHotkeys(ShortKeys.PREVIOUS_FORMAT, () => selectFormatRef.current?.previous(), { preventDefault: true });

    if (!selectedProvider) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <>
            <Select
                label={getText('widgets.providerFormatSelect.provider')}
                options={availableProviders.map((p) => p.name)}
                selectedOption={availableProviders.findIndex((p) => p.name === selectedProvider.name).toString() || ''}
                onChange={(i) => onProviderChange(i)}
                className='min-w-250'
            />
            <Select
                ref={selectFormatRef}
                label={getText('widgets.providerFormatSelect.format')}
                onChange={(f) => onFormatChange(f)}
                options={
                    selectedProvider
                        ? selectedProvider.formats.map((f) => ({
                              label: getFormatRatioValue(f.name, f.width, f.height),
                              icon:
                                  formatValues.some((v) => v.formatId === f.id) ||
                                  (currentFormat?.id === f.id && isFormatSpecificActive) ? (
                                      <FormatOverrideIcon className='h-20 w-20 text-company' />
                                  ) : undefined,
                          }))
                        : []
                }
                selectedOption={
                    selectedFormat
                        ? selectedProvider.formats
                              .findIndex(
                                  (f) =>
                                      getFormatRatioValue(f.name, f.width, f.height) ===
                                      getFormatRatioValue(
                                          selectedFormat.name,
                                          selectedFormat.width,
                                          selectedFormat.height,
                                      ),
                              )
                              .toString()
                        : ''
                }
                showArrows
                prevArrowDisabled={isPreviousDisabled()}
                nextArrowDisabled={isNextDisabled()}
                onPrevious={() => onProviderChange(getCurrentIndex() - 1, true)}
                onNext={() => onProviderChange(getCurrentIndex() + 1)}
                className='max-w-120'
                showTooltip
                iconTooltipText={getText('widgets.providerFormatSelect.tooltipFormatOverride')}
            />
        </>
    );
}
