import { gql } from '@apollo/client';

export const subscribeRenderingJobStatusChanged = gql`
    subscription Subscription($renderingJobStatusChangedId: ID) {
        renderingJobStatusChanged(id: $renderingJobStatusChangedId) {
            id
            status
            result {
                ... on RenderingJobSuccess {
                    url
                }
                ... on RenderingJobError {
                    message
                }
            }
        }
    }
`;
