import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
};

export default function EditorContentContainer(props: Props): ReactElement {
    const { children } = props;
    return <div className='flex flex-1 flex-1 flex-row gap-30'>{children}</div>;
}

EditorContentContainer.defaultProps = {
    children: undefined,
};
