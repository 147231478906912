import React, { ReactElement, useState } from 'react';
import TitleWithIconButton from '../../components/header/title-with-icon-button';
import { Add } from '@mui/icons-material';
import useLocale from '../../hooks/use-locale';
import NewCampaignForm from '../forms/new-campaign/form';
import { useServices } from '../../hooks/use-services';

export default function CampaignCardListHeader(): ReactElement {
    const [showDialog, setShowDialog] = useState(false);

    const { campaign } = useServices();
    const { getText } = useLocale();

    function onClick(): void {
        setShowDialog(true);
    }

    async function onCreateCampaign(name: string, languageIds: string[]): Promise<void> {
        const result = await campaign.createCampaign(name, languageIds);

        setShowDialog(false);

        window.location.href = `/campaign/${result.id}`;
    }

    return (
        <div className='my-20 h-fit'>
            <TitleWithIconButton
                title={getText('widgets.dashboard.header.title')}
                icon={<Add />}
                onClickButton={() => onClick()}
                toolTip={getText('widgets.dashboard.header.tooltip')}
            />

            <NewCampaignForm
                open={showDialog}
                onClose={() => setShowDialog(false)}
                onSubmit={(n, langIds) => onCreateCampaign(n, langIds)}
            />
        </div>
    );
}
