import React, { ReactElement } from 'react';

import { Icon, Menu, MenuItem } from '@mui/material';
import { TextAlign } from '@metaphore/magnolia-rendering';

type Props = {
    textAlign: TextAlign;
    onSetTextAlign(textAlign: TextAlign): void;
};

export default function TextAlignment({ onSetTextAlign, textAlign }: Props): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
    const [selected, setSelected] = React.useState<TextAlign | undefined>(textAlign);
    const open = Boolean(anchorEl);

    function handleOnClick(event: React.MouseEvent<HTMLElement>): void {
        setAnchorEl(event.currentTarget);
    }

    function handleOnChange(value: TextAlign): void {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setAnchorEl(undefined);
        setSelected(value);
        onSetTextAlign(value);
    }

    return (
        <>
            <Icon
                className='cursor-pointer'
                id='fade-button'
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleOnClick(e)}
            >
                format_align_{selected}
            </Icon>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                onClose={() => setAnchorEl(undefined)}
            >
                <MenuItem
                    data-value={TextAlign.Left}
                    onClick={() => handleOnChange(TextAlign.Left)}
                    selected={selected === TextAlign.Left}
                >
                    <Icon className='mr-10'>format_align_left</Icon> {TextAlign.Left}
                </MenuItem>
                <MenuItem
                    data-value={TextAlign.Center}
                    onClick={() => handleOnChange(TextAlign.Center)}
                    selected={selected === TextAlign.Center}
                >
                    <Icon className='mr-10'>format_align_center</Icon> {TextAlign.Center}
                </MenuItem>
                <MenuItem
                    data-value={TextAlign.Right}
                    onClick={() => handleOnChange(TextAlign.Right)}
                    selected={selected === TextAlign.Right}
                >
                    <Icon className='mr-10'>format_align_right</Icon> {TextAlign.Right}
                </MenuItem>
            </Menu>
        </>
    );
}
