import React, { MouseEvent, ReactElement } from 'react';
import Class from '../../../utils/classes';

type Props = {
    text: string;
    isSelected: boolean;
    onClick(event: MouseEvent<HTMLElement>): void;
};

export default function HorizontalItemSelectionItem(props: Props): ReactElement {
    const { text, onClick, isSelected } = props;

    return (
        <li>
            <button
                onClick={(event) => onClick(event)}
                className={Class.classNames('rounded-xl border px-12 py-8', isSelected ? 'text-company' : '')}
                type='button'
            >
                {text}
            </button>
        </li>
    );
}
