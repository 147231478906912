import React, { ReactElement, useEffect, useRef } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import CanvasPreview, { ICanvasPreview } from '../canvas/canvas-preview';
import VideoControls from './video-controls';
import { Template } from '../../generated/gql/graphql';
import CanvasHeader from '../canvas/canvas-header';

type Props = {
    activeTemplate: Template;
};

export default function VideoContainer(props: Props): ReactElement {
    const { activeTemplate } = props;

    const isLooping = useStoreValue<boolean>(CanvasSelectors.isLooping());
    const isPlaying = useStoreValue<boolean>(CanvasSelectors.isPlaying());

    const canvasPreviewRef = useRef<ICanvasPreview>(null);
    const loop = useRef(isLooping);
    const play = useRef(isPlaying);
    const templateRef = useRef(activeTemplate);
    const animationLengthRef = useRef(activeTemplate?.motion?.length || 0);
    const fpsRef = useRef(activeTemplate?.motion?.fps || 0);

    useEffect(() => {
        templateRef.current = activeTemplate;
        animationLengthRef.current = activeTemplate?.motion?.length || 0;
        fpsRef.current = activeTemplate?.motion?.fps || 30;
    }, [activeTemplate]);

    useEffect(() => {
        loop.current = isLooping;
    }, [isLooping]);

    useEffect(() => {
        play.current = isPlaying;
    }, [isPlaying]);

    if (!activeTemplate) {
        return (
            <div>
                <p>loading</p>
            </div>
        );
    }

    return (
        <>
            <CanvasHeader
                onResetSize={() => canvasPreviewRef.current?.resetSize()}
                onFitToCanvas={() => canvasPreviewRef.current?.fitToCanvas()}
            />
            <CanvasPreview ref={canvasPreviewRef} />
            <VideoControls />
        </>
    );
}
