/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { TemplateImage, TemplateObject, TemplateObjectChange } from '../../generated/gql/graphql';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import TemplateSelector from '../../stores/selectors/template';
import InstanceSelector from '../../stores/selectors/instance';
import CropSlider from './crop-slider';
import useLocale from '../../hooks/use-locale';
import { Rectangle } from '@metaphore/magnolia-rendering';

type CropStats = { left: number; top: number; right: number; bottom: number };
type Direction = 'top' | 'right' | 'bottom' | 'left';

export default function CropSliders(): ReactElement {
    const imageProps = useStoreValue<TemplateObject[]>(TemplateSelector.getImageProperties());
    const selectedObjectId = useStoreValue<string>(CanvasSelectors.getSelectedObjectId());

    const currentImage = imageProps?.find((item) => item.id === selectedObjectId)?.content as TemplateImage;
    const currentInstance = useStoreValue<TemplateObjectChange>(
        InstanceSelector.getChangedPropertyById(selectedObjectId, 'content.frame'),
    );
    const instanceFrame = currentInstance?.value || undefined;
    const initFrameValue = { left: 0, top: 0, right: 0, bottom: 0 };

    const [cropValues, setCropValues] = useState<CropStats>(initFrameValue);

    const { logger, instance } = useServices();
    const { getText } = useLocale();

    function updateCropValues(): void {
        setCropValues({
            left: instanceFrame ? instanceFrame.x : 0,
            top: instanceFrame ? instanceFrame.y : 0,
            right: instanceFrame ? 1 - (instanceFrame.width + instanceFrame.x) : 0,
            bottom: instanceFrame ? 1 - (instanceFrame.height + instanceFrame.y) : 0,
        });
    }

    useEffect(() => {
        if (currentImage) {
            updateCropValues();
        }
    }, [currentImage]);

    useEffect(() => {
        updateCropValues();
    }, [instanceFrame]);

    async function cropPackshot(value: Rectangle): Promise<void> {
        await instance.packshots.cropPackshot(selectedObjectId, value);
    }

    function onChangeInputValue(value: number, direction: Direction): void {
        const oldCropData = instanceFrame ?? { x: 0, y: 0, width: 1, height: 1, unit: 'Percent' };

        switch (direction) {
            case 'left':
                cropPackshot({ ...oldCropData, x: value, width: 1 - value - cropValues.right });
                break;
            case 'top':
                cropPackshot({ ...oldCropData, y: value, height: 1 - value - cropValues.bottom });
                break;
            case 'right':
                cropPackshot({ ...oldCropData, width: 1 - cropValues.left - value });
                break;
            case 'bottom':
                cropPackshot({ ...oldCropData, height: 1 - cropValues.top - value });
                break;

            default:
                logger.info('Crop Direction did not validate correctly');
        }
    }

    return (
        <>
            <p className='text-gray-500'>{getText('widgets.imageEditing.crop')}</p>
            <div className='crop-slider flex w-full justify-between'>
                <div className='flex w-[340px] flex-col gap-30'>
                    <CropSlider
                        label={getText('widgets.imageEditing.top')}
                        sliderValue={cropValues.top}
                        onChange={onChangeInputValue}
                        direction='top'
                    />
                    <CropSlider
                        label={getText('widgets.imageEditing.left')}
                        sliderValue={cropValues.left}
                        onChange={onChangeInputValue}
                        direction='left'
                    />
                </div>
                <div className='flex w-[340px] flex-col gap-30'>
                    <CropSlider
                        label={getText('widgets.imageEditing.bottom')}
                        sliderValue={cropValues.bottom}
                        onChange={onChangeInputValue}
                        direction='bottom'
                    />
                    <CropSlider
                        label={getText('widgets.imageEditing.right')}
                        sliderValue={cropValues.right}
                        onChange={onChangeInputValue}
                        direction='right'
                    />
                </div>
            </div>
        </>
    );
}
