/* eslint-disable react/jsx-no-useless-fragment */
import { KeyboardArrowDown } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import short from 'short-uuid';
import IconButton from '../../components/buttons/icon-button';
import MultiCheckbox from '../../components/checkboxs/multi-checkbox';
import TableCell from '../../components/table/table-cell';
import { CheckStatus } from '../../types/enums/check-status';
import { GeneratorCampaignItem, GeneratorFormat, Provider } from '../../types/generator';
import Class from '../../utils/classes';
import NewGeneratorMatrixRow from './new-generator-matrix-row';

type Props = {
    provider: Provider;
    expanded: boolean;
    checkMarkMatrix: GeneratorFormat[];
    campaignItems: GeneratorCampaignItem[];
    updateCheckMarkMatrix(newArray: GeneratorFormat[]): void;
    onExpand(providerId: string, expand: boolean): void;
};

export default function NewProviderAccordion(props: Props): ReactElement {
    const { provider, expanded, checkMarkMatrix, campaignItems, updateCheckMarkMatrix, onExpand } = props;
    const { formats, name, id } = provider;

    function getProviderCheckStatus(providerId: string): CheckStatus {
        const array = [] as boolean[];

        checkMarkMatrix.forEach((entry) => {
            if (entry.providerId === providerId && !entry.disabled) {
                array.push(entry.checked);
            }
        });

        const checkStatus = array.includes(true)
            ? array.includes(false)
                ? CheckStatus.INDETERMINATE
                : CheckStatus.CHECKED
            : CheckStatus.UNCHECKED;

        return checkStatus;
    }

    function getProviderCampaignItemCheckStatus(providerId: string, campaignItemId: string): CheckStatus {
        const out: boolean[] = [];

        checkMarkMatrix.forEach((entry) => {
            if (entry.providerId === providerId && entry.campaignItemId === campaignItemId && !entry.disabled) {
                out.push(entry.checked);
            }
        });

        const checkStatus = out.includes(true)
            ? out.includes(false)
                ? CheckStatus.INDETERMINATE
                : CheckStatus.CHECKED
            : CheckStatus.UNCHECKED;

        return checkStatus;
    }

    function getProviderCampaignItemDisabledStatus(providerId: string, campaignItemId: string): boolean {
        const out: boolean[] = [];

        checkMarkMatrix.forEach((entry) => {
            if (entry.providerId === providerId && entry.campaignItemId === campaignItemId) {
                out.push(entry.disabled);
            }
        });

        return out.every(Boolean);
    }

    function updateProvider(providerId: string): void {
        const currentCheckStatus = getProviderCheckStatus(providerId);
        const updateValue =
            currentCheckStatus === CheckStatus.INDETERMINATE || currentCheckStatus === CheckStatus.UNCHECKED;

        const newCheckMarkMatrix: GeneratorFormat[] = [];

        checkMarkMatrix.forEach((entry) => {
            if (entry.providerId === providerId && !entry.disabled) {
                newCheckMarkMatrix.push({
                    ...entry,
                    checked: updateValue,
                });
            } else {
                newCheckMarkMatrix.push(entry);
            }
        });

        updateCheckMarkMatrix(newCheckMarkMatrix);
    }

    function updateProviderCampaignItem(providerId: string, campaignItemId: string): void {
        const currentCheckStatus = getProviderCampaignItemCheckStatus(providerId, campaignItemId);
        const updateValue =
            currentCheckStatus === CheckStatus.INDETERMINATE || currentCheckStatus === CheckStatus.UNCHECKED;

        const newCheckMarkMatrix = [] as GeneratorFormat[];

        checkMarkMatrix.forEach((entry) => {
            if (entry.providerId === providerId && entry.campaignItemId === campaignItemId && !entry.disabled) {
                newCheckMarkMatrix.push({
                    ...entry,
                    checked: updateValue,
                });
            } else {
                newCheckMarkMatrix.push(entry);
            }
        });

        updateCheckMarkMatrix(newCheckMarkMatrix);
    }

    return (
        <div>
            <div className='flex border-b-1'>
                <div className='flex h-60 w-320 items-center justify-between border-r-1'>
                    <MultiCheckbox status={getProviderCheckStatus(id)} onClick={() => updateProvider(id)} />
                    <p>{name}</p>
                    <IconButton onClick={() => onExpand(id, !expanded)}>
                        <KeyboardArrowDown
                            className={Class.classNames('transform transition-all', expanded ? '' : 'rotate-180')}
                        />
                    </IconButton>
                </div>
                {campaignItems.map((c) => (
                    <TableCell
                        className='flex h-60 w-135 items-center justify-center border-r-1'
                        key={short.generate()}
                    >
                        <MultiCheckbox
                            status={getProviderCampaignItemCheckStatus(id, c.id)}
                            disabled={getProviderCampaignItemDisabledStatus(id, c.id)}
                            onClick={() => updateProviderCampaignItem(id, c.id)}
                        />
                    </TableCell>
                ))}
            </div>
            {expanded && (
                <>
                    {formats.map((format) => (
                        <NewGeneratorMatrixRow
                            key={short.generate()}
                            format={format}
                            checkMarkMatrix={checkMarkMatrix}
                            updateCheckMarkMatrix={(newArray) => updateCheckMarkMatrix(newArray)}
                            campaignItems={campaignItems}
                        />
                    ))}
                </>
            )}
        </div>
    );
}
