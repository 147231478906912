import React, { ReactElement } from 'react';
import Class from '../../utils/classes';

type Props = {
    children: ReactElement | ReactElement[];
    className?: string;
};

export default function PanelContent(props: Props): ReactElement {
    const { children, className } = props;

    return <div className={Class.classNames('p-20', className || '')}>{children}</div>;
}

PanelContent.defaultProps = {
    className: '',
};
