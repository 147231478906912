import { FileRejection } from 'react-dropzone';

export function hasErrorsWithCode(array: FileRejection[]): boolean {
    return array.every(
        (item) =>
            typeof item === 'object' &&
            'errors' in item &&
            Array.isArray(item.errors) &&
            item.errors.some(
                (error: { code: string }) =>
                    typeof error === 'object' && 'code' in error && error.code === 'file-invalid-type',
            ),
    );
}
