import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useServices } from './use-services';
import Routes from '../types/enums/routes';
import { asyncInvoke } from '../utils/function';

export default function useEditorParams(): void {
    const [searchParams] = useSearchParams();

    const { editor, template, logger } = useServices();

    const navigate = useNavigate();

    async function loadEditorFile(): Promise<void> {
        const campaignId = searchParams.get('campaignId');
        const campaignItemId = searchParams.get('campaignItemId');
        const templateId = searchParams.get('templateId');
        const instanceId = searchParams.get('instanceId');

        if (!campaignId || !campaignItemId || !templateId) {
            navigate(Routes.CAMPAIGNS);
            return;
        }

        try {
            await editor.loadCampaign(campaignId);
            editor.setCampaignItem(campaignItemId);

            await template.loadBaseTemplate(templateId);
            if (instanceId) {
                await template.loadTemplateInstanceById(instanceId);
            }
        } catch (error) {
            logger.warn('failed to load campaign or template', error);
            navigate(Routes.CAMPAIGNS);
        }
    }

    useEffect(asyncInvoke(loadEditorFile), []);
}
