import { gql } from '@apollo/client';

export const uploadAsset = gql`
    mutation uploadAsset($file: FileUpload!, $input: AssetInput!) {
        createAsset(file: $file, input: $input) {
            id
            name
            url
        }
    }
`;
