import { RenderingExportTarget } from '../generated/gql/graphql';
import { GraphQlClient } from '../providers/graphql-provider';
import { Mutations } from '../repositories';
import { RenderData } from '../types/generator';

interface IRendererService {
    createJob(
        data: RenderData[],
        exportTarget: RenderingExportTarget,
        name?: string,
        preview?: boolean,
    ): Promise<string>;
}

const RendererService = (): IRendererService => {
    async function createJob(
        data: RenderData[],
        exportTarget: RenderingExportTarget,
        name?: string,
        preview?: boolean,
    ): Promise<string> {
        const { campaignId } = data[0];

        const renderings: { campaignItemInstanceId: string; formatId: string }[] = [];
        data.forEach((item) => {
            item.formatIds.forEach((formatId) => {
                renderings.push({
                    campaignItemInstanceId: item.campaignItemInstanceId,
                    formatId,
                });
            });
        });

        const result = await GraphQlClient.mutate({
            mutation: Mutations.job.createRenderJob,
            variables: {
                input: {
                    campaignId,
                    renderings,
                    exportTarget,
                    name: name || undefined,
                    preview: preview || false,
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }

        return result.data.createRenderingJob.id;
    }

    return {
        createJob,
    };
};

export type { IRendererService };
export default RendererService;
