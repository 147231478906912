import React, { ReactElement, ReactNode } from 'react';
import ListItemButton from '../list/list-item-button';

type Props = {
    icon?: ReactNode;
    label: string;
    onClick?: () => void;
};

export default function PopperItem(props: Props): ReactElement {
    const { icon, label, onClick } = props;
    return (
        <ListItemButton className='pl-15 pr-15' onClick={onClick}>
            {icon && <div className='mr-30 flex h-24 w-24 justify-center [&>img]:self-center'>{icon}</div>}
            <span>{label}</span>
        </ListItemButton>
    );
}

PopperItem.defaultProps = {
    icon: undefined,
    onClick: undefined,
};
