import React, { forwardRef, ReactElement } from 'react';
import Class from '../../utils/classes';
import useLocale from '../../hooks/use-locale';
import {
    isTemplateImage,
    isTemplateText,
    TemplateFormat,
    TemplateInstanceFormatValues,
    TemplateObject,
    TemplateObjectChange,
} from '@metaphore/magnolia-rendering';
import { FileDownloadOutlined, ImageOutlined, TextFieldsOutlined, WineBar } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';
import { TemplateImage } from '../../generated/gql/graphql';
import { AssetGroup } from '../../types/assets.d';
import IconButton from '../../components/buttons/icon-button';
import { useStoreValue } from '../../hooks/use-store-value';
import EditorSelectors from '../../stores/selectors/editor';
import InstanceSelector from '../../stores/selectors/instance';

type Props = {
    items: TemplateObject[];
    position: { x: number; y: number };
    visible: boolean;
};

const CanvasLevelMenu = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { items, position, visible } = props;

    const format = useStoreValue<TemplateFormat>(EditorSelectors.getCurrentFormat());
    const instanceValues = useStoreValue<TemplateObjectChange[]>(InstanceSelector.getImageProperties());
    const formatValues = useStoreValue<TemplateInstanceFormatValues[]>(
        EditorSelectors.getTemplateInstanceFormatValues(),
    );

    const { canvas } = useServices();

    const { getText } = useLocale();

    function handleClick(id: string): void {
        canvas.selectObject(id);
    }

    function getIcon(item: TemplateObject): ReactElement {
        if (isTemplateText(item.content)) {
            return <TextFieldsOutlined className='fill-gray-400' />;
        }

        if (isTemplateImage(item.content)) {
            const content = item.content as TemplateImage;

            if (content.assetGroups?.includes(AssetGroup.Background)) {
                return <ImageOutlined className='fill-gray-400' />;
            }

            if (content.assetGroups?.includes(AssetGroup.Packshot)) {
                return <WineBar className='fill-gray-400' />;
            }
        }

        return <WineBar className='fill-gray-400' />;
    }

    function download(src: string, name: string): void {
        fetch(src, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = name;
                document.body.append(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    }

    function getItemValue(id: string): string | undefined {
        const instanceFormatValues = formatValues?.find((f) => f.formatId === format.id);
        if (instanceFormatValues && instanceFormatValues.values) {
            const object = instanceFormatValues.values.find((value) => value.id === id);
            if (object && object.value) {
                return object.value as string;
            }
        }

        const objectChange = instanceValues?.find((v) => v.id === id);
        if (objectChange && objectChange.value) {
            return objectChange.value as string;
        }

        return undefined;
    }

    function getDownloadButton(item: TemplateObject): ReactElement | undefined {
        if (isTemplateText(item.content) || !item.id || !isTemplateImage(item.content)) {
            return undefined;
        }

        const itemSrc = getItemValue(item.id);

        if (item.content) {
            return (
                <IconButton onClick={() => download(itemSrc || (item.content as TemplateImage).src!, item.name!)}>
                    <FileDownloadOutlined />
                </IconButton>
            );
        }
        return undefined;
    }

    return (
        <div
            ref={ref}
            style={{ top: `${position.y}px`, left: `${position.x}px` }}
            className={Class.classNames(
                visible ? 'z-10 opacity-100' : 'pointer-events-none -z-10 opacity-0',
                'absolute overflow-hidden rounded-xl bg-white shadow',
            )}
        >
            <ul className='menu flex max-h-250 flex-col overflow-y-scroll p-8'>
                <li className='px-16 text-sm leading-[24px] text-[#999999]'>
                    {getText('widgets.canvasLevelMenu.label')}
                </li>
                {items.map((item) => (
                    <li key={item.id} className='flex w-full cursor-pointer whitespace-nowrap px-8'>
                        <button
                            type='button'
                            className='flex w-full flex-row justify-start space-x-15 p-8 hover:bg-companyLightGrey'
                            onClick={() => handleClick(item.id!)}
                        >
                            {getIcon(item)}
                            <span className='text-companyDarkGrey'>{item.name}</span>
                        </button>
                        {getDownloadButton(item)}
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default CanvasLevelMenu;
