import { gql } from '@apollo/client';

export const renderTemplateInstance = gql`
    mutation RenderTemplateInstance($templateInstanceId: ID!, $input: RenderingInput!) {
        renderTemplateInstance(templateInstanceId: $templateInstanceId, input: $input) {
            id
            status
            end
            finishedNotificationMailAddress
            result {
                ... on RenderingResult {
                    url
                }
                ... on RenderingError {
                    message
                }
            }
        }
    }
`;
