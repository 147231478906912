import React, { ReactElement, ReactNode } from 'react';
import Class from '../../utils/classes';

type Props = {
    children: ReactNode;
    className?: string;
};

export default function TableCell(props: Props): ReactElement {
    const { children, className } = props;
    return <td className={Class.classNames(className || '')}>{children}</td>;
}

TableCell.defaultProps = {
    className: '',
};
