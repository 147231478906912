import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Accordion as MUIAccordion, AccordionSummary as MUIAccordionSummary } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';

type Props = {
    label: string;
    summaryChildren?: NonNullable<ReactNode>;
    children: NonNullable<ReactNode>;
    expanded?: boolean | undefined;
    onExpand?(expand: boolean): void;
};

export default function Accordion(props: Props): ReactElement {
    const { label, summaryChildren, children, expanded, onExpand } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded || false);

    function onUpdateExpand(): void {
        if (expanded !== undefined) {
            setIsExpanded(expanded);
        }
    }

    useEffect(onUpdateExpand, [expanded]);

    function handleOnExpand(): void {
        setIsExpanded((prev) => !prev);
        if (onExpand) onExpand(!isExpanded);
    }

    return (
        <MUIAccordion expanded={isExpanded} className='min-w-570 overflow-hidden !rounded-15 shadow'>
            <MUIAccordionSummary
                expandIcon={
                    <ExpandMoreOutlined
                        className='fill-companyDarkGrey'
                        onClick={() => {
                            if (isExpanded) {
                                handleOnExpand();
                            }
                        }}
                    />
                }
                className='pb-20 pl-30 pt-20'
                classes={{ content: '!m-0 flex flex-row items-center min-h-44 ', focusVisible: '!bg-white' }}
                onClick={() => {
                    if (!isExpanded) {
                        handleOnExpand();
                    }
                }}
            >
                <div className='flex w-full flex-row items-center justify-between gap-80'>
                    <p className='text-xl uppercase text-companyMediumGrey'>{label}</p>
                    {isExpanded && summaryChildren}
                </div>
            </MUIAccordionSummary>
            {children}
        </MUIAccordion>
    );
}

Accordion.defaultProps = {
    expanded: false,
    summaryChildren: undefined,
    onExpand: undefined,
};
