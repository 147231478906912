import React, { ReactElement } from 'react';
import {
    Dialog as MUIDialog,
    DialogActions as MUIDialogActions,
    DialogContent as MUIDialogContent,
    DialogTitle as MUIDialogTitle,
} from '@mui/material';
import IconButton from '../buttons/icon-button';
import { Close } from '@mui/icons-material';
import Button from '../buttons/button';
import useLocale from '../../hooks/use-locale';
import { uuid } from 'short-uuid';

type Action = {
    id: string;
    label: string;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
};

type Props = {
    title: string;
    subtitle?: string;
    open: boolean;
    children?: ReactElement | ReactElement[];
    onClose(): void;
    onBack?(): void;
    showCancelButton?: boolean;
    showCancelIcon?: boolean;
    disableSubmitActionIds?: string[];
    onSubmit?(actionId: string): void;
    actions?: Action[];
    maxWidth?: string;
    maxHeight?: string;
};

export default function Modal(props: Props): ReactElement {
    const {
        open,
        title,
        subtitle,
        onClose,
        onBack,
        onSubmit,
        children,
        actions,
        showCancelButton,
        showCancelIcon,
        disableSubmitActionIds,
        maxWidth,
        maxHeight,
    } = props;

    const { getText } = useLocale();

    return (
        <MUIDialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth,
                    maxHeight,
                    padding: '10px 0',
                },
            }}
            PaperProps={{
                className: 'rounded',
            }}
        >
            <MUIDialogTitle className='flex justify-between'>
                <div className='flex flex-col'>
                    <span className='text-[20px] font-bold'>{title}</span>
                    {subtitle && <span className='-mt-2 text-lg font-bold'>{subtitle}</span>}
                </div>
                {showCancelIcon && (
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                )}
            </MUIDialogTitle>
            {children && <MUIDialogContent className='h-full space-y-24'>{children}</MUIDialogContent>}
            <MUIDialogActions className='px-24'>
                {onBack && (
                    <Button
                        onClick={() => onBack()}
                        label={getText('widgets.forms.common.actions.back')}
                        variant='text'
                        className='mr-auto text-companyDarkGrey'
                    />
                )}
                {showCancelButton && (
                    <Button
                        label={getText('widgets.forms.common.actions.cancel')}
                        variant='text'
                        className='text-companyDarkGrey'
                        onClick={onClose}
                    />
                )}
                {actions?.map((action) => (
                    <Button
                        key={uuid()}
                        label={action.label}
                        variant={action.variant ?? 'text'}
                        className='text-company disabled:cursor-not-allowed'
                        disabled={disableSubmitActionIds?.includes(action.id)}
                        onClick={() => (onSubmit ? onSubmit(action.id) : undefined)}
                    />
                ))}
            </MUIDialogActions>
        </MUIDialog>
    );
}

Modal.defaultProps = {
    actions: undefined,
    subtitle: undefined,
    onBack: undefined,
    onSubmit: undefined,
    disableSubmitActionIds: undefined,
    maxWidth: undefined,
    maxHeight: undefined,
    children: undefined,
    showCancelButton: true,
    showCancelIcon: true,
};
