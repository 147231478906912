import React, { ReactElement, useEffect, useState } from 'react';
import HorizontalItemSelectionList from '../../components/list/horizontal-item-selection-list/horizontal-item-selection-list';
import useLocale from '../../hooks/use-locale';
import { useStoreValue } from '../../hooks/use-store-value';
import GeneratorSelector from '../../stores/selectors/generator';
import { useServices } from '../../hooks/use-services';
import { asyncInvoke } from '../../utils/function';
import EditorSelectors from '../../stores/selectors/editor';
import { OutputChannel } from '../../types/enums/template';
import { CampaignItem } from '../../generated/gql/graphql';

export default function MultiTemplateLanguageSelector(): ReactElement {
    const [languages, setLanguages] = useState<string[]>([]);
    const { getText } = useLocale();

    const selectedCampaignId = useStoreValue<string>(EditorSelectors.getActiveCampaignId());

    const selectedOutputChannel = useStoreValue<OutputChannel>(GeneratorSelector.getSelectedOutputChannel);
    const selectedLanguages = useStoreValue<string[]>(GeneratorSelector.getSelectedLocals);

    const activeCampaignItem = useStoreValue<CampaignItem | undefined>(EditorSelectors.getActiveCampaignItem());

    const { generator } = useServices();

    async function loadLanguages(): Promise<void> {
        if (!activeCampaignItem) {
            return;
        }
        const campaignItemInstances = activeCampaignItem.instances;

        if (campaignItemInstances) {
            const loadedLanguages: string[] = [];
            campaignItemInstances.forEach((instance) => {
                if (instance.languageId && !loadedLanguages.includes(instance.languageId)) {
                    loadedLanguages.push(instance.languageId);
                }
            });

            generator.setLocals(loadedLanguages);
            setLanguages(loadedLanguages);
        }
    }

    useEffect(asyncInvoke(loadLanguages), [selectedCampaignId, selectedOutputChannel]);

    function onSelectLanguage(lang: string): void {
        if (lang === 'All') {
            generator.setLocals(selectedLanguages.length === languages.length ? [] : languages);
            return;
        }

        generator.setLocals(
            selectedLanguages.includes(lang)
                ? selectedLanguages.filter((l) => l !== lang)
                : [...selectedLanguages, lang],
        );
    }

    return (
        <div className='min-h-[125px] w-full space-y-20 rounded-xl bg-white px-40 pt-20 shadow'>
            <h2 className='text-xl uppercase text-companyMediumGrey'>{getText('widgets.multiLanguageSelect.label')}</h2>
            <HorizontalItemSelectionList
                items={languages}
                selectedItems={selectedLanguages}
                onItemSelect={(lang) => onSelectLanguage(lang)}
            />
        </div>
    );
}
