import { gql } from '@apollo/client';

export const getTemplateFormats = gql`
    query getTemplateFormats($templateId: ID!) {
        template(id: $templateId) {
            formats {
                name
                width
                height
            }
        }
    }
`;
