import { ThemeProvider } from '@mui/material';
import MUISlider from '@mui/material/Slider';
import React, { ReactElement } from 'react';
import { defaultTheme } from '../../providers/mui-theme-provider';
import { createTheme } from '@mui/material/styles';

type Props = {
    value: number;
    max?: number;
    onChange: (value: number) => void;
    defaultValue: number;

    // Default: 0.01
    step?: number;
    // Default: medium
    size?: 'small' | 'medium' | undefined;
    // Default: false
    disableTransition?: boolean;
    // Default: primary
    color?: 'primary' | 'secondary' | undefined;
};

const disabledTransitionSliderTheme = createTheme({
    ...defaultTheme,
    transitions: {
        easing: {
            easeInOut: 'none',
            easeOut: 'none',
            easeIn: 'none',
            sharp: 'none',
        },
    },
});

export default function Slider(props: Props): ReactElement {
    const { color, value, max, defaultValue, onChange, disableTransition, step, size } = props;

    function drawSlider(): ReactElement {
        return (
            <MUISlider
                max={max}
                color={color}
                step={step}
                size={size}
                value={value}
                defaultValue={defaultValue}
                onChange={(_, v) => onChange(v as number)}
            />
        );
    }

    if (disableTransition) {
        return <ThemeProvider theme={disabledTransitionSliderTheme}>{drawSlider()}</ThemeProvider>;
    }

    return drawSlider();
}

Slider.defaultProps = {
    color: 'primary',
    max: undefined,
    disableTransition: false,
    step: 0.01,
    size: 'medium',
};
