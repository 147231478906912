import React, { ReactElement } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import { Template } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import StillContainer from './still-container';
import Class from '../../utils/classes';

export default function StillCanvas(): ReactElement {
    const activeTemplate = useStoreValue<Template | undefined>(EditorSelectors.getActiveTemplate());
    const isFormatSpecificActive = useStoreValue<boolean>(EditorSelectors.getIsFormatSpecificActive());

    return (
        <div
            className={Class.classNames(
                'flex h-full w-full flex-col rounded-xl bg-white shadow',
                isFormatSpecificActive ? 'border-2 border-company' : '',
            )}
        >
            {activeTemplate ? <StillContainer /> : <p>Please Select a template</p>}
        </div>
    );
}
