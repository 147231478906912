import React, { ReactElement } from 'react';
import { Divider } from '@mui/material';

type Props = {
    orientation?: 'horizontal' | 'vertical' | undefined;
    variant?: 'middle' | 'fullWidth' | 'inset' | undefined;
    sx?: object | undefined;
};

export default function Separator(props: Props): ReactElement {
    const { orientation, variant, sx } = props;

    return <Divider sx={sx} orientation={orientation} variant={variant} flexItem />;
}

Separator.defaultProps = {
    orientation: '',
    variant: '',
    sx: undefined,
};
