import React, { ReactElement, ReactNode } from 'react';
import Class from '../../utils/classes';

type Props = {
    children: ReactNode;
    className?: string;
};

export default function TableHeadCell(props: Props): ReactElement {
    const { children, className } = props;
    return (
        <th
            className={Class.classNames(
                `sticky top-0 min-w-90 whitespace-nowrap pb-16 pl-12 pr-8 pt-16 text-left
            text-base text-companyDarkGrey after:absolute after:right-0 after:top-1/2 after:h-14 after:w-2 
            after:-translate-y-1/2 after:bg-companyGrey first:left-0 first:z-20 first:text-right`,
                className || '',
            )}
        >
            {children}
        </th>
    );
}

TableHeadCell.defaultProps = {
    className: '',
};
