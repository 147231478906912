import React, { ReactElement, useRef } from 'react';
import CanvasHeader from '../canvas/canvas-header';
import CanvasPreview, { ICanvasPreview } from '../canvas/canvas-preview';
import StillControls from './still-controls';

export default function StillContainer(): ReactElement {
    const canvasPreviewRef = useRef<ICanvasPreview>(null);

    return (
        <>
            <CanvasHeader
                onResetSize={() => canvasPreviewRef.current?.resetSize()}
                onFitToCanvas={() => canvasPreviewRef.current?.fitToCanvas()}
            />
            <CanvasPreview ref={canvasPreviewRef} />
            <StillControls />
        </>
    );
}
