import { gql } from '@apollo/client';

export const getCampaignItemInstancesById = gql`
    query GetCampaignItemInstancesById($campaignId: ID!) {
        campaign(id: $campaignId) {
            id
            name
            items {
                id
                instances {
                    id
                    templateInstanceId
                    templateInstance {
                        id
                        createdAt
                        updatedAt
                        templateId
                        name
                        languageId
                        values {
                            id
                            path
                            value
                        }
                    }
                    languageId
                    language {
                        id
                        name
                    }
                }
            }
        }
    }
`;
