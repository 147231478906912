import React, { ReactElement, useEffect, useState } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { SaveOutlined } from '@mui/icons-material';
import { useServices } from '../../hooks/use-services';
import { Event } from '../../types/enums/events';
import Class from '../../utils/classes';
import ToolbarUndoRedo from './toolbar-undo-redo';

export default function ToolbarSave(): ReactElement {
    const [showBadge, setShowBadge] = useState<boolean>(false);

    const { eventBus } = useServices();

    function init(): void {
        eventBus.onEvent(Event.CAMPAIGN_SAVING_INSTANCE, () => {
            if (!showBadge) {
                setShowBadge(true);
                setTimeout(() => {
                    setShowBadge(false);
                }, 1500);
            }
        });
    }

    useEffect(init, []);

    return (
        <div className='flex flex-row gap-30'>
            <ToolbarUndoRedo />
            <IconButton className='relative rounded-15 bg-white p-15 shadow'>
                <SaveOutlined className='fill-companyMediumGrey' />
                <div
                    className={Class.classNames(
                        'absolute left-0 top-0 h-8 w-8 translate-x-15 translate-y-15 transition-all',
                        showBadge ? 'opacity-100' : 'opacity-0',
                    )}
                >
                    <div className='flex h-full w-full'>
                        <span className='absolute inline-flex h-full w-full animate-ping rounded-full bg-green-500 opacity-75' />
                        <span className='relative inline-flex h-6 w-6 rounded-full bg-green-500' />
                    </div>
                </div>
            </IconButton>
        </div>
    );
}
