import React, { ReactElement } from 'react';
import { useStoreValue } from '../../hooks/use-store-value';
import EditorSelectors from '../../stores/selectors/editor';

type Props = {
    classNames?: string;
};

export default function ActiveTemplateName(props: Props): ReactElement {
    const { classNames } = props;

    const activeTemplateName = useStoreValue<string>(EditorSelectors.getActiveTemplateName());

    return <p className={classNames}>{activeTemplateName}</p>;
}

ActiveTemplateName.defaultProps = {
    classNames: '',
};
