import { gql } from '@apollo/client';

export const getTemplateInstances = gql`
    query getTemplateInstances($templateId: ID!) {
        template(id: $templateId) {
            instances {
                id
                templateId
                languageId
                name
                values {
                    id
                    path
                    value
                }
            }
        }
    }
`;
