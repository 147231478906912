export enum RenderingExportTarget {
    /** Google Ads HTML banner */
    GoogleAd = 'GoogleAd',
    /** Stills format */
    Png = 'Png',
    /** Video format */
    Mp4 = 'Mp4',
    /** JSON format */
    Json = 'Json',
    /** Print-ready PDF */
    Pdf = 'Pdf',
    /** Layered Tif image */
    Tif = 'Tif',
}
