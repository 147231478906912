import React, { ReactElement, useEffect, useState } from 'react';
import useLocale from '../../../hooks/use-locale';
import Modal from '../../../components/modal/modal';
import TextField from '../../../components/inputs/text-field';

type Props = {
    open: boolean;
    input: string;
    onClose(): void;
    onSubmit(name: string): void;
};

export default function DuplicateCampaignModal(props: Props): ReactElement {
    const { open, input, onClose, onSubmit } = props;

    const [name, setName] = useState(input);

    const { getText } = useLocale();

    useEffect(() => {
        setName(input);
    }, [open]);

    return (
        <Modal
            onClose={() => {
                onClose();
                setName('');
            }}
            title={getText('widgets.dashboard.duplicate.title')}
            open={open}
            actions={[
                {
                    id: 'duplicate',
                    label: getText('widgets.dashboard.duplicate.action'),
                    variant: 'text',
                },
            ]}
            onSubmit={() => {
                onSubmit(name);
                setName('');
            }}
            maxWidth='600px'
            maxHeight='760px'
        >
            <div className='flex flex-col gap-20'>
                <p className='text-xl text-companyDarkGrey'>{getText('widgets.dashboard.duplicate.content')}</p>
            </div>
            <div className='flex w-full py-5'>
                <TextField
                    label={getText('widgets.dashboard.duplicate.label')}
                    value={name}
                    className='my-2 w-full'
                    onChange={(e) => setName(e.currentTarget.value)}
                />
            </div>
        </Modal>
    );
}
