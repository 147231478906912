import React, { ReactElement } from 'react';
import Tooltip from '../tooltip/tooltip';

type Props = {
    texts: string[];
    className?: string;
};

export default function TextList(props: Props): ReactElement {
    const { texts, className } = props;

    if (texts.length === 0) {
        return <p className={className} />;
    }

    const items = texts.filter((text) => !!text);

    if (items.length === 0) {
        return <p className={className} />;
    }

    if (items.length <= 4) {
        const value = items.slice(0, 4).join(', ');
        return <p className={className}>{value}</p>;
    }

    const value = items.slice(0, 4).join(',');
    if (value.length < 15) {
        return (
            <Tooltip title={items.join(', ')}>
                <p className={className}>{`${value}...`}</p>
            </Tooltip>
        );
    }

    return (
        <Tooltip title={items.join(', ')}>
            <p className={className}>{`${value.slice(0, 15)}...`}</p>
        </Tooltip>
    );
}

TextList.defaultProps = {
    className: '',
};
