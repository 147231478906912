import React, { ReactElement } from 'react';
import { IconProps } from '../../types/icon';

export default function VideoIcon(props: IconProps): ReactElement {
    const { width, height, className, color } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M19.2 4.08008H4.80002C3.47456 4.08008 2.40002 5.15462 2.40002 6.48008V17.2801C2.40002 18.6055 3.47456 19.6801 4.80002 19.6801H19.2C20.5255 19.6801 21.6 18.6055 21.6 17.2801V6.48008C21.6 5.15462 20.5255 4.08008 19.2 4.08008ZM19.8 17.2801C19.8 17.6109 19.5309 17.8801 19.2 17.8801H4.80002C4.46918 17.8801 4.20002 17.6109 4.20002 17.2801V6.48008C4.20002 6.14924 4.46918 5.88008 4.80002 5.88008H19.2C19.5309 5.88008 19.8 6.14924 19.8 6.48008V17.2801ZM15.668 12.8303L10.6459 15.7298C9.91436 16.1521 9.00002 15.6242 9.00002 14.7796V8.98052C9.00002 8.13584 9.91442 7.60796 10.6459 8.0303L15.668 10.9298C16.3995 11.3521 16.3995 12.408 15.668 12.8303Z'
                fill={color}
            />
        </svg>
    );
}
