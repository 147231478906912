import { ILoggerService } from './logger';
import { GraphQlClient } from '../providers/graphql-provider';
import { Queries } from '../repositories';

interface IMeService {
    getClientLogo(): Promise<string>;

    getClientName(): Promise<string>;
}

export const MeService = (logger: ILoggerService): IMeService => {
    async function getClientLogo(): Promise<string> {
        const result = await GraphQlClient.query({
            query: Queries.me.getClientLogo,
            fetchPolicy: 'no-cache',
        });
        if (result.error) {
            logger.info('no client logo found');
            throw result.error;
        }
        return result.data.me.client.logoUrl;
    }

    async function getClientName(): Promise<string> {
        const result = await GraphQlClient.query({
            query: Queries.me.getClientName,
            fetchPolicy: 'no-cache',
        });
        if (result.error) {
            logger.info('no client name found');
            throw result.error;
        }
        return result.data.me.client.name;
    }

    return {
        getClientLogo,
        getClientName,
    };
};
