import React, { ReactElement, useEffect, useState } from 'react';
import { useServices } from '../../hooks/use-services';
import { asyncInvoke } from '../../utils/function';
import CampaignCardList from './campaign-card-list';
import useLocale from '../../hooks/use-locale';
import useNotification from '../../hooks/use-notification';
import CampaignContextOperation from '../../types/enums/campaign-context-operation';
import { CampaignListType } from '../../types/campaign';
import Skeleton from '../../components/skeleton/skeleton';
import DeleteCampaignModal from '../modal/campaign/delete-campaign-modal';
import RenameCampaignModal from '../modal/campaign/rename-campaign-modal';
import { useNavigate } from 'react-router-dom';
import DuplicateCampaignModal from '../modal/campaign/duplicate-campaign-modal';

export default function CampaignCards(): ReactElement {
    const [isLoaded, setIsLoaded] = useState(true);

    const [campaignList, setCampaignList] = useState<CampaignListType>([]);
    const { campaign } = useServices();
    const navigate = useNavigate();

    const [selectedCampaignId, setSelectedCampaignId] = useState<string | undefined>(undefined);

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    const { getText } = useLocale();

    const notification = useNotification();

    async function loadCampaigns(): Promise<void> {
        setIsLoaded(true);

        const loadedCampaigns = await campaign.getAll();
        setCampaignList(loadedCampaigns);

        setIsLoaded(false);
    }

    useEffect(asyncInvoke(loadCampaigns), []);

    function handleCampaignClick(id: string): void {
        navigate(`/campaign/${id}`);
    }

    function handleContextOperation(operation: CampaignContextOperation, id: string): void {
        setSelectedCampaignId(id);

        switch (operation) {
            case CampaignContextOperation.Delete:
                setShowDeleteModal(true);
                break;
            case CampaignContextOperation.Rename:
                setShowRenameModal(true);
                break;
            case CampaignContextOperation.Duplicate:
                setShowDuplicateModal(true);
                break;
            default:
                console.error('Unknown operation', operation);
        }
    }

    async function handleDelete(): Promise<void> {
        if (!selectedCampaignId) {
            return;
        }

        try {
            await campaign.deleteCampaign(selectedCampaignId);
            await loadCampaigns();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('notifications.campaign.delete.error'));
        }

        setSelectedCampaignId(undefined);
        setShowDeleteModal(false);
    }

    async function handleRename(name: string): Promise<void> {
        if (!selectedCampaignId) {
            return;
        }

        try {
            await campaign.renameCampaign(selectedCampaignId, name);
            await loadCampaigns();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('notifications.campaign.rename.error'));
        }

        setSelectedCampaignId(undefined);
        setShowRenameModal(false);
    }

    async function handleDuplicate(name: string): Promise<void> {
        if (!selectedCampaignId) {
            return;
        }

        try {
            await campaign.duplicateCampaign(selectedCampaignId, name);
            await loadCampaigns();
        } catch (error) {
            console.error(error);
            notification.pushError(getText('notifications.campaign.duplicate.error'));
        }

        setSelectedCampaignId(undefined);
        setShowDuplicateModal(false);
    }

    if (isLoaded) {
        return <Skeleton variant='rectangular' className='rounded-xl' />;
    }

    return (
        <div className='flex flex-col rounded-xl bg-white p-50 shadow'>
            <CampaignCardList
                campaignList={campaignList}
                onClick={(id) => handleCampaignClick(id)}
                onContextOperation={(op, id) => handleContextOperation(op, id)}
            />

            <DeleteCampaignModal
                open={showDeleteModal}
                onClose={() => {
                    setSelectedCampaignId(undefined);
                    setShowDeleteModal(false);
                }}
                onSubmit={() => handleDelete()}
            />

            <RenameCampaignModal
                open={showRenameModal}
                input={campaignList.find((p) => p.id === selectedCampaignId)?.name || ''}
                onClose={() => {
                    setSelectedCampaignId(undefined);
                    setShowRenameModal(false);
                }}
                onSubmit={(name) => handleRename(name)}
            />

            <DuplicateCampaignModal
                open={showDuplicateModal}
                input={`${campaignList.find((p) => p.id === selectedCampaignId)?.name} (copy)` || ''}
                onClose={() => {
                    setSelectedCampaignId(undefined);
                    setShowDuplicateModal(false);
                }}
                onSubmit={(name) => handleDuplicate(name)}
            />
        </div>
    );
}
