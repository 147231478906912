import { graphql } from '../../../generated/gql';

export const updateBannerSettings = graphql(`
    mutation updateBannerSettings($updateTemplateInstanceId: ID!, $data: TemplateInstanceUpdate!) {
        updateTemplateInstance(id: $updateTemplateInstanceId, data: $data) {
            renderSettings {
                bannerSettings {
                    loop
                    targetUrl
                }
            }
        }
    }
`);
