import React, { ReactElement, useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import { useAppConfig } from './config/app-config-use';

type Props = {
    children: ReactElement | ReactElement[];
};

export default function MarkerIoProvider(props: Props): ReactElement {
    const { children } = props;

    const appConfig = useAppConfig();

    useEffect(() => {
        if (!appConfig.loading) {
            markerSDK.loadWidget({
                project: appConfig.config.markerIo.project,
            });
        }
    }, [appConfig.loading, appConfig.config?.markerIo.project]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
