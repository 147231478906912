import React, { ReactElement, useEffect, useState } from 'react';
import HorizontalSliderSelector from '../../components/selectors/horizontal-slider-selector';
import { useServices } from '../../hooks/use-services';
import { useStoreValue } from '../../hooks/use-store-value';
import EditorSelectors from '../../stores/selectors/editor';
import { asyncInvoke } from '../../utils/function';
import { CampaignItem } from '../../generated/gql/graphql';
import Skeleton from '../../components/skeleton/skeleton';

type Instance = {
    id: string;
    name: string;
};

export default function ToolbarInstanceSelect(): ReactElement {
    const [instances, setInstances] = useState<Instance[]>([]);

    const activeCampaignItem = useStoreValue<CampaignItem | undefined>(EditorSelectors.getActiveCampaignItem());
    const activeCampaignInstanceId = useStoreValue<string | undefined>(EditorSelectors.getTemplateInstanceId());

    const { template, logger } = useServices();

    async function setTemplateInstances(): Promise<void> {
        if (!activeCampaignItem) {
            return;
        }

        const campaignInstances = activeCampaignItem.instances;
        if (campaignInstances && campaignInstances.length === 0) {
            logger.info(`Template ${activeCampaignItem.templateId} has no instances`);
            return;
        }
        if (campaignInstances) {
            const loadedInstances = campaignInstances.map((instance) => ({
                id: instance.templateInstanceId,
                name: instance.languageId || instance.language,
            })) as Instance[];

            setInstances(loadedInstances);
        }
    }

    async function loadDefaultTemplateInstance(): Promise<void> {
        if (instances.length === 0) {
            logger.info(`Template has no instances`);
            return;
        }
        const firstInstance = instances[0];
        await template.loadTemplateInstanceById(firstInstance.id);
    }

    useEffect(asyncInvoke(setTemplateInstances), [activeCampaignItem]);
    useEffect(asyncInvoke(loadDefaultTemplateInstance), [instances]);

    async function onSelectInstance(index: number): Promise<void> {
        if (!activeCampaignItem) {
            return;
        }

        await template.loadTemplateInstanceById(instances[index].id);
    }

    function onAddNewInstance(): void {
        // ToDo (out of scope): Add new template instance
        logger.info('Not implemented');
    }

    return instances.length === 0 ? (
        <Skeleton variant='rectangular' className='w-180' />
    ) : (
        <HorizontalSliderSelector
            options={instances.map((v) => v.name)}
            onAddOption={() => onAddNewInstance()}
            onClickItem={(i) => onSelectInstance(i)}
            selectedIndex={instances.findIndex((v) => v.id === activeCampaignInstanceId)}
        />
    );
}
