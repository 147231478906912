import React, { ReactElement, useRef, useState } from 'react';
import IconButton from '../../components/buttons/icon-button';
import { AspectRatio, FormatShapes, Fullscreen, MoreVert } from '@mui/icons-material';
import Popper from '../../components/popper/popper';
import PopperItem from '../../components/popper/popper-item';
import { useServices } from '../../hooks/use-services';
import useLocale from '../../hooks/use-locale';
import { useStoreValue } from '../../hooks/use-store-value';
import CanvasSelectors from '../../stores/selectors/canvas';
import { useHotkeys } from 'react-hotkeys-hook';
import { ShortKeys } from '../../types/enums/short-keys';
import EditorSelectors from '../../stores/selectors/editor';
import Modal from '../../components/modal/modal';
import { ReactComponent as SearchAndReplaceIcon } from '../../assets/icons/search-and-replace-icon.svg';
import { ReactComponent as FormatOverrideIcon } from '../../assets/icons/format-override-icon.svg';
import FormatEditorIcon from '../../assets/icons/format-editor-icon.svg';
import SearchAndReplaceModal from '../modal/editor/search-and-replace-modal';
import FormatEditorModal from '../modal/editor/format-editor-modal';

type Props = {
    onResetSize(): void;
    onFitToCanvas(): void;
};

export default function CanvasHeaderMore(props: Props): ReactElement {
    const { onResetSize, onFitToCanvas } = props;
    const [isOpen, setIsOpen] = useState(false);

    const [isFormatSpecificActivateOpen, setIsFormatSpecificActivateOpen] = useState(false);
    const [isFormatSpecificDeactivateOpen, setIsFormatSpecificDeactivateOpen] = useState(false);
    const [isFormatSpecificCloseOpen, setIsFormatSpecificCloseOpen] = useState(false);
    const [isSearchAndReplaceOpen, setIsSearchAndReplaceOpen] = useState(false);
    const [isFormatEditorOpen, setIsFormatEditorOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const showObjectOutlines = useStoreValue<boolean>(CanvasSelectors.displayObjectOutlines());

    const { getText } = useLocale();

    const { canvas, editor, common } = useServices();

    useHotkeys(ShortKeys.SHOW_SELECTION, () => canvas.showOutlines(!showObjectOutlines), { preventDefault: true });
    useHotkeys(ShortKeys.FIT_TO_100, () => onResetSize(), { preventDefault: true });
    useHotkeys(ShortKeys.FIT_TO_CANVAS, () => onFitToCanvas(), { preventDefault: true });

    const isFormatSpecificActive = useStoreValue<boolean>(EditorSelectors.getIsFormatSpecificActive());

    function onSubmit(action: string): void {
        if (action === 'close') {
            setIsFormatSpecificCloseOpen(false);
        }

        if (action === 'deactivate') {
            editor.setIsFormatSpecificActive(false);
            common.deleteCurrentFormatValues();
            setIsFormatSpecificCloseOpen(true);
            setIsFormatSpecificDeactivateOpen(false);
        }

        if (action === 'activate') {
            editor.setIsFormatSpecificActive(true);
            setIsFormatSpecificActivateOpen(false);
        }
    }

    return (
        <div>
            <Modal
                onClose={() => setIsFormatSpecificActivateOpen(false)}
                title={getText('widgets.canvas.formatSpecific.activate.headline')}
                open={isFormatSpecificActivateOpen}
                showCancelIcon={false}
                onSubmit={(a) => onSubmit(a)}
                actions={[
                    {
                        id: 'activate',
                        label: getText('widgets.canvas.formatSpecific.activate.confirm'),
                        variant: 'text',
                    },
                ]}
            >
                <p>{getText('widgets.canvas.formatSpecific.activate.body')}</p>
            </Modal>

            <Modal
                onClose={() => setIsFormatSpecificDeactivateOpen(false)}
                title={getText('widgets.canvas.formatSpecific.deactivate.headline')}
                open={isFormatSpecificDeactivateOpen}
                onSubmit={(a) => onSubmit(a)}
                showCancelIcon={false}
                actions={[
                    {
                        id: 'deactivate',
                        label: getText('widgets.canvas.formatSpecific.deactivate.confirm'),
                        variant: 'text',
                    },
                ]}
            >
                <p>{getText('widgets.canvas.formatSpecific.deactivate.body')}</p>
            </Modal>
            <Modal
                onClose={() => setIsFormatSpecificCloseOpen(false)}
                title={getText('widgets.canvas.formatSpecific.close.headline')}
                open={isFormatSpecificCloseOpen}
                showCancelIcon={false}
                showCancelButton={false}
                onSubmit={(a) => onSubmit(a)}
                actions={[
                    {
                        id: 'close',
                        label: getText('widgets.canvas.formatSpecific.close.confirm'),
                        variant: 'text',
                    },
                ]}
            />

            <SearchAndReplaceModal open={isSearchAndReplaceOpen} onClose={() => setIsSearchAndReplaceOpen(false)} />
            <FormatEditorModal open={isFormatEditorOpen} onClose={() => setIsFormatEditorOpen(false)} />

            <div className='flex flex-row gap-5'>
                <IconButton
                    ref={anchorRef}
                    className='p-17 h-56 w-56 rounded-xl bg-white shadow'
                    onClick={() => setIsOpen(true)}
                >
                    <MoreVert />
                </IconButton>
            </div>
            <Popper anchorEl={anchorRef.current} open={isOpen} onOpen={(b) => setIsOpen(b)}>
                <PopperItem
                    label={getText('widgets.canvas.header.more.objectFrames')}
                    icon={<FormatShapes />}
                    onClick={() => canvas.showOutlines(!showObjectOutlines)}
                />
                <PopperItem
                    label={getText('widgets.canvas.header.more.resetSize')}
                    icon={<Fullscreen />}
                    onClick={() => onResetSize()}
                />
                <PopperItem
                    label={getText('widgets.canvas.header.more.fitToCanvas')}
                    icon={<AspectRatio />}
                    onClick={() => onFitToCanvas()}
                />
                <PopperItem
                    label={getText('widgets.canvas.header.more.formatSpecific')}
                    icon={
                        isFormatSpecificActive ? (
                            <FormatOverrideIcon className='text-company' />
                        ) : (
                            <FormatOverrideIcon />
                        )
                    }
                    onClick={() =>
                        isFormatSpecificActive
                            ? setIsFormatSpecificDeactivateOpen(true)
                            : setIsFormatSpecificActivateOpen(true)
                    }
                />
                <PopperItem
                    label={getText('widgets.canvas.header.more.formatEditor')}
                    icon={<img alt='format editor icon' className='' src={FormatEditorIcon} />}
                    onClick={() => setIsFormatEditorOpen(true)}
                />
                <PopperItem
                    label={getText('widgets.canvas.header.more.searchAndReplace')}
                    icon={<SearchAndReplaceIcon className='fill-companyDarkGrey' />}
                    onClick={() => setIsSearchAndReplaceOpen(true)}
                />
            </Popper>
        </div>
    );
}
