import React, { ReactElement, useEffect, useState } from 'react';
import Switch from '../../components/switch/switch';
import { useStoreValue } from '../../hooks/use-store-value';
import { TemplateInstance } from '../../generated/gql/graphql';
import EditorSelectors from '../../stores/selectors/editor';
import { useServices } from '../../hooks/use-services';
import { BannerSettingsInput } from '../../services/instance/banner';

export default function BannerLoopSetting(): ReactElement {
    const [loop, setLoop] = useState(false);

    const templateInstance = useStoreValue<TemplateInstance | undefined>(EditorSelectors.getTemplateInstance());

    const { instance } = useServices();

    useEffect(() => {
        if (!templateInstance) {
            return;
        }
        let bannerSettings: BannerSettingsInput | undefined | null = templateInstance.renderSettings?.bannerSettings;
        if (!bannerSettings) {
            // Set default loop value to `true`
            bannerSettings = { loop: true };
            instance.banner.updateSettings(templateInstance.id, bannerSettings);
        }
        setLoop(bannerSettings.loop || false);
    }, [templateInstance, instance.banner]);

    async function onLoopChange(checked: boolean): Promise<void> {
        if (!templateInstance) {
            return;
        }
        await instance.banner.updateSettings(templateInstance.id, {
            ...templateInstance.renderSettings?.bannerSettings,
            loop: checked,
        });
        setLoop(checked);
    }

    return <Switch label='Loop' onChange={(v) => onLoopChange(v)} checked={loop} />;
}
