import { ILoggerService } from '../logger';
import { IEventBusService } from '../event-bus';
import { TemplateObjectChange, TemplateObjectChangeInput } from '../../generated/gql/graphql';
import { store } from '../../stores';
import { EditorActions } from '../../stores/slices/editor';
import { Event } from '../../types/enums/events';
import { GraphQlClient } from '../../providers/graphql-provider';
import { Mutations } from '../../repositories';
import { HistoryActions } from '../../stores/slices/history';
import { isNil, TemplateInstanceFormatValues } from '@metaphore/magnolia-rendering';

interface ICommonInstanceService {
    getChangeObjectById(id: string): TemplateObjectChange | undefined;

    setObjectChange(id: string, path: string, value?: string | number | object, ignoreHistory?: boolean): Promise<void>;

    saveInstance(id: string, path: string, value: string | object): void;

    deleteCurrentFormatValues(): void;

    setObjectChangesForTemplate(
        objectChanges: {
            instanceId: string;
            id: string;
            path: string;
            value: string | object;
        }[],
    ): void;
}

const CommonInstanceService = (logger: ILoggerService, eventBus: IEventBusService): ICommonInstanceService => {
    function getChangeObjectById(id: string): TemplateObjectChange | undefined {
        logger.info('getChangeObjectById', id);
        return undefined;
    }

    async function saveInstance(
        id: string,
        path: string,
        value: string | object | undefined,
        formatId?: string,
    ): Promise<void> {
        const currentInstance = store.getState().editor.instanceTemplate;

        if (!currentInstance) {
            logger.info('skip sync. no instance selected');
            return;
        }

        const data: TemplateObjectChangeInput = {
            id,
            path,
            value,
        };

        let variables: {
            updateTemplateInstanceId: string;
            data: {
                valueChanges?: TemplateObjectChangeInput[];
                formatValuesChanges?: TemplateInstanceFormatValues[];
            };
        } = {
            updateTemplateInstanceId: currentInstance.id,
            data: {
                valueChanges: [data],
            },
        };

        if (formatId) {
            const currentFormatValues = currentInstance.formatValues || [];
            const formatValue = currentFormatValues.find((item) => item.formatId === formatId);

            let newData = [data];

            if (formatValue?.values) {
                newData = formatValue.values
                    .map((v) => ({
                        id: v.id,
                        path: v.path,
                        value: v.value,
                    }))
                    .map((item) => {
                        const match = [data].find((obj) => obj.id === item.id);
                        return match || item;
                    })
                    .concat([data].filter((obj) => !formatValue.values?.find((item) => item.id === obj.id)));
            }

            variables = {
                updateTemplateInstanceId: currentInstance.id,
                data: {
                    formatValuesChanges: [
                        {
                            formatId,
                            values: newData,
                        },
                    ],
                },
            };
        }

        const result = await GraphQlClient.mutate({
            mutation: Mutations.instance.updateInstanceChangeObject,
            variables,
        });

        store.dispatch(
            EditorActions.setIsFormatSpecificActive({
                isFormatSpecificActive: !!formatId,
            }),
        );

        if (result.errors) {
            throw result.errors;
        }

        eventBus.publish(Event.CAMPAIGN_SAVING_INSTANCE);
    }

    async function setFormatSpecificObjectChange(
        id: string,
        path: string,
        value: string | undefined,
        formatId: string,
    ): Promise<void> {
        const instance = store.getState().editor.instanceTemplate;

        if (!instance) {
            logger.error('Unable to set text, no instance is selected');
            return;
        }

        if (!instance.formatValues) {
            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: {
                        ...instance,
                        formatValues: [
                            {
                                formatId,
                                values: [
                                    {
                                        __typename: 'TemplateObjectChange',
                                        id,
                                        path,
                                        value,
                                    },
                                ],
                            },
                        ],
                    },
                }),
            );

            await saveInstance(id, path, value, formatId);
            await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            return;
        }

        const { formatValues } = instance;
        const newEl = {
            __typename: 'TemplateObjectChange',
            id,
            path,
            value,
        } as TemplateObjectChange;

        const formatValue = formatValues.find((item) => item.formatId === formatId);

        if (!formatValue) {
            const newInstance = {
                ...instance,
                formatValues: [
                    ...formatValues,
                    {
                        formatId,
                        values: [newEl],
                    },
                ],
            };

            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: newInstance,
                }),
            );

            await saveInstance(id, path, value, formatId);
            await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            return;
        }

        if (formatValue && formatValue.values) {
            const newValues = formatValue.values.filter((item) => `${item.id}_${item.path}` !== `${id}_${path}`) || [];

            newValues.push(newEl);

            const newInstance = {
                ...instance,
                formatValues: formatValues.map((item) => {
                    if (item.formatId === formatId) {
                        return {
                            ...item,
                            values: newValues,
                        };
                    }
                    return item;
                }),
            };

            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: newInstance,
                }),
            );

            await saveInstance(id, path, value, formatId);
            await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
        }
    }

    async function setObjectChange(
        id: string,
        path: string,
        value: string | undefined,
        ignoreHistory?: boolean,
    ): Promise<void> {
        const instance = store.getState().editor.instanceTemplate;
        const { isFormatSpecificActive, selectedFormat } = store.getState().editor;

        // Call Format Specific Changes
        if (isFormatSpecificActive && selectedFormat) {
            await setFormatSpecificObjectChange(id, path, value, selectedFormat.id);
            return;
        }

        if (!instance) {
            logger.error('Unable to set text, no instance is selected');
            return;
        }

        if (!instance.values) {
            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: {
                        ...instance,
                        values: [
                            {
                                __typename: 'TemplateObjectChange',
                                id,
                                path,
                                value,
                            },
                        ],
                    },
                }),
            );

            await saveInstance(id, path, value);
            await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
            return;
        }

        const values = instance.values as TemplateObjectChange[];
        const newEl = {
            __typename: 'TemplateObjectChange',
            id,
            path,
            value,
        } as TemplateObjectChange;

        const newValues = values.filter((item) => `${item.id}_${item.path}` !== `${id}_${path}`);

        if (!isNil(value)) {
            newValues.push(newEl);
        }

        const newInstance = {
            ...instance,
            values: newValues,
        };

        if (!ignoreHistory) {
            // add previous state to history
            const previous = values.find((o) => o.id === id);
            const hasElement = store.getState().history.instanceHistory.past.some((o) => o.id === id);

            if (!hasElement && previous) {
                store.dispatch(HistoryActions.add({ templateObjectChange: previous }));
            }
            store.dispatch(HistoryActions.add({ templateObjectChange: newEl }));
        }

        store.dispatch(
            EditorActions.setInstanceTemplate({
                instance: newInstance,
            }),
        );

        await saveInstance(id, path, value);
        await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
    }

    async function deleteCurrentFormatValues(): Promise<void> {
        const instance = store.getState().editor.instanceTemplate;
        const formatId = store.getState().editor.selectedFormat?.id;

        if (!instance) {
            logger.error('Unable to delete format values, no instance is selected');
            return;
        }

        if (!formatId) {
            logger.error('Unable to delete format values, no format is selected');
            return;
        }

        const { formatValues, id } = instance;

        if (!formatValues) {
            logger.error('Unable to delete format values, no format values found');
            return;
        }

        const result = await GraphQlClient.mutate({
            mutation: Mutations.instance.updateInstanceChangeObject,
            variables: {
                updateTemplateInstanceId: id,
                data: {
                    formatValuesChanges: [
                        {
                            formatId,
                            values: [],
                        },
                    ],
                },
            },
        });

        if (result.errors) {
            throw result.errors;
        }

        const formatValue = formatValues.find((item) => item.formatId === formatId);

        if (formatValue) {
            const newFormatValues = formatValues.filter((item) => item.formatId !== formatId);

            const newInstance = {
                ...instance,
                formatValues: [...newFormatValues],
            };

            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: newInstance,
                }),
            );
        }

        await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
    }

    // Function to perform the mutation
    async function performObjectChange(
        instanceId: string,
        id: string,
        path: string,
        value: string | object,
    ): Promise<void> {
        const variables = {
            updateTemplateInstanceId: instanceId,
            data: {
                valueChanges: [
                    {
                        id,
                        path,
                        value,
                    },
                ],
            },
        };

        try {
            await GraphQlClient.mutate({
                mutation: Mutations.instance.updateInstanceChangeObject,
                variables,
            });

            const instance = store.getState().editor.instanceTemplate;

            if (!instance) {
                logger.error('Unable to set value, no instance is selected');
                return;
            }

            if (!instance.values) {
                store.dispatch(
                    EditorActions.setInstanceTemplate({
                        instance: {
                            ...instance,
                            values: [
                                {
                                    __typename: 'TemplateObjectChange',
                                    id,
                                    path,
                                    value,
                                },
                            ],
                        },
                    }),
                );

                await saveInstance(id, path, value);
                await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
                return;
            }

            const values = instance.values as TemplateObjectChange[];
            const newEl = {
                __typename: 'TemplateObjectChange',
                id,
                path,
                value,
            } as TemplateObjectChange;

            const newValues = values.filter((item) => `${item.id}_${item.path}` !== `${id}_${path}`);

            if (!isNil(value)) {
                newValues.push(newEl);
            }

            const newInstance = {
                ...instance,
                values: newValues,
            };
            store.dispatch(
                EditorActions.setInstanceTemplate({
                    instance: newInstance,
                }),
            );

            await saveInstance(id, path, value);
            await eventBus.publish(Event.CANVAS_FORCE_REDRAW);
        } catch (error) {
            console.error(`Error performing mutation for ID: ${id} and instance ${instanceId}`, error);
        }
    }

    async function setObjectChangesForTemplate(
        objectChanges: {
            instanceId: string;
            id: string;
            path: string;
            value: string | object;
        }[],
    ): Promise<void> {
        const promises = objectChanges.map((o) => performObjectChange(o.instanceId, o.id, o.path, o.value));
        await Promise.all(promises);
    }

    return {
        getChangeObjectById,
        setObjectChange,
        setObjectChangesForTemplate,
        saveInstance,
        deleteCurrentFormatValues,
    };
};

export type { ICommonInstanceService };
export default CommonInstanceService;
