import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    className?: string;
};

export default function Table(props: Props): ReactElement {
    const { children, className } = props;
    return <table className={className}>{children}</table>;
}

Table.defaultProps = {
    className: '',
};
