import React, { ReactElement, useState } from 'react';
import ActionButton from '../../components/buttons/action-button';
import useLocale from '../../hooks/use-locale';
import Modal from '../../components/modal/modal';
import TextField from '../../components/inputs/text-field';

type Props = {
    onGenerate(name: string): void;
    disabled: boolean;
};

export default function GeneratorPreviewButton(props: Props): ReactElement {
    const { onGenerate, disabled } = props;

    const { getText } = useLocale();
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [dialogState, setDialogState] = useState<'name' | 'generate'>('name');

    return (
        <>
            <ActionButton
                label={getText('widgets.generator.preview.label')}
                variant='outlined'
                onClick={() => setIsOpen(true)}
                disabled={disabled}
                className='mr-20'
            />

            <Modal
                onClose={() => {
                    setDialogState('name');
                    setIsOpen(false);
                    setName('');
                }}
                title={
                    dialogState === 'name'
                        ? getText('components.dialogs.preview.name.title')
                        : getText('components.dialogs.preview.generate.title')
                }
                open={isOpen}
                actions={[
                    {
                        id: 'generate',
                        label:
                            dialogState === 'name'
                                ? getText('components.dialogs.preview.name.submit')
                                : getText('components.dialogs.preview.generate.submit'),
                        variant: 'text',
                    },
                ]}
                disableSubmitActionIds={[name.length === 0 ? 'generate' : '']}
                showCancelButton
                showCancelIcon={dialogState === 'name'}
                onSubmit={() => {
                    if (dialogState === 'name') {
                        setDialogState('generate');
                        return;
                    }
                    onGenerate(name);
                    setName('');
                    setIsOpen(false);
                    setDialogState('name');
                }}
                maxWidth='600px'
                maxHeight='760px'
            >
                <div className='flex w-full py-5 '>
                    {dialogState === 'name' && (
                        <div className='flex w-full flex-col'>
                            <p className='mb-2'>{getText('components.dialogs.preview.name.inputLabel')}</p>
                            <TextField
                                value={name}
                                placeholder={getText('components.dialogs.preview.name.placeholder')}
                                className='my-2 w-full'
                                onChange={(e) => setName(e.currentTarget.value)}
                            />
                        </div>
                    )}
                    {dialogState === 'generate' && (
                        <p className='text-base text-companyLighterText'>
                            {getText('components.dialogs.preview.generate.content')}
                        </p>
                    )}
                </div>
            </Modal>
        </>
    );
}
