import { gql } from '@apollo/client';

export const getCampaignDetailsById = gql`
    query getCampaignDetailsById($campaignId: ID!) {
        campaign(id: $campaignId) {
            id
            name
            items {
                id
                name
                templateId
                template {
                    id
                    name
                    formats {
                        id
                        groupId
                        name
                        width
                        height
                        scale
                    }
                    formatIds
                    formatGroups {
                        id
                        name
                        formats {
                            id
                            groupId
                            name
                            width
                            height
                        }
                    }
                    clientId
                    outputChannels
                    instances {
                        id
                        languageId
                        name
                        templateId
                    }
                    clientFormats {
                        formatId
                        format {
                            id
                            name
                            width
                            height
                        }
                    }
                }
                instances {
                    id
                    templateInstanceId
                    templateInstance {
                        id
                        createdAt
                        updatedAt
                        templateId
                        name
                        languageId
                    }
                    languageId
                    language {
                        id
                        name
                    }
                }
            }
        }
    }
`;
