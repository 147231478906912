import { ICommonInstanceService } from './common';

interface IBackgroundInstanceService {
    setBackground(id: string, src: string): Promise<void>;

    resetBackground(id: string): Promise<void>;
}

const BackgroundInstanceService = (common: ICommonInstanceService): IBackgroundInstanceService => {
    async function setBackground(id: string, src: string): Promise<void> {
        await common.setObjectChange(id, 'content.src', src);
    }

    async function resetBackground(id: string): Promise<void> {
        await common.setObjectChange(id, 'content.src', undefined, true);
    }

    return {
        setBackground,
        resetBackground,
    };
};

export type { IBackgroundInstanceService };
export default BackgroundInstanceService;
