import React, { ReactElement } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';

type Props = {
    status: 'checked' | 'unchecked' | 'indeterminate';
    disabled?: boolean;
    onClick(): void;
};

export default function MultiCheckbox(props: Props): ReactElement {
    const { status, disabled, onClick } = props;

    return (
        <MuiCheckbox
            checked={status === 'checked'}
            disabled={disabled}
            indeterminate={status === 'indeterminate'}
            onClick={onClick}
        />
    );
}

MultiCheckbox.defaultProps = {
    disabled: false,
};
