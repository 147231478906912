import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
};

export default function EditorContainer(props: Props): ReactElement {
    const { children } = props;
    return <div className='flex min-h-screen flex-grow flex-col bg-companyLightGrey p-30'>{children}</div>;
}

EditorContainer.defaultProps = {
    children: undefined,
};
